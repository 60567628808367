import superagent from 'superagent';
import { message } from 'antd';
import { debounce } from 'lodash';

import { store } from 'reducers/store';
import { logout } from 'actions/user.js';

export const request = superagent.agent().use((req) => {
  req.on('response', (res) => {
    if (res.status === 401) {
      logoutAndRedirect();
    }
  });
});

const logoutAndRedirect = debounce(
  () => {
    message.error('Session expired, please log in again.');
    setTimeout(() => {
      store.dispatch(logout());
      window.location = '/login';
    }, 3000);
  },
  10000,
  { leading: true, trailing: false }
);
