import { useEffect } from 'react';

const GoogleLogin = ({ clientId, callback }) => {
  /* global google */
  useEffect(() => {
    setTimeout(() => {
      google.accounts.id.initialize({
        client_id: clientId,
        callback,
      });

      google.accounts.id.renderButton(document.getElementById('google-login'), {
        theme: 'outline',
        size: 'large',
      });
    }, 100); // google might not be available so delay it for 100 ms
  }, []);

  return (
    <div
      style={{ display: 'inline-block' }}
      id={'google-login'}></div>
  );
};

export default GoogleLogin;
