import combineReducerMapSelectors from 'redux-reducer-selectors';
import * as user from './user';
import * as filter from './filter';
import * as queryResult from './queryResult';
import * as assetsToDownload from './assetsToDownload';
import * as salesData from './salesData';
import * as creators from './creators';
import * as distributors from './distributors';
import * as productsColumns from './productsColumns';
import * as watermarks from './watermarks';
import * as queueErrors from './queueErrors';
import * as dwTasks from './dwTasks';
import * as dwTasksDesigns from './dwTasksDesigns';
import * as trends from './trends';
import * as wbmTypes from './wbmType';
import * as contentCalendarEvents from './contentCalendarEvents';
import * as modelRecognization from './modelRecognization';
import * as categoryTemplate from './categoryTemplate';
import * as headerState from './headerState';
import * as similarImageBase64 from './similarImageBase64';
import * as darkTheme from './darkTheme';

const { rootReducer, selectors } = combineReducerMapSelectors({
  user,
  filter,
  queryResult,
  assetsToDownload,
  salesData,
  creators,
  distributors,
  productsColumns,
  watermarks,
  queueErrors,
  dwTasks,
  dwTasksDesigns,
  trends,
  wbmTypes,
  contentCalendarEvents,
  modelRecognization,
  categoryTemplate,
  headerState,
  similarImageBase64,
  darkTheme,
});

// console.log('root reducer');
// console.log(rootReducer());

export default rootReducer;
export { selectors as Selectors };
