import { Menu } from 'antd';
import {
  DashboardOutlined,
  AppstoreOutlined,
  ExperimentOutlined,
  PictureOutlined,
  CameraOutlined,
  TeamOutlined,
  FundOutlined,
  ReconciliationOutlined,
  StarOutlined,
  CoffeeOutlined,
  CalendarOutlined,
  WarningOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { menuStatus } from 'menuItems';
import { useState } from 'react';
import { withRouter } from 'react-router-dom';
import './Menu.css';

export default withRouter(({ collapsed, history, location }) => {
  let { currentOpen, currentSelected, menuItems } = menuStatus(history.location.pathname);
  if (!menuItems) return null;
  const [menuOpen, setMenuOpen] = useState(currentOpen);

  const onOpenChange = (openKeys) => void setMenuOpen(openKeys.filter((key) => !menuOpen.includes(key)));

  const mapIconFieldToIconComponent = (iconField) => {
    switch (iconField) {
      case 'dashboard':
        return <DashboardOutlined />;
      case 'appstore':
        return <AppstoreOutlined />;
      case 'experiment':
        return <ExperimentOutlined />;
      case 'picture':
        return <PictureOutlined />;
      case 'camera':
        return <CameraOutlined />;
      case 'team':
        return <TeamOutlined />;
      case 'fund':
        return <FundOutlined />;
      case 'reconciliation':
        return <ReconciliationOutlined />;
      case 'star':
        return <StarOutlined />;
      case 'coffee':
        return <CoffeeOutlined />;
      case 'calendar':
        return <CalendarOutlined />;
      case 'warning':
        return <WarningOutlined />;
      case 'setting':
        return <SettingOutlined />;
      default:
        return null;
    }
  };

  return (
    <Menu
      mode="inline"
      // theme="dark"
      openKeys={collapsed ? [] : menuOpen}
      onOpenChange={onOpenChange}
      selectedKeys={
        collapsed
          ? []
          : location.pathname === '/shoots/add-video-folders'
          ? ['shoots.registerRawFiles']
          : // : location.pathname === '/shoots/add-photo-folders'
          // ? ['shoots.registerPhotos']
          location.pathname === '/shoots/add-shoot-models'
          ? ['shoots.models.add']
          : location.pathname === '/shoots/add-shoot-prs'
          ? ['shoots.prs.add']
          : /\/shoots\/[0-9]*\/approve-post-production/.test(location.pathname)
          ? ['shoots.approvePostProduction']
          : /\/collection\/[0-9]*/.test(location.pathname)
          ? ['collections']
          : currentSelected
      }
      onSelect={({ selectedKeys }) => (currentSelected = selectedKeys)}>
      {menuItems.map((item) =>
        item.subs ? (
          <Menu.SubMenu
            key={item.key}
            title={
              <span>
                {mapIconFieldToIconComponent(item.icon)}
                {collapsed ? null : <span>{item.text}</span>}
              </span>
            }>
            {item.subs.map((sub) => (
              <Menu.Item
                key={sub.key}
                onClick={() => {
                  history.push(sub.path);
                }}>
                <span>{sub.text}</span>
              </Menu.Item>
            ))}
          </Menu.SubMenu>
        ) : (
          <Menu.Item
            key={item.key}
            onClick={() => {
              history.push(item.path);
            }}>
            <span>
              {mapIconFieldToIconComponent(item.icon)}
              {collapsed ? null : <span>{item.text}</span>}
            </span>
          </Menu.Item>
        ),
      )}
      <div style={{ height: 100 }}></div>
    </Menu>
  );
});
