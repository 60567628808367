import { Menu } from 'antd';
import { SettingOutlined, LogoutOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { logout } from 'actions/user';

const UserMenu = ({ hideMenu, logout, history }) => (
  <Menu>
    <Menu.Item>
      <span
        onClick={() => {
          hideMenu();
          history.push('/user-profile');
        }}>
        <SettingOutlined />
        User Profile
      </span>
    </Menu.Item>
    <Menu.Item>
      <span
        onClick={() => {
          hideMenu();
          logout();
          history.push('/');
        }}>
        <LogoutOutlined />
        Logout
      </span>
    </Menu.Item>
  </Menu>
);

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
});

export default withRouter(connect(null, mapDispatchToProps)(UserMenu));
