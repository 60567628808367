import { getCreators } from '../lib/api/creator';
import { SET_CREATORS } from '../reducers/actionTypes';

export const populateCreators = () => async (dispatch) => {
  const { body: creators } = await getCreators();
  dispatch(setCreatorsAction(creators));
};

const setCreatorsAction = (creators) => ({
  type: SET_CREATORS,
  payload: creators,
});
