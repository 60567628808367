import { request } from './request.js';
import { getAccessToken } from '../util';
import { apiUrl } from './apiUrl';

export const getWbmTypes = (filter = {}) =>
  request
    .get(`${apiUrl}/api/wbmTypes?filter=${JSON.stringify(filter)}`)
    .set('Authorization', getAccessToken());

export default { getWbmTypes };
