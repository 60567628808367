import { cloneDeep } from 'lodash';
import { SET_SIMILAR_IMAGE_BASE64 } from './actionTypes';
const init = [];
export default (state = init, action) => {
  switch (action.type) {
    case SET_SIMILAR_IMAGE_BASE64:
      return cloneDeep(action.payload);
    default:
      return state;
  }
};
