import { SET_WATERMARKS, ADD_WATERMARK } from './actionTypes';
const init = [];
export default (state = init, action) => {
  switch (action.type) {
    case SET_WATERMARKS:
      return [...action.payload];
    case ADD_WATERMARK:
      return [action.payload, ...state];
    default:
      return state;
  }
};
