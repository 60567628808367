import {
  SET_TRENDS,
  SET_TRENDS_CATEGORY,
  SET_TRENDS_BY_CATEGORY,
  SET_IMAGE_GALLERY,
  SET_MORE_IMAGES,
  SET_TRENDS_DATE,
} from './actionTypes';
const init = {
  selectedTrend: '',
};
export default (state = init, action) => {
  let temp;
  switch (action.type) {
    case SET_TRENDS:
      return { ...state, ...action.payload };
    case SET_TRENDS_DATE:
      return { ...state, selectedDate: action.payload };
    case SET_TRENDS_CATEGORY:
      return { ...state, selectedTrend: action.payload };
    case SET_TRENDS_BY_CATEGORY:
      state.trends[action.payload.category] = action.payload.trendsData;
      return { ...state, selectedTrend: action.payload.category };
    case SET_IMAGE_GALLERY:
      return { ...state, ...{ gallery: action.payload } };
    case SET_MORE_IMAGES:
      temp = state.trends[action.payload.id][action.payload.order].images;
      temp.push.apply(temp, action.payload.images);
      return { ...state };
    default:
      return state;
  }
};
