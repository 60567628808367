import transform from 'lodash/transform';
import isEqual from 'lodash/isEqual';
import isObject from 'lodash/isObject';
import { store } from '../reducers/store';
import { useEffect } from 'react';
import { message } from 'antd';

const actionsList = ['read', 'write', 'create', 'delete'];

export const canI = (action, target) => {
  const { user } = store.getState();
  if (!user) return false;
  const me = typeof user === 'object' ? user : JSON.parse(user);
  if (!action || !actionsList.includes(action)) return false;
  const myList = me.allowed[action];
  if (!myList || !Array.isArray(myList)) return false;
  return myList.includes(target);
};

export const getParameterFromUrl = (url, name) => new URLSearchParams(url).get(name) || '';

export const setParameterFromUrl = (url, name, value) => {
  const locationSearch = new URLSearchParams(url);
  if (value === undefined) {
    locationSearch.delete(name);
  } else if (value.length === 0) {
    locationSearch.delete(name);
  } else {
    locationSearch.set(name, value);
  }
  return locationSearch.toString();
};

export const getLocalUser = () => {
  const userStr = localStorage && localStorage.getItem('user');
  return userStr ? JSON.parse(userStr) : null;
};

export const setLocalUser = (user) => localStorage && localStorage.setItem('user', JSON.stringify(user));

export const removeLocalUser = () => localStorage && localStorage.removeItem('user');

export const getAccessToken = () => {
  const user = getLocalUser();
  return user ? user.accessToken.id : '';
};

export const hasRole = (user, roleName) => user && user.roles.some((role) => role.name === roleName);

/**
 * Generate s3 url for thumbnail.
 * @param id Id of the product.
 * @param extension
 * @param isTransparent
 * @return {string} Thumbnail url.
 */
export const s3ImageURL = (id, extension, isTransparent) => {
  if (extension === 'mov') {
    return `${process.env.REACT_APP_WF_PRODUCT_POSTERS_URL}/${id}.${isTransparent ? 'png' : 'jpg'}`;
  }

  return `${process.env.REACT_APP_WF_PRODUCT_THUMBS_URL}/${id}.${isTransparent ? 'png' : 'jpg'}`;
};

export function currency(n) {
  n = parseFloat(n);
  return isNaN(n) ? undefined : n.toFixed(2);
}

export const randomId = () => Math.random().toString(36).substring(2, 15);

export function objectDiff(object, base) {
  function changes(object, base) {
    return transform(object, function (result, value, key) {
      if (!isEqual(value, base[key])) {
        result[key] = isObject(value) && isObject(base[key]) ? changes(value, base[key]) : value;
      }
    });
  }
  return changes(object, base);
}

export function dateFilters(fromDate, toDate) {
  if (!fromDate && !toDate) return {};
  if (fromDate && !toDate) {
    return {
      where: { shootdate: { gte: fromDate } },
    };
  } else if (!fromDate && toDate) {
    return {
      where: { shootdate: { lte: toDate } },
    };
  } else {
    return {
      where: { shootdate: { between: [fromDate, toDate] } },
    };
  }
}

export const parallelLimit = async (funcList, limit = 10) => {
  let inFlight = new Set();

  return funcList.map(async (func, i) => {
    // Hold the loop by another loop
    // while the next promise resolves
    while (inFlight.size >= limit) {
      await Promise.race(inFlight);
    }

    console.log(`STARTING ROUND->${i} SIZE->${inFlight.size}`);

    const promise = func();
    // Add promise to inFlight Set
    inFlight.add(promise);
    // Delete promise from Set when it is done
    await promise;
    inFlight.delete(promise);
  });
};

export const captureVideoFrame = (video, format, quality) => {
  if (typeof video === 'string') {
    video = document.getElementById(video);
  }

  format = format || 'jpeg';
  quality = quality || 0.92;

  if (!video || (format !== 'png' && format !== 'jpeg')) {
    return false;
  }

  var canvas = document.createElement('CANVAS');

  canvas.width = video.videoWidth;
  canvas.height = video.videoHeight;

  canvas.getContext('2d').drawImage(video, 0, 0);

  var dataUri = canvas.toDataURL('image/' + format, quality);
  var data = dataUri.split(',')[1];
  var mimeType = dataUri.split(';')[0].slice(5);

  var bytes = window.atob(data);
  var buf = new ArrayBuffer(bytes.length);
  var arr = new Uint8Array(buf);

  for (var i = 0; i < bytes.length; i++) {
    arr[i] = bytes.charCodeAt(i);
  }

  var blob = new Blob([arr], { type: mimeType });
  return { blob: blob, dataUri: dataUri, format: format };
};

export const emailIsValid = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

export const videoFrameExtractionWarning = (product) => {
  if (!product.is4k) {
    return 'Video is not 4K';
  } else if (product.state !== 'Production') {
    return 'Video is not in Production yet';
  }
  return null;
};

export const chunks = (arr, size) => {
  const array = arr.slice(0);
  var results = [];
  while (array.length) {
    results.push(array.splice(0, size));
  }
  return results;
};

async function postData(url = '', data = {}) {
  const response = await fetch(url, data);
  return response.text();
}
export const customFileUploadRequest = (options, customName, contentType = 'binary/octate-stream') => {
  const token = getAccessToken();
  console.log('custom file upload request running');
  const url = options.action + (customName || options.file.name);
  return postData(url, {
    headers: {
      Authorization: token,
    },
  })
    .then((response) => {
      console.log(response);
      return response;
    })
    .then((signedUrl) => {
      return postData(signedUrl, {
        method: 'PUT',
        cache: 'no-cache',
        headers: {
          'content-type': contentType,
        },
        body: options.file,
      });
    })
    .then((response) => options.onSuccess(response, options.file))
    .catch((err) => {
      message.error('Error uploading file, please try again later!', 10);
      console.log(err);
      return false;
    });
};

export const useOutsideAlerter = (ref, triggered) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        triggered();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
};
