import { cloneDeep } from 'lodash';
import { SET_USER } from './actionTypes';
const init = localStorage.getItem('user') || null;
export default (state = init, action) => {
  switch (action.type) {
    case SET_USER:
      return cloneDeep(action.payload);
    default:
      return state;
  }
};
