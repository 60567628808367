import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { Table, Tag } from 'antd';
import { getData } from './getData';

/* eslint-disable react/display-name */
const columns = [
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: 'Shoot Date',
    dataIndex: 'shootdate',
    key: 'shootdate',
    align: 'center',
  },
  {
    title: 'Location',
    dataIndex: 'location',
    key: 'location',
    align: 'center',
  },
  {
    title: 'Team',
    dataIndex: 'teamDetails',
    key: 'team',
    align: 'center',
    render: (team) => (
      <span>
        <Tag color={team.color} key={team.id}>
          {team.id.toUpperCase()}
        </Tag>
      </span>
    ),
  },
];
/* eslint-enable react/display-name */

const ShootsThisWeek = ({ style }) => {
  const [shoots, setShoots] = useState([]);
  useEffect(() => {
    getData(
      {
        start: dayjs().startOf('week').toDate(),
        end: dayjs().endOf('week'),
      },
      (shoots) => {
        setShoots(shoots);
      }
    );
  }, []);

  return (
    <div style={{ ...style }}>
      <h3 style={{ paddingTop: 20, paddingLeft: 10 }}>Shoots this week</h3>
      <Table
        columns={columns}
        dataSource={shoots}
        style={{ padding: 10 }}
        rowKey="id"
        pagination={{ pageSize: 3 }}
      />
    </div>
  );
};

export default ShootsThisWeek;
