import { getAccessToken } from '../util';
import { apiUrl } from './apiUrl';
import { request } from './request.js';

//note these api where changed from using separate ContentPage api to
//using the generic content api. some functions expect contentType: landing-page/category-page/subcategory-page
export const getContentPages = (payload) =>
  request.post(`${apiUrl}/api/contents/get-contentlist`).set('Authorization', getAccessToken()).send(payload);

export const getAssets = (filter) =>
  request.get(`${apiUrl}/api/contents?filter=${JSON.stringify(filter)}`).set('Authorization', getAccessToken());

export const getTotalContentCount = async (filter) =>
  request.get(`${apiUrl}/api/contents/count?where=${JSON.stringify(filter)}`).set('Authorization', getAccessToken());

export const contentBreakdown = (data) =>
  request.post(`${apiUrl}/api/contents/content-count-breakdown`).set('Authorization', getAccessToken()).send(data);

export const getContent = (filter) => {
  return request.get(`${apiUrl}/api/contents?filter=${JSON.stringify(filter)}`).set('Authorization', getAccessToken());
};

export const getContentCreators = (contentType) => {
  return request
    .get(`${apiUrl}/api/contents/creators?contentType=${contentType}`)
    .set('Authorization', getAccessToken());
};
export const getCreatorsforColl = () => {
  return request.get(`${apiUrl}/api/contents/creators`).set('Authorization', getAccessToken());
};

export const createContentPage = (data) => {
  return request.post(`${apiUrl}/api/contents`).send(data).set('Authorization', getAccessToken());
};

export const editContentPage = (id, data) => {
  return request.put(`${apiUrl}/api/contents/${id}`).send(data).set('Authorization', getAccessToken());
};

export const updateContentPage = (id, data) => {
  return request.patch(`${apiUrl}/api/contents/${id}`).set('Authorization', getAccessToken()).send(data);
};

export const updateContentPages = (idsArray, data) => {
  return request
    .post(`${apiUrl}/api/contents/update?where=${JSON.stringify({ id: { inq: idsArray } })}`)
    .send(data)
    .set('Authorization', getAccessToken());
};

export const upsertHistory = (data) =>
  request
    .post(
      `${apiUrl}/api/product-histories/upsertWithWhere?where=${JSON.stringify({
        and: [{ productId: data.productId }, { status: 'Model releases assigned' }],
      })}`,
    )
    .set('Authorization', getAccessToken())
    .send(data);

export const deleteContentPage = (id, site, contentType) => {
  if (site === 'pikwizard' && contentType === 'landing-page') {
    return request.delete(`${apiUrl}/api/contents/pikwizard-lp/${id}`).set('Authorization', getAccessToken());
  }
  return request.delete(`${apiUrl}/api/contents/${id}`).set('Authorization', getAccessToken());
};

export const getTotalCounts = (filterQuery) =>
  request
    .get(`${apiUrl}/api/clickthroughAnalytics?filter=${JSON.stringify(filterQuery)}`)
    .set('Authorization', getAccessToken());

export const showErrors = (data) => {
  return request.post(`${apiUrl}/api/contents/errorsbycontentId`).send(data).set('Authorization', getAccessToken());
};

export const getErrorsByContentType = (contentType) => {
  return request
    .get(`${apiUrl}/api/contents?filter=${JSON.stringify({ where: { contentType, errors: { neq: null } } })}`)
    .set('Authorization', getAccessToken());
};

export const getTotalCount = (data) => {
  return request
    .post(`${apiUrl}/api/clickthroughAnalytics/get-clickthrough-analytics`)
    .send(data)
    .set('Authorization', getAccessToken());
};

export const getContents = (data) => {
  return request.post(`${apiUrl}/api/contents/get-landingpages`).send(data).set('Authorization', getAccessToken());
};

export const createPWAILP = (data) => {
  return request.post(`${apiUrl}/api/pwAILPKeywords/pw-ai-lp`).send(data).set('Authorization', getAccessToken());
};

export const bulkCreatePWAIPages = (data) => {
  return request
    .post(`${apiUrl}/api/pwAILPKeywords/bulkcreate-pw-ai-lp`)
    .send(data)
    .set('Authorization', getAccessToken());
};

export const updateAllFilter = (filter, data) => {
  return request
    .post(`${apiUrl}/api/contents/update?where=${JSON.stringify(filter)}`)
    .send(data)
    .set('Authorization', getAccessToken());
};

export const mergePages = (basePageId, toBeMergedIds = []) => {
  return request
    .post(`${apiUrl}/api/contents/merge-pages`)
    .send({ basePageId, toBeMergedIds })
    .set('Authorization', getAccessToken());
};

export const translateContent = (data) =>
  request.post(`${apiUrl}/api/contents/translate-content`).send(data).set('Authorization', getAccessToken());

export const updateAssetSearch = (data) =>
  request.post(`${apiUrl}/api/contents/update-pw-asset-search`).send(data).set('Authorization', getAccessToken());

export default {
  getContent,
  updateContentPages,
  updateContentPage,
  getContentPages,
  getContentCreators,
  createContentPage,
  editContentPage,
  deleteContentPage,
  showErrors,
  getErrorsByContentType,
  getTotalContentCount,
  getCreatorsforColl,
  getTotalCount,
  getContents,
  createPWAILP,
  bulkCreatePWAIPages,
  updateAllFilter,
  mergePages,
  translateContent,
  updateAssetSearch,
};
