import {
  SET_DISTRIBUTORS,
  ADD_DISTRIBUTOR,
  EDIT_DISTRIBUTOR,
  REMOVE_DISTRIBUTOR,
} from './actionTypes';
const init = [];
export default (state = init, action) => {
  switch (action.type) {
    case SET_DISTRIBUTORS:
      return [...action.payload];
    case ADD_DISTRIBUTOR:
      return [...state, action.payload];
    case EDIT_DISTRIBUTOR:
      state.some((distributor, index) => {
        if (distributor.id === action.payload.id) {
          state[index] = action.payload;
          return true;
        }
        return false;
      });
      return [...state];
    case REMOVE_DISTRIBUTOR:
      state.some((distributor, index) => {
        if (distributor.id === action.payload.id) {
          state.splice(index, 1);
          return true;
        }
        return false;
      });
      return [...state];
    default:
      return state;
  }
};
