import { apiUrl } from './apiUrl';
import { request } from './request.js';
import { getAccessToken } from '../util';

/**
 * search design wizard
 * @returns {*}
 */
export const search = ({ from = 0, size = 20, body }) => {
  return request.post(`${apiUrl}/api/designwizard/search`).send({ from, size, body });
};

export const searchAssets = (filter) => {
  return request.get(`${apiUrl}/api/dw-assets?filter=${JSON.stringify(filter)}`);
};

export const searchBriefCodes = ({ from = 0, size = 0, searchterm = '', assetType = 'photo' }) => {
  return request
    .post(`${apiUrl}/api/designwizard/brief-code-search`)
    .set('Authorization', getAccessToken())
    .send({ from, size, searchterm, assetType });
}

export const getDWRoleMapping = (email) => {
  return request
    .post(`${apiUrl}/api/dw/dwuser`)
    .set('Authorization', getAccessToken())
    .send({ email })
    .then((res) => res.body);
};

export const getDWRoles = (email) => {
  return request
    .get(`${apiUrl}/api/dw/roles`)
    .set('Authorization', getAccessToken())
    .send({ email })
    .then((res) => res.body);
};

export const addDWRole = (data) => {
  return request
    .post(`${apiUrl}/api/dw/addrole`)
    .set('Authorization', getAccessToken())
    .send(data)
    .then((res) => res.body);
};

export const deleteDWRole = (data) => {
  return request
    .post(`${apiUrl}/api/dw/removeRoleMapping`)
    .set('Authorization', getAccessToken())
    .send(data)
    .then((res) => res.body);
};

export const approveDWTemplates = (data) =>
  request
    .post(`${apiUrl}/api/approve-dw-templates`)
    .set('Authorization', getAccessToken())
    .send(data)
    .then((res) => res.body);

export const approveOrRejectDWTemplateTranslation = (data) =>
  request
    .post(`${apiUrl}/api/approve-dw-templates-translations`)
    .set('Authorization', getAccessToken())
    .send(data)
    .then((res) => res.body);

export const getDwFonts = () =>
  request
    .get(`${apiUrl}/api/dw-fonts`)
    .set('Authorization', getAccessToken())
    .then((res) => res.body);

export const addDWFont = (data) =>
  request
    .post(`${apiUrl}/api/add-dw-font`)
    .set('Authorization', getAccessToken())
    .send(data)
    .then((res) => res.body);

export const updateDWFont = (data) =>
  request
    .post(`${apiUrl}/api/update-dw-font`)
    .set('Authorization', getAccessToken())
    .send(data)
    .then((res) => res.body);

export default {
  search,
  searchAssets,
  getDWRoleMapping,
  getDWRoles,
  addDWRole,
  deleteDWRole,
  approveDWTemplates,
  approveOrRejectDWTemplateTranslation,
  getDwFonts,
  addDWFont,
  updateDWFont,
};
