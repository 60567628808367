export const CATE_ACTION_TYPE = {
  FAV_LIST: 'FAV_LIST',
  FAV_GRP: 'FAV_GRP',
  CAT_LIST: 'CAT_LIST',
  SUB_CAT_LIST: 'SUB_CAT_LIST',
  TEMPLATE_LIST: 'TEMPLATE_LIST',
  SEARCH_LIST: 'SEARCH_LIST',
};

export const initialState = {
  favouriteList: [],
  favouriteGrpById: {},
  categoryList: [],
  subCategoryList: [],
  templateList: [],
  searchList: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CATE_ACTION_TYPE.CAT_LIST:
      return {
        ...state,
        categoryList: action.payload,
      };
    case CATE_ACTION_TYPE.SUB_CAT_LIST:
      return {
        ...state,
        subCategoryList: action.payload,
      };
    case CATE_ACTION_TYPE.TEMPLATE_LIST:
      return { ...state, templateList: action.payload };
    case CATE_ACTION_TYPE.FAV_LIST: {
      // const favouriteGrpById = {};
      // if (action.payload.length > 0) {
      //   const { id, templates, categoryId } = action.payload[0];
      //   favouriteGrpById[categoryId] = { id, templates: templates.split(',') };
      // }
      return { ...state, favouriteList: action.payload || [] };
    }
    case CATE_ACTION_TYPE.SEARCH_LIST:
      return { ...state, searchList: action.payload };

    default:
      return state;
  }
};
