import React from 'react';
import {
  align,
  font,
  fontColor,
  fontSize,
  formatBlock,
  hiliteColor,
  horizontalRule,
  image,
  lineHeight,
  link,
  list,
  paragraphStyle,
  table,
  template,
  textStyle,
} from 'suneditor/src/plugins';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

const RichTextInput = (props) => {
  const propsCopy = { ...props };

  return (
    <>
      <SunEditor
        {...propsCopy}
        defaultValue={propsCopy.value}
        setOptions={{
          plugins: [
            align,
            font,
            fontColor,
            fontSize,
            formatBlock,
            hiliteColor,
            horizontalRule,
            lineHeight,
            list,
            paragraphStyle,
            table,
            template,
            textStyle,
            image,
            link,
          ],
          buttonList: [
            ['undo', 'redo'],
            ['fontSize', 'formatBlock', 'blockquote'],
            ['paragraphStyle'],
            ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
            ['fontColor', 'hiliteColor'],
            ['outdent', 'indent'],
            ['align', 'horizontalRule', 'list', 'lineHeight'],
            ['table', 'link', 'image'],
            ['removeFormat'],
            ['codeView'],
            // ['save'],
          ],
          defaultTag: 'div',
          minHeight: '300px',
          showPathLabel: false,
          // font: sortedFontOptions,
        }}
      />
    </>
  );
};

export default RichTextInput;
