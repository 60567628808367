import React from 'react';
import { CloseCircleFilled } from '@ant-design/icons';
import styled from 'styled-components';

const HoveredImage = ({ photo, onClick, onLoad }) => {
  const handleOnClick = () => {
    onClick();
  };

  // in this view treat videos as images for simplicity
  if (photo.src.includes('mp4')) {
    photo.src = photo.src.replace('mp4', 'jpg');
  }

  return (
    <div
      style={{ margin: '2px', position: 'relative', height: photo.height, width: photo.width }}
      onClick={handleOnClick}
      className={'hovered'}>
      <s.overlay
        style={{
          position: 'absolute',
          height: photo.height,
          width: photo.width,
        }}>
        <CloseCircleFilled
          style={{
            fontSize: '40px',
            position: 'absolute',
            color: 'rgb(255, 0, 0)',
            left: '50%',
            top: '50%',
            marginLeft: '-20px',
            marginTop: '-20px',
          }}
        />
      </s.overlay>
      <img
        alt={photo.title}
        {...photo}
        onLoad={(e) => onLoad(e)}
      />
      <style>{`.hovered:hover{outline:2px solid rgb(255, 0, 0)}`}</style>
    </div>
  );
};

const s = {
  overlay: styled.div`
    :hover {
      opacity: 80%;
      outline: 2px solid rgb(255, 0, 0);
    }
    opacity: 0;
  `,
};

export default HoveredImage;
