import { library } from '@fortawesome/fontawesome-svg-core';

import {
  faBold,
  faItalic,
  faUnderline,
  faStrikethrough,
  faSuperscript,
  faSubscript,
  faHeading,
  faListOl,
  faListUl,
  faHighlighter,
  faStickyNote,
  faCalendarAlt,
  faEdit,
  faTags,
  faUserFriends,
  faMoneyBillAlt,
  faPlusSquare,
  faUserAlt,
  faClock,
  faTrashAlt,
  faSave,
  faBan,
  faAngleLeft,
  faAngleRight,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faBold,
  faItalic,
  faUnderline,
  faStrikethrough,
  faSuperscript,
  faSubscript,
  faHeading,
  faListOl,
  faListUl,
  faHighlighter,
  faStickyNote,
  faCalendarAlt,
  faEdit,
  faTags,
  faUserFriends,
  faMoneyBillAlt,
  faPlusSquare,
  faUserAlt,
  faClock,
  faTrashAlt,
  faSave,
  faBan,
  faAngleLeft,
  faAngleRight
);
