const getUrl = () => {
  // to test frontend with production api, set useProductionAPI to true;

  const useProductionAPI = false;
  const useStagingAPI = false;

  if (window?.location.origin) {
    const { origin } = window.location;

    if (useProductionAPI || origin.startsWith('https://wf1.wbml.io')) {
      return 'https://api-wf2.wbml.io';
    }
    if (useStagingAPI || origin.startsWith('https://wf-staging.wbml.io')) {
      return 'https://api-wfstaging.wbml.io';
    }
    if (origin.includes('ngrok.io')) {
      return 'https://26eb5334d969.ngrok.io'; // api url for the ngrok tunnel
    }
  }

  return 'http://localhost:2021';
};

const getSalesProcessingUrl = () => {
  if (process.env.REACT_APP_ENV === 'production') {
    return 'https://salesprocessor.wbml.io';
  }
  if (process.env.REACT_APP_ENV === 'staging') {
    return 'https://salesprocessor-staging.wbml.io';
  }
  if (window?.location.origin.includes(':2020')) {
    return 'http://localhost:9090';
  }
};

export const apiUrl = getUrl();
export const salesProcessingUrl = getSalesProcessingUrl();
export const DwApiUrl = process.env.REACT_APP_DW_API_URL;
export const DwAppUrl = process.env.REACT_APP_DW_APP_URL;
export const releaseBucket = process.env.REACT_APP_WF_RELEASE_BUCKET;
export const DwDesignThumbUrl = process.env.REACT_APP_COMPOSITION_THUMB_SRC;
