import { getAccessToken } from '../util';
import { apiUrl } from './apiUrl';
import { request } from './request.js';

export const getUserToDepartments = (filter = {}) => {
  return request
    .get(`${apiUrl}/api/userToDepartments?filter=${JSON.stringify(filter)}`)
    .set('Authorization', getAccessToken())
    .then((res) => res.body || []);
};

export const addUserToDepartment = (userId, departmentId) => {
  return request
    .post(`${apiUrl}/api/userToDepartments/addUserToDepartment`)
    .send({ userId, departmentId })
    .set('Authorization', getAccessToken())
    .then((res) => res);
};

export const removeUserFromDepartment = (userId, departmentId) => {
  return request
    .post(`${apiUrl}/api/userToDepartments/removeUserFromDepartment`)
    .send({ userId, departmentId })
    .set('Authorization', getAccessToken())
    .then((res) => res);
};

export const setDepartmentMembers = (users = [], departmentId) => {
  return request
    .post(`${apiUrl}/api/userToDepartments/setDepartmentMembers`)
    .send({ users, departmentId })
    .set('Authorization', getAccessToken())
    .then((res) => res);
};

export default {
  getUserToDepartments,
  addUserToDepartment,
  removeUserFromDepartment,
  setDepartmentMembers,
};
