import React from 'react';
import { Modal, Spin } from 'antd';
import { CloseCircleTwoTone } from '@ant-design/icons';
import Gallery from 'react-photo-gallery';
const RemovedModal = ({ open, closeModal, assets, imageRenderer }) => {
  return (
    <Modal
      closable={true}
      footer={null}
      open={open}
      onOk={() => closeModal(false)}
      onCancel={() => closeModal(false)}
      centered
      closeIcon={
        <CloseCircleTwoTone
          style={{ fontSize: '24px' }}
          twoToneColor="rgb(255, 0, 0)"
        />
      }
      width={1000}
      styles={{ body: { display: 'relative', height: 630 }}}>
      <div style={{ overflowY: 'auto', marginTop: '50px' }}>
        <Gallery
          photos={assets}
          targetRowHeight={150}
          renderImage={imageRenderer}
          style={{ height: '100%' }}
        />
      </div>
    </Modal>
  );
};

export default RemovedModal;
