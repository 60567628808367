import { getAccessToken } from '../util';
import { apiUrl, DwApiUrl } from './apiUrl';
import { request } from './request.js';

export const googleLogin = (tokenId) =>
  request.post(`${apiUrl}/api/users/google-login/${tokenId}`).then((res) => res.body);

export const logout = () =>
  request
    .post(`${apiUrl}/api/users/logout`)
    .set('Authorization', getAccessToken())
    .then((res) => res.body);

export const getUsers = (filter) =>
  request
    .get(`${apiUrl}/api/users?filter=${JSON.stringify(filter || {})}`)
    .set('Authorization', getAccessToken())
    .then((res) => res.body);

export const getUsersCount = (where) =>
  request
    .get(`${apiUrl}/api/users/count?where=${JSON.stringify(where || {})}`)
    .set('Authorization', getAccessToken())
    .then((res) => res.body);

export const getUsersInRole = (roleName) =>
  request
    .get(`${apiUrl}/api/users/get-users-in-role/${roleName}`)
    .set('Authorization', getAccessToken())
    .then((res) => res.body);

export const getRoles = (filter) =>
  request
    .get(`${apiUrl}/api/roles?filter=${JSON.stringify(filter || {})}`)
    .set('Authorization', getAccessToken())
    .then((res) => res.body);

export const createRole = (data) =>
  request
    .post(`${apiUrl}/api/roles`)
    .set('Authorization', getAccessToken())
    .send(data)
    .then((res) => res.body);

export const updateRole = (role) =>
  request.put(`${apiUrl}/api/roles`).set('Authorization', getAccessToken()).send(role);

export const deleteRole = (id) =>
  request
    .delete(`${apiUrl}/api/roles/${id}`)
    .set('Authorization', getAccessToken())
    .then((res) => res.body);

export const createUser = (data) =>
  request
    .post(`${apiUrl}/api/users`)
    .set('Authorization', getAccessToken())
    .send(data)
    .then((res) => res.body);

export const updateUser = (user) =>
  request
    .put(`${apiUrl}/api/users/${user.id}/safeUpdate`)
    .set('Authorization', getAccessToken())
    .send(user)
    .then((res) => res.body);

export const deleteUser = (id) =>
  request
    .delete(`${apiUrl}/api/users/${id}`)
    .set('Authorization', getAccessToken())
    .then((res) => res.body);

export const activateUsers = (users) =>
  request.post(`${apiUrl}/api/users/activate-users`).set('Authorization', getAccessToken()).send(users);

export const deactivateUsers = (users) =>
  request.post(`${apiUrl}/api/users/deactivate-users`).set('Authorization', getAccessToken()).send(users);

export const linkUserToRole = (userId, roleId) =>
  request
    .put(`${apiUrl}/api/users/${userId}/roles/rel/${roleId}`)
    .set('Authorization', getAccessToken())
    .then((res) => res.body);

export const unlinkUserToRole = (userId, roleId) =>
  request
    .delete(`${apiUrl}/api/users/${userId}/roles/rel/${roleId}`)
    .set('Authorization', getAccessToken())
    .then((res) => res.body);

export const requestServerDownload = (data) =>
  request
    .post(`${apiUrl}/api/server-download-histories`)
    .set('Authorization', getAccessToken())
    .send(data)
    .then((res) => res.body);

const _getRequest = (endpoint) =>
  request
    .get(`${apiUrl}/api/${endpoint}`)
    .set('Authorization', getAccessToken())
    .then((res) => res.body.result);

const _postRequest = (endpoint, data) =>
  request
    .post(`${apiUrl}/api/${endpoint}`)
    .set('Authorization', getAccessToken())
    .send(data)
    .then((res) => res.body.result);

const mapFolderStructure = (root = '', depth = 2) => _postRequest('users/mapFolderStructure', { root, depth });

const validateFilesInDirectory = (directory) => _postRequest('users/validateFilesInDirectory', directory);

const insertDirectoryProducts = (directories) => _postRequest('users/insertDirectoryProducts', directories);

const getProductUploadConfig = () => _getRequest('users/getProductUploadConfig');

// S3 Uploader
const getS3UploaderStatus = () => _postRequest('users/configureS3Uploader', {});

const toggleS3UploaderStatus = (enable) => _postRequest('users/configureS3Uploader', { enable });

const auditProductsOnS3 = (ids) => _postRequest('users/auditProductsOnS3', ids);

const auditStorageArray = () => _getRequest('users/auditStorageArray');

const recreateVideosFromStorageArray = (ids) => _postRequest('users/recreateVideosFromStorageArray', ids);

/**
 * Returns signed Urls for all file versions of a product in S3
 * @param id
 * @param {number} offset if supplied it will jump forward/backwards x products form the supplied id
 *  Note the skip will be based on the current product e.g offset +10 will advance 10 videos if the current product is a video
 * @returns {*}
 */
export const getProductS3SignedUrls = (id, offset = 0, wbmType) =>
  _getRequest(`users/getProductS3SignedUrls?id=${id}&offset=${offset}&wbmType=${wbmType}`);

const addVideosToWaveflowDwList = (ids) => _postRequest('users/addVideosToWaveflowDwList', ids);

// Check the product_uploads table to get a count of filed completed per directory
const checkUploadProgress = () => _getRequest('users/checkUploadProgress');

const getProductsUploadStatus = (type) =>
  request
    .get(`${apiUrl}/api/users/products-upload?type=${type}`)
    .set('Authorization', getAccessToken())
    .then((res) => res.body);

const requestProductsUpload = (type, single = '') =>
  request
    .post(`${apiUrl}/api/users/products-upload?type=${type}&single=${single}`)
    .set('Authorization', getAccessToken())
    .then((res) => res.body);

// const getRecentUploadsForViewer = filter =>
//   request
//     .post(`${apiUrl}/api/users/recentUploadsForViewer`)
//     .set('Authorization', getAccessToken())
//     .send(filter)
//     .then(res => res.body);

// const makeTableForVideoViewer = filter =>
//   request
//     .post(`${apiUrl}/api/users/makeTableForVideoViewer`)
//     .set('Authorization', getAccessToken())
//     .send(filter)
//     .then(res => res.body);

// const writeToFile = data =>
//   request
//     .post(`${apiUrl}/api/users/writeToFile`)
//     .set('Authorization', getAccessToken())
//     .send(data)
//     .then(res => res.body);

const getCreatorList = (filter) =>
  request
    .get(`${apiUrl}/api/creators?filter=${JSON.stringify(filter || {})}`)
    .set('Authorization', getAccessToken())
    .then((res) => res.body);

const getSubrefList = () =>
  request
    .get(`${apiUrl}/api/users/getSubrefList`)
    .set('Authorization', getAccessToken())
    .then((res) => res.body);

const getDistributorList = (filter) =>
  request
    .get(`${apiUrl}/api/distributors?filter=${JSON.stringify(filter)}`)
    .set('Authorization', getAccessToken())
    .then((res) => {
      return res.body;
    });

const getTopSellers = (filter) =>
  request
    .post(`${apiUrl}/api/users/topSellers`)
    .set('Authorization', getAccessToken())
    .send(filter)
    .then((res) => res.body);

const downloadFromSalesDashboard = (data) =>
  request
    .post(`${apiUrl}/api/users/downloadFromSalesdashboard`)
    .set('Authorization', getAccessToken())
    .send(data)
    .then((res) => res.body);

const getRevenueByShootId = (filter) =>
  request
    .post(`${apiUrl}/api/users/getRevenueByShootId`)
    .set('Authorization', getAccessToken())
    .send(filter)
    .then((res) => res.body);

const getDataOfID = (filter) =>
  request
    .post(`${apiUrl}/api/users/getDataOfId`)
    .set('Authorization', getAccessToken())
    .send(filter)
    .then((res) => res.body);

const getEntireShootInfo = (filter) =>
  request
    .post(`${apiUrl}/api/users/getEntireShootInfo`)
    .set('Authorization', getAccessToken())
    .send(filter)
    .then((res) => res.body);

const getNewTopSellers = (filter) =>
  request
    .post(`${apiUrl}/api/users/newTopSellers`)
    .set('Authorization', getAccessToken())
    .send(filter)
    .then((res) => res.body);

const getNewRevenueByShootId = (filter) =>
  request
    .post(`${apiUrl}/api/users/getNewRevenueByShootId`)
    .set('Authorization', getAccessToken())
    .send(filter)
    .then((res) => res.body);

const getRevenueByShootBI = () =>
  request
    .get(`${apiUrl}/sales/revenuePerShoot`)
    .set('Authorization', getAccessToken())
    .then((res) => res.body);

const getYearlyRevBreakdownByShoot = (data) =>
  request
    .post(`${apiUrl}/sales/yearlyBreakdownForShoot`)
    .set('Authorization', getAccessToken())
    .send(data)
    .then((res) => res.body);

const getRevenueByEmployeeBI = (filter) =>
  request
    .post(`${apiUrl}/sales/revenuePerEmployee/`)
    .set('Authorization', getAccessToken())
    .send(filter)
    .then((res) => res.body);

const getRevenueByAssetBI = (filter) =>
  request
    .post(`${apiUrl}/sales/revenuePerAsset`)
    .set('Authorization', getAccessToken())
    .send(filter)
    .then((res) => res.body);

const getDistributors = (filter) =>
  request
    .get(`${apiUrl}/api/distributors?filter=${JSON.stringify(filter || {})}`)
    .set('Authorization', getAccessToken())
    .then((res) => res.body);

const addDistributor = (data) =>
  request
    .post(`${apiUrl}/api/distributors`)
    .set('Authorization', getAccessToken())
    .send(data)
    .then((res) => res.body);

const editDistributor = (data) =>
  request
    .put(`${apiUrl}/api/distributors/${data.id}`)
    .set('Authorization', getAccessToken())
    .send(data)
    .then((res) => res.body);

const deleteDistributor = (id) =>
  request
    .delete(`${apiUrl}/api/distributors/${id}`)
    .set('Authorization', getAccessToken())
    .then((res) => res.body);

const getProductsColumns = () =>
  request
    .get(`${apiUrl}/api/products/getColumns`)
    .set('Authorization', getAccessToken())
    .then((res) => res.body);

const getWatermarks = () => request.get(`${apiUrl}/watermarks`).then((res) => res.body);

const addWatermark = (data) =>
  request
    .post(`${apiUrl}/api/users/uploadWatermark`)
    .set('Authorization', getAccessToken())
    .send(data)
    .then((res) => res.body);

const getProductswithErrors = (filter) =>
  request
    .get(`${apiUrl}/api/product-has-errors?filter=${JSON.stringify(filter || {})}`)
    .set('Authorization', getAccessToken())
    .then((res) => res.body);

const getQueueErrors = (filter) =>
  request
    .post(`${apiUrl}/api/queue-errors/get-queue-errors`)
    .set('Authorization', getAccessToken())
    .send(filter)
    .then((res) => res.body);

const getDistinctQueueErrors = (filter) =>
  request
    .post(`${apiUrl}/api/queue-errors/get-distinct-queue-errors`)
    .set('Authorization', getAccessToken())
    .send(filter)
    .then((res) => res.body);

const getQueueErrorsCount = (filter) =>
  request
    .get(`${apiUrl}/api/queue-errors/count?where=${JSON.stringify(filter)}`)
    .set('Authorization', getAccessToken())
    .then((res) => res.body);

const getDistributorData = (filter) =>
  request.post(`${apiUrl}/api/products/get-filtered-data`).set('Authorization', getAccessToken()).send(filter);

const resolveQueueErrors = (ids, requeue = false) =>
  request
    .post(`${apiUrl}/api/queue-errors/resolved`)
    .set('Authorization', getAccessToken())
    .send({
      ids,
      requeue,
    })
    .then((res) => res.body);

const deleteQueueError = (id, reason) =>
  request
    .delete(`${apiUrl}/api/queue-errors/${id}`)
    .set('Authorization', getAccessToken())
    .send({ comment: reason })
    .then((res) => res.body);

const importSalesCSV = (data) =>
  request
    .post(`${apiUrl}/api/salesProcessor/upload`)
    .set('Authorization', getAccessToken())
    .send(data)
    .then((res) => res.body);

export const getDWTasks = (filter) =>
  request
    .get(`${apiUrl}/api/dw-tasks?filter=${JSON.stringify(filter || {})}`)
    .set('Authorization', getAccessToken())
    .then((res) => res.body);

export const countDWTasks = (where) =>
  request
    .get(`${apiUrl}/api/dw-tasks/count?where=${JSON.stringify(where || {})}`)
    .set('Authorization', getAccessToken())
    .then((res) => res.body.count);

export const addDWTask = (data) =>
  request
    .post(`${apiUrl}/api/dw-tasks`)
    .set('Authorization', getAccessToken())
    .send(data)
    .then((res) => res.body);

export const editDWTask = (data) =>
  request
    .put(`${apiUrl}/api/dw-tasks/${data.id}`)
    .set('Authorization', getAccessToken())
    .send(data)
    .then((res) => res.body);

export const deleteDWTask = (id) =>
  request
    .delete(`${apiUrl}/api/dw-tasks/${id}`)
    .set('Authorization', getAccessToken())
    .then((res) => res.body);

export const getDWTaskDesigners = () =>
  request
    .get(`${apiUrl}/api/dw-tasks/designers`)
    .set('Authorization', getAccessToken())
    .then((res) => res.body);

export const getTasksWithDesigns = () =>
  request
    .get(`${apiUrl}/api/dw-tasks/tasksWithDesigns`)
    .set('Authorization', getAccessToken())
    .then((res) => res.body);

export const getDWTasksDesigns = (filter) =>
  request
    .get(`${apiUrl}/api/dw-tasks/designs?filter=${JSON.stringify(filter || {})}`)
    .set('Authorization', getAccessToken())
    .then((res) => res.body);

export const getDWTasksDesignsNumber = (where) =>
  request
    .get(`${apiUrl}/api/dw-tasks/designs/count?where=${JSON.stringify(where || {})}`)
    .set('Authorization', getAccessToken())
    .then((res) => res.body);

export const updateDWTaskDesignPreview = (data) =>
  request
    .put(`${apiUrl}/api/dw-tasks/designs/updatePreview`)
    .set('Authorization', getAccessToken())
    .send(data)
    .then((res) => res.body);

export const editDWTaskDesign = (data) =>
  request
    .put(`${apiUrl}/api/dw-tasks/designs/bulkUpdate`)
    .set('Authorization', getAccessToken())
    .send(data)
    .then((res) => res.body);

const getCsvUploadHistory = () =>
  request
    .get(`${apiUrl}/api/salesDataImporters/csv-upload-history`)
    .set('Authorization', getAccessToken())
    .then((res) => res.body);

const delayProducts = (data) =>
  request
    .post(`${apiUrl}/api/products/delayProducts`)
    .set('Authorization', getAccessToken())
    .send(data)
    .then((res) => res.body);

const assetElasticSearch = (data) =>
  request
    .post(`${apiUrl}/api/open-search-api/queries`)
    .set('Authorization', getAccessToken())
    .send(data)
    .then((res) => res.body);

const createContractorJob = (data) =>
  request
    .post(`${apiUrl}/api/contractorJobs`)
    .set('Authorization', getAccessToken())
    .send(data)
    .then((res) => res.body);

const editContractorJob = (data) =>
  request
    .patch(`${apiUrl}/api/contractorJobs/${data.id}`)
    .set('Authorization', getAccessToken())
    .send(data)
    .then((res) => res.body);

const deleteContractorJob = (id) =>
  request
    .delete(`${apiUrl}/api/contractorJobs/${id}`)
    .set('Authorization', getAccessToken())
    .then((res) => res.body);

const getContractorJobs = (filter = {}) =>
  request
    .get(`${apiUrl}/api/contractorJobs?filter=${JSON.stringify(filter || {})}`)
    .set('Authorization', getAccessToken())
    .then((res) => res.body);

const getJobsWithCompletedPages = (contentType, contractorId) =>
  request
    .get(`${apiUrl}/api/contractorJobs/getAllJobInfo?contentType=${contentType}&contractorId=${contractorId}`)
    .set('Authorization', getAccessToken())
    .then((res) => res.body);

const importreconciliationCSV = (data) =>
  request
    .post(`${apiUrl}/api/reconciliation/upload`)
    .set('Authorization', getAccessToken())
    .send(data)
    .then((res) => res.body);

const getreconciliationReport = (data) =>
  request
    .post(`${apiUrl}/api/productsIngestedByDistributors/getingestion-report`)
    .set('Authorization', getAccessToken())
    .send(data)
    .then((res) => res.body);

const getRejectionReasons = (data) =>
  request
    .post(`${apiUrl}/api/productsIngestedByDistributors/rejection-reasons`)
    .set('Authorization', getAccessToken())
    .send(data)
    .then((res) => res.body);

const getRejectedAssetsIds = (data) =>
  request
    .post(`${apiUrl}/api/distributorProductRejections/rejected-assets`)
    .set('Authorization', getAccessToken())
    .send(data)
    .then((res) => res.body);

const getYearWiseData = (data) =>
  request
    .post(`${apiUrl}/api/productsIngestedByDistributors/year-wise-assets`)
    .set('Authorization', getAccessToken())
    .send(data)
    .then((res) => res.body);

const deleteproductsHistory = (data) =>
  request
    .post(`${apiUrl}/api/productsIngestedByDistributors/delete-prod-history`)
    .set('Authorization', getAccessToken())
    .send(data)
    .then((res) => res.body);

const assetElasticSearchWithVector = (data) =>
  request
    .post(`${apiUrl}/api/open-search-api/queries-vector`)
    .set('Authorization', getAccessToken())
    .send(data)
    .then((res) => res.body);

const assetElasticSearchSelectAll = (data) =>
  request
    .post(`${apiUrl}/api/open-search-api/select-all-search`)
    .set('Authorization', getAccessToken())
    .send(data)
    .then((res) => res.body);

const segmentSalesReport = (data) =>
  request
    .post(`${apiUrl}/api/segment-sales-report`)
    .set('Authorization', getAccessToken())
    .send(data)
    .then((res) => res.body);

const segmentTemplatesReport = (data) =>
  request
    .post(`${apiUrl}/api/segment-templates-report`)
    .set('Authorization', getAccessToken())
    .send(data)
    .then((res) => res.body);

const segmentLeavingReport = (data) =>
  request
    .post(`${apiUrl}/api/segment-leaving-report`)
    .set('Authorization', getAccessToken())
    .send(data)
    .then((res) => res.body);

const productVisibilityReport = (data) =>
  request
    .post(`${apiUrl}/api/subrefStatuses/product-visibility-report`)
    .set('Authorization', getAccessToken())
    .send(data)
    .then((res) => res.body);

const partnerVisibilityReport = (data) =>
  request
    .post(`${apiUrl}/api/subrefStatuses/partner-visibility-report`)
    .set('Authorization', getAccessToken())
    .send(data)
    .then((res) => res.body);

const getIdsForPartnerVisibilityReport = (data) =>
  request
    .post(`${apiUrl}/api/subrefStatuses/partner-visibility-get-ids`)
    .set('Authorization', getAccessToken())
    .send(data)
    .then((res) => res.body);

const getKeywordSalesData = (data) =>
  request
    .post(`${apiUrl}/api/open-search-api/keyword-sales-data`)
    .set('Authorization', getAccessToken())
    .send(data)
    .then((res) => res.body);

const getTopPerformingKeywords = (data) =>
  request
    .post(`${apiUrl}/api/open-search-api/top-performing-keywords`)
    .set('Authorization', getAccessToken())
    .send(data)
    .then((res) => res.body);

const getIngestionFiles = (filter = {}) =>
  request
    .get(`${apiUrl}/api/reconciliationDataImporters?filter=${JSON.stringify(filter || {})}`)
    .set('Authorization', getAccessToken())
    .then((res) => res.body);

const getAllDWTemplates = (filter) => {
  return request
    .post(`${apiUrl}/api/designwizard/search`)
    .set('Authorization', getAccessToken())
    .send(filter)
    .then((res) => res.body);
};

const updateAllDWTemplatesStatus = (ids) => {
  return request
    .post(`${apiUrl}/api/designwizard/update-status-id`)
    .set('Authorization', getAccessToken())
    .send(ids)
    .then((res) => res.body);
};

const updateTemplateName = (payload) => {
  return request
    .post(`${apiUrl}/api/designwizard/rename-templates`)
    .set('Authorization', getAccessToken())
    .send(payload)
    .then((res) => res.body);
};
const getRevenuePerShoot = (filter) =>
  request
    .post(`${apiUrl}/sales/revenuePerShootByFilter`)
    .set('Authorization', getAccessToken())
    .send(filter)
    .then((res) => res.body);

const getDesignWizardTemplates = (filter) => {
  return request
    .post(`${apiUrl}/api/dw/templates`)
    .set('Authorization', getAccessToken())
    .send(filter)
    .then((res) => res.body);
};

const getAllDwDesigns = (filter, dwToken) => {
  return request
    .get(`${DwApiUrl}/api/compositions?filter=${JSON.stringify(filter || {})}`)
    .set('Authorization', dwToken)
    .then((res) => res.body);
};

const getAllDwDesignsCount = (filter, dwToken) => {
  return request
    .get(`${DwApiUrl}/api/compositions/count?where=${JSON.stringify(filter || {})}`)
    .set('Authorization', dwToken)
    .then((res) => res.body);
};

const updateCompositionName = (compositionId, payload, dwToken) => {
  return request
    .put(`${DwApiUrl}/api/compositions/${compositionId}`)
    .set('Authorization', dwToken)
    .send(payload)
    .then((res) => res.body);
};

const deleteDWcompositions = (compIds, dwToken) => {
  return request
    .delete(`${DwApiUrl}/api/compositions/${JSON.stringify(compIds)}`)
    .set('Authorization', dwToken)
    .then((res) => res.body);
};

export const updateCompositionStatus = (compositionIds, statusId, dwToken) => {
  return request
    .put(`${DwApiUrl}/api/compositions/${JSON.stringify(compositionIds || {})}/updateStatus?statusId=${statusId}`)
    .set('Authorization', dwToken)
    .send({})
    .then((res) => res.body);
};

export const saveDwCompositionAddToTask = (payload, dwToken) => {
  return request
    .post(`${DwApiUrl}/api/waveflowTaskDesigns/bulkCreate`)
    .set('Authorization', dwToken)
    .send(payload)
    .then((res) => res.body);
};
export const updateDwCompositionAddToTask = (id, payload, dwToken) => {
  return request
    .put(`${DwApiUrl}/api/waveflowTaskDesigns/${id}`)
    .set('Authorization', dwToken)
    .send(payload)
    .then((res) => res.body);
};
export const removeDwCompositionAddToTask = (id, dwToken) => {
  return request
    .delete(`${DwApiUrl}/api/waveflowTaskDesigns/${id}`)
    .set('Authorization', dwToken)
    .then((res) => res.body);
};

export const getDwWFTaskDesigns = (filter, dwToken) => {
  return request
    .get(`${DwApiUrl}/api/waveflowTaskDesigns/?filter=${JSON.stringify(filter || {})}`)
    .set('Authorization', dwToken)
    .then((res) => res.body);
};

export const getDwAllCategories = (dwToken) => {
  return request
    .get(`${DwApiUrl}/api/templateCategories/?filter=${JSON.stringify({})}`)
    .set('Authorization', dwToken)
    .then((res) => res.body);
};

export const addDWCategory = (payload, dwToken) => {
  return request
    .post(`${DwApiUrl}/api/templateCategories`)
    .set('Authorization', dwToken)
    .send(payload)
    .then((res) => res.body);
};

export const editDWCategory = (payload, id, dwToken) => {
  return request
    .put(`${DwApiUrl}/api/templateCategories/${id}`)
    .set('Authorization', dwToken)
    .send(payload)
    .then((res) => res.body);
};

export const addDesignerRoles = (payload) => {
  return request.post(`${apiUrl}/api/dw-add-designers`).set('Authorization', getAccessToken()).send(payload);
};
export const downloadReleases = (data) =>
  fetch(`${apiUrl}/api/shoots/download-releases`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      authorization: getAccessToken(),
    },
  });

const getDesignWizardTemplatesRandom = (dwToken, status = 'approved', numOfTemplates = 48) => {
  return request
    .get(`${DwApiUrl}/api/templates/randomTempStatus/${status}?numOfTemplates=${numOfTemplates}`)
    .set('Authorization', dwToken)
    .then((res) => res.body);
};

const updateTemplateStatus = (dwToken, updatedTemplates) => {
  const payload = { updatedTemplates };
  return request
    .post(`${DwApiUrl}/api/templates/updateTempStatus`)
    .send(payload)
    .set('Authorization', dwToken)
    .then((res) => res.body);
};

const multiStatusUpdate = (dwToken, data) => {
  return request
    .patch(`${DwApiUrl}/api/templates/multi-status-update`)
    .send(data)
    .set('Authorization', dwToken)
    .then((res) => res.body);
};

const getCommentedTemplates = (dwToken, limit = 48) => {
  return request
    .get(`${DwApiUrl}/api/templates/randomTempStatusWithComments?numOfTemplates=${limit}`)
    .set('Authorization', dwToken)
    .then((res) => res.body);
};

const getTemplatesCount = (dwToken, status) => {
  const api = `${DwApiUrl}/api/templates/totalTempStatus/${status}`;
  return request
    .get(api)
    .set('Authorization', dwToken)
    .then((res) => res.body);
};

const getSaleCSVHistory = (filter) => {
  return request
    .get(`${apiUrl}/api/salesDataImporters?filter=${JSON.stringify(filter || {})}`)
    .set('Authorization', getAccessToken())
    .then((res) => res.body);
};

const getSearchCategoryKeywords = (data) =>
  request.post(`${apiUrl}/api/assets/asset-search-category-keywords`).set('Authorization', getAccessToken()).send(data);

export default {
  apiUrl,
  validateFilesInDirectory,
  googleLogin,
  logout,
  getUsers,
  getUsersCount,
  getRoles,
  createUser,
  deleteUser,
  linkUserToRole,
  unlinkUserToRole,
  requestServerDownload,
  insertDirectoryProducts,
  checkUploadProgress,
  mapFolderStructure,
  getProductUploadConfig,
  getProductsUploadStatus,
  requestProductsUpload,
  getCreatorList,
  getSubrefList,
  getDistributorList,
  getTopSellers,
  getNewTopSellers,
  downloadFromSalesDashboard,
  getRevenueByShootId,
  getNewRevenueByShootId,
  getDataOfID,
  getEntireShootInfo,
  getRevenueByShootBI,
  getYearlyRevBreakdownByShoot,
  getRevenueByEmployeeBI,
  getRevenueByAssetBI,
  getS3UploaderStatus,
  toggleS3UploaderStatus,
  auditProductsOnS3,
  auditStorageArray,
  addVideosToWaveflowDwList,
  recreateVideosFromStorageArray,
  getProductS3SignedUrls,
  getDistributors,
  addDistributor,
  editDistributor,
  deleteDistributor,
  getProductsColumns,
  getWatermarks,
  addWatermark,
  getUsersInRole,
  getQueueErrors,
  resolveQueueErrors,
  deleteQueueError,
  importSalesCSV,
  getDWTasks,
  countDWTasks,
  addDWTask,
  editDWTask,
  deleteDWTask,
  getDWTaskDesigners,
  getTasksWithDesigns,
  getDWTasksDesigns,
  getDWTasksDesignsNumber,
  editDWTaskDesign,
  getCsvUploadHistory,
  delayProducts,
  assetElasticSearch,
  createContractorJob,
  editContractorJob,
  deleteContractorJob,
  getContractorJobs,
  getJobsWithCompletedPages,
  importreconciliationCSV,
  getreconciliationReport,
  getQueueErrorsCount,
  getProductswithErrors,
  getDistributorData,
  getRejectionReasons,
  getRejectedAssetsIds,
  getYearWiseData,
  deleteproductsHistory,
  getDistinctQueueErrors,
  assetElasticSearchWithVector,
  assetElasticSearchSelectAll,
  segmentSalesReport,
  segmentTemplatesReport,
  segmentLeavingReport,
  productVisibilityReport,
  partnerVisibilityReport,
  getIdsForPartnerVisibilityReport,
  getKeywordSalesData,
  getIngestionFiles,
  getTopPerformingKeywords,
  getAllDWTemplates,
  updateAllDWTemplatesStatus,
  updateTemplateName,
  getRevenuePerShoot,
  getDesignWizardTemplates,
  getAllDwDesigns,
  getAllDwDesignsCount,
  updateCompositionName,
  updateCompositionStatus,
  saveDwCompositionAddToTask,
  updateDwCompositionAddToTask,
  removeDwCompositionAddToTask,
  deleteDWcompositions,
  addDesignerRoles,
  downloadReleases,
  getDesignWizardTemplatesRandom,
  updateTemplateStatus,
  getCommentedTemplates,
  getTemplatesCount,
  multiStatusUpdate,
  getSaleCSVHistory,
  getSearchCategoryKeywords,
};
