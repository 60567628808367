import { SET_WBM_TYPES } from '../reducers/actionTypes';
import { getWbmTypes } from '../lib/api/wbmTypes';

export const getWbmTypesAndStore = () => async (dispatch) => {
  const result = await getWbmTypes();
  dispatch(setWbmTypes(result.body));
};

const setWbmTypes = (result) => ({
  type: SET_WBM_TYPES,
  payload: result,
});
