import styled from 'styled-components';

export default styled.div`
  text-align: center;
  margin-top: 150px;
  button {
    cursor: pointer;
    font-family: sans-serif !important;
  }
`;
