import React from 'react';
import { Select, Input } from 'antd';

const { Option } = Select;

const DEFAULT_HEADING_SIZE = 3;

const headers = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];

const HeadingInput = ({
  style,
  fieldName,
  text,
  size = DEFAULT_HEADING_SIZE,
  onTextChange,
  onSizeChange,
  placeholder,
}) => {
  const headingSelectAfter = (
    <Select className="select-after" onChange={onSizeChange} value={size}>
      {headers.map((header, i) => (
        <Option value={i + 1} key={header}>
          {header}
        </Option>
      ))}
    </Select>
  );

  return (
    <Input
      style={style}
      key={fieldName}
      id={fieldName}
      placeholder={placeholder || 'heading'}
      value={text}
      onChange={(e) => onTextChange(e.target.value)}
      addonAfter={onSizeChange && headingSelectAfter}
    />
  );
};

export default HeadingInput;
