/* eslint-disable no-case-declarations */
import { cloneDeep } from 'lodash';
import { SET_QUERY_RESULT, UPDATE_QUERY_RESULT } from './actionTypes';

export default (state = {}, action) => {
  switch (action.type) {
    case SET_QUERY_RESULT:
      let updatedList = [];
      if (state.result !== undefined && action.payload.result) {
        const oldList = [...state.result];
        const newList = [...action.payload.result];
        updatedList = [...oldList, ...newList];
      }
      return updatedList.length > 0 ? { ...state, result: updatedList } : cloneDeep(action.payload);
    case UPDATE_QUERY_RESULT:
      const { key, value } = action.payload;
      return {
        ...state,
        [key]: value,
      };
    default:
      return state;
  }
};
