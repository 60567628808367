import { request } from './request.js';
import { getAccessToken } from '../util';
import { apiUrl } from './apiUrl';

export const getTeams = (filter = {}) => {
  let theFilter = {};
  if (filter.all) {
    delete filter.all;
    theFilter = filter;
  } else {
    theFilter = { where: { active: true, ...filter } };
  }
  return request
    .get(`${apiUrl}/api/teams?filter=${JSON.stringify(theFilter)}`)
    .set('Authorization', getAccessToken());
};

export default {
  getTeams,
};
