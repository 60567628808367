import React, { useState } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import { ConfigProvider, Switch as AntSwitch, theme } from 'antd';
import { Provider } from 'react-redux';
import 'intersection-observer';

import enGB from 'antd/es/locale/en_GB';
import moment from 'moment';
import 'moment/locale/en-gb';

import lightBaseTheme from 'material-ui/styles/baseThemes/lightBaseTheme';
import darkBaseTheme from 'material-ui/styles/baseThemes/darkBaseTheme';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import 'video-react/dist/video-react.css';
import styled from 'styled-components';

import Layout from './components/Layout';
import { Login, authenticatedRoutes } from './routes';

import './fontAwesome.js';
import { store } from './reducers/store';
import { setDarkThemeAndStore } from './actions/darkTheme'; // Import your action creator

moment.updateLocale('en-GB');

const StyledAntSwitch = styled(AntSwitch)`
  // Your custom styles here
  // For example:
    position: absolute;
    top: ${({ isDarkTheme }) => (isDarkTheme ?  '22px': '18px' )};
    right: 200px;
  .ant-switch-inner {
    background-color: ${({ isDarkTheme }) => (isDarkTheme ?  '#555': '#ddd' )};
      .ant-switch-inner-checked{
          color: #ddd;
      }
      .ant-switch-inner-unchecked{
          color: #555;
      }
  }
`;

const App = () => {

    const storedTheme = localStorage.getItem('wf_dark_theme');
    const initialTheme = storedTheme && storedTheme === 'false' || !storedTheme ? false : true;
    const [isDarkTheme, setIsDarkTheme] = useState(initialTheme);
    store.dispatch(setDarkThemeAndStore(initialTheme));

    const toggleTheme = () => {
        const newTheme = !isDarkTheme;
        setIsDarkTheme(newTheme);
        localStorage && localStorage.setItem('wf_dark_theme', newTheme.toString());
        store.dispatch(setDarkThemeAndStore(newTheme));
    };

    return (
        <Provider store={store}>
            <MuiThemeProvider muiTheme={getMuiTheme(isDarkTheme ? darkBaseTheme : lightBaseTheme)}>
                <ConfigProvider locale={enGB} theme={{ algorithm: isDarkTheme ? theme.darkAlgorithm : theme.compactAlgorithm }}>
                    <BrowserRouter>
                        <Layout isDarkTheme={isDarkTheme}>
                            <Switch>
                                {authenticatedRoutes}
                                <Login />
                            </Switch>
                        </Layout>
                    </BrowserRouter>
                </ConfigProvider>
            </MuiThemeProvider>
            <StyledAntSwitch
                checked={isDarkTheme}
                onChange={toggleTheme}
                checkedChildren="Dark"
                unCheckedChildren="Light"
                isDarkTheme={isDarkTheme}
            />
        </Provider>
    );
};

export default App;