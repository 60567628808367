import { getWbmTypesAndStore } from 'actions/wbmType';
import { Avatar, Badge, Layout, Popover } from 'antd';
import { pageTitle } from 'menuItems';
import { useEffect, useMemo, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { store } from 'reducers/store';
import { connect } from 'react-redux';
import { getAuthors } from 'lib/api/author';
import { getSignedUrls } from 'lib/api/s3';
import UserMenu from './UserMenu';
import { UserOutlined } from '@ant-design/icons';

const { Header } = Layout;
const { user } = store.getState();
const me = typeof user === 'object' ? user : JSON.parse(user);

const LayoutHeader = withRouter(({ history, headerfn, isDarkTheme }) => {
  const [showUserMenu, setShowUserMenu] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploadedProfile, setUploadedProfile] = useState(null);
  const hideUserMenu = () => setShowUserMenu(false);

  useEffect(() => {
    const { wbmTypes, user } = store.getState();
    if (!wbmTypes.length && user) {
      store.dispatch(getWbmTypesAndStore());
    }
  }, []);

  useMemo(async () => {
    setLoading(true);
    try {
      if (me) {
        const res = await getAuthors({ where: { userId: me.id } });
        let d = res.body[res.body.length - 1];

        if (d && d.profilePicture) {
          const sURLs = await getSignedUrls(process.env.REACT_APP_PROFILE_IMAGE_BUCKET, 'profile', [d.profilePicture]);

          if (sURLs.body?.length === 0) {
            throw new Error('failed to retrieve profile pic from s3');
          }
          setUploadedProfile(sURLs.body[0]);
        }
      }
    } catch (e) {
      console.log(e, "Couldn't load Author data from server");
    }
    setLoading(false);
  }, [headerfn]);

  return (
    <Header style={{ backgroundColor: isDarkTheme ? '#141414' : '#FFFFFF', padding: 0, borderBottom: `1px solid ${isDarkTheme ? '#333' : '#ddd'}`}}>
      <div
        style={{
          marginLeft: 10,
          marginRight: 20,
          display: 'flex',
          justifyContent: 'space-between',
          height: '100%',
        }}>
        <Link to="/">
          <img
            src="/img/logo-wide.png"
            alt=""
          />
        </Link>

        <div style={{fontSize: 24 }}>{pageTitle(history.location.pathname)}</div>
        <div
          style={{
            width: 350,
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            gap: 10,
          }}>
          <a
            href="https://wkf.ms/3S8sdUp"
            target="_blank"
            rel="noopener noreferrer">
            <Avatar
              src="/img/ticket.png"
              style={{ cursor: 'pointer', backgroundColor: '#CBCCCC' }}
            />
          </a>
          <a
            href="https://wkf.ms/3Cmlcdy"
            target="_blank"
            rel="noopener noreferrer">
            <Avatar
              src="/img/help.png"
              style={{ cursor: 'pointer', backgroundColor: '#CBCCCC' }}
            />
          </a>
          <a
            href="https://wkf.ms/3eRCeGx"
            target="_blank"
            rel="noopener noreferrer">
            <Avatar
              src="/img/lightbulb.png"
              style={{ cursor: 'pointer', backgroundColor: '#CBCCCC' }}
            />
          </a>
          <div>
            <Popover
              placement="bottomRight"
              content={<UserMenu hideMenu={hideUserMenu} />}
              open={showUserMenu}
              onOpenChange={setShowUserMenu}
              trigger="click">
              <Badge
                count={0}
                onClick={() => setShowUserMenu(!showUserMenu)}>
                <Avatar
                  icon={uploadedProfile ? <img src={uploadedProfile}></img> : <UserOutlined />}
                  style={{ cursor: 'pointer' }}
                />
              </Badge>
            </Popover>
          </div>
        </div>
      </div>
    </Header>
  );
});

const mapStateToProps = (state) => ({ headerfn: state.headerState });

export default connect(mapStateToProps)(LayoutHeader);
