import { getAccessToken } from '../util';
import { apiUrl } from './apiUrl';
import { request } from './request.js';

export const getCreators = (filter = { where: { active: true } }) =>
  request.get(`${apiUrl}/api/creators?filter=${JSON.stringify(filter)}`).set('Authorization', getAccessToken());

export const performance = (query) =>
  request.post(`${apiUrl}/api/creators/performance`).set('Authorization', getAccessToken()).send(query);

export const getPwDownloadData = (filter) =>
  request
    .post(`${apiUrl}/api/clickthroughAnalytics/getPerformanceData`)
    .set('Authorization', getAccessToken())
    .send(filter);

export const countCreators = (where = {}) =>
  request.get(`${apiUrl}/api/creators/count?where=${JSON.stringify(where)}`).set('Authorization', getAccessToken());

export const createCreator = (shoot) =>
  request.post(`${apiUrl}/api/creators`).set('Authorization', getAccessToken()).send(shoot);

export const updateCreator = (shoot) =>
  request.put(`${apiUrl}/api/creators`).set('Authorization', getAccessToken()).send(shoot);

export const approveCreator = (id, approved) =>
  request.patch(`${apiUrl}/api/creators/${id}`).set('Authorization', getAccessToken()).send({ approved });

export const deleteCreator = (id) =>
  request.delete(`${apiUrl}/api/creators/${id}`).set('Authorization', getAccessToken()).send();

export default {
  getCreators,
  countCreators,
  createCreator,
  updateCreator,
  approveCreator,
  deleteCreator,
  performance,
};
