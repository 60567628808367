import { getAccessToken } from '../util';
import { apiUrl } from './apiUrl';
import { request } from './request.js';
import dayjs from 'dayjs';

export const getEvents = (filter = {}) => {
  return request
    .get(`${apiUrl}/api/contentCalendarEvents?filter=${JSON.stringify(filter)}`)
    .set('Authorization', getAccessToken())
    .then((res) => {
      return { events: res.body, count: (res.body || []).length };
    });
};

export const getEvent = (id) => {
  return request
    .get(`${apiUrl}/api/contentCalendarEvents/${id}`)
    .set('Authorization', getAccessToken())
    .then((res) => res.body);
};

export const createEvent = (data) => {
  return request
    .post(`${apiUrl}/api/contentCalendarEvents`)
    .send(data)
    .set('Authorization', getAccessToken())
    .then((res) => res.body);
};

export const getEventCount = () => {
  return request
    .get(`${apiUrl}/api/contentCalendarEvents/count`)
    .set('Authorization', getAccessToken())
    .then((res) => res.body.count);
};

export const editEvent = (id, data) => {
  return request
    .patch(`${apiUrl}/api/contentCalendarEvents/${id}`)
    .send(data)
    .set('Authorization', getAccessToken())
    .then((res) => res.body);
};

export const deleteEvent = (id) => {
  return request.delete(`${apiUrl}/api/contentCalendarEvents/${id}`).set('Authorization', getAccessToken());
};

export const getAmountOfEvents = (date, months) => {
  //get all events from date for the next 'months' months
  return request
    .post(`${apiUrl}/api/contentCalendarEvents/getAmountOfEvents`)
    .send({ date, months })
    .set('Authorization', getAccessToken())
    .then((res) => {
      return res.body;
    });
};

export const getEventsDropDate = async (startDate, endDate, daysBeforeEvent = 0) => {
  let start = dayjs(startDate);
  let end = dayjs(endDate);
  if (daysBeforeEvent > 0) {
    start = start.add(daysBeforeEvent, 'd');
    end = end.add(daysBeforeEvent, 'd');
  }
  const filter = {
    where: {
      and: [{ actualDate: { gte: start.format('YYYY-MM-DD') } }, { actualDate: { lte: end.format('YYYY-MM-DD') } }],
    },
  };
  const { events } = await getEvents(filter);
  return events.map((event) => {
    let tempDate = dayjs(event.actualDate).subtract(daysBeforeEvent, 'd');
    return { ...event, date: tempDate.format('YYYY-MM-DD') };
  });
};

export const getEventsForContentTypes = (contentTypes) => {
  /* get next 2 weeks of events for each contentType listed  in contentTypes
    Return Object:
    {
      <contentType.id> : {
        isDuplicate: <boolean>,
        original: <contentType.id>,
        events: [events]
      },
      ...etc.
    }
  */
  return request
    .post(`${apiUrl}/api/contentCalendarEvents/getEventsForContentTypes`)
    .send({ contentTypes })
    .set('Authorization', getAccessToken())
    .then((res) => {
      return res.body;
    });
};

export default {
  getEvents,
  createEvent,
  deleteEvent,
  getEventCount,
  editEvent,
  getAmountOfEvents,
  getEventsDropDate,
  getEventsForContentTypes,
  getEvent,
};
