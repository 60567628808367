import { SET_CREATORS_LIST, SET_SUBREFS_LIST } from './actionTypes';

const initialState = {
  creators: [],
  subrefs: [],
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_CREATORS_LIST:
      return {
        ...state,
        creators: payload,
      };

    case SET_SUBREFS_LIST:
      return {
        ...state,
        subrefs: payload,
      };
    default:
      return state;
  }
};

export const getCreators = (state) => state.creators;
export const getSubRefs = (state) => state.subrefs;
