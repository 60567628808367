import { SET_QUEUE_ERRORS, REMOVE_QUEUE_ERRORS } from './actionTypes';
const init = [];
export default (state = init, action) => {
  switch (action.type) {
    case SET_QUEUE_ERRORS:
      return [...action.payload];
    case REMOVE_QUEUE_ERRORS:
      state.some((e, index) => {
        if (e.id === action.payload.id) {
          state.splice(index, 1);
          return true;
        }
        return false;
      });
      return [...state];
    default:
      return state;
  }
};
