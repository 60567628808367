import React, { memo, useEffect } from 'react';
import { Form, Input, Button, Checkbox, DatePicker, Col, Row } from 'antd';
import dayjs from 'dayjs';
import { store } from 'reducers/store';

const formItemLayout = {
  labelAlign: 'left',
  labelCol: {
    sm: { span: 6 },
  },
  wrapperCol: {
    sm: { span: 18 },
  },
};

const AddEentForm = memo((props) => {
  const [form] = Form.useForm();
  const { onSubmitCallback, isEditable = false, onFormReset } = props;
  const { user } = store.getState();
  const useDetails = typeof user === 'object' ? user : JSON.parse(user);

  useEffect(() => {
    form.setFieldsValue({
      name: props.name,
      actualDate: props.actualDate ? dayjs(props.actualDate) : null,
      optimalDropDate: props.optimalDropDate ? dayjs(props.optimalDropDate) : null,
      microContentNeeded: props.microContentNeeded,
      graphicNeeded: props.graphicNeeded,
      sameDateEveryYear: props.sameDateEveryYear,
    });
  }, [props, form]);

  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields().then((values) => {
      onSubmitCallback({ ...values, name: values.name.trim(), createdBy: useDetails.id });
    });
  };

  const onDateChange = (e) => {
    const optimalDropDate = dayjs(e).subtract(70, 'days');
    form.setFieldsValue({ optimalDropDate });
  };

  return (
    <Form
      onFinish={handleSubmit}
      {...formItemLayout}
      form={form}
      layout={'vertical'}>
      <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Required' }]}>
        <Input placeholder="Name" />
      </Form.Item>
      <Form.Item label="Current Date" name="actualDate" rules={[{ required: true, message: 'Required' }]}>
        <DatePicker
          allowClear={false}
          onChange={onDateChange}
          format="MM-DD-YYYY"
        />
      </Form.Item>
      <Form.Item label="Optimal Date" name="optimalDropDate">
        <DatePicker
          disabled={true}
          format="MM-DD-YYYY"
        />
      </Form.Item>
      <Form.Item name="microContentNeeded" valuePropName="checked" initialValue={false}>
        <Checkbox>Micro Content</Checkbox>
      </Form.Item>
      <Form.Item name="graphicNeeded" valuePropName="checked" initialValue={false}>
        <Checkbox>Graphic Content</Checkbox>
      </Form.Item>
      <Form.Item name="sameDateEveryYear" valuePropName="checked" initialValue={false}>
        <Checkbox>Do you want to repeat</Checkbox>
      </Form.Item>

      <Row
        type="flex"
        justify="end">
        <Col span={4}>
          <Button onClick={onFormReset}>Cancel</Button>
        </Col>
        <Col offset={1}>
          <Button
            type="primary"
            htmlType="submit">
            {!isEditable ? 'Save Event' : 'Update Event'}
          </Button>
        </Col>
      </Row>
    </Form>
  );
});

export const WrappedAddEentForm = AddEentForm;