import { message, Spin } from 'antd';
import { removeLocalUser } from 'lib/util';
import { Component } from 'react';
import GoogleLogin from './GoogleLogin';
import { connect } from 'react-redux';
import { login } from '../actions/user';
import LoginWrapper from '../styles/Login';

class Login extends Component {
  state = { loading: false };

  responseGoogle = (response) => {
    this.setState({ loading: true });
    if (response?.credential) {
      this.props.login(response.credential).catch((err) => {
        this.setState({ loading: false });
        console.log(err);
        message.error('There was an error please contact the IT admin.');
      });
    } else {
      this.setState({ loading: false });
      message.error('Google authentication error.');
    }
  };

  render() {
    removeLocalUser();

    if (this.props.location.search.includes('access_token=')) {
      const token = this.props.location.search.split('access_token=')[1];
      this.responseGoogle({ tokenId: 'access_token_' + token });
      return <LoginWrapper>Logging in, please wait...</LoginWrapper>;
    } else if (window.location.host.startsWith('192.168.24')) {
      window.location.replace('https://wf1.wbml.io/login');
      return <LoginWrapper>Redirecting, please wait...</LoginWrapper>;
    }

    return (
      <LoginWrapper>
        <Spin spinning={this.state.loading}>
          <GoogleLogin
            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
            callback={this.responseGoogle}
          />
        </Spin>
      </LoginWrapper>
    );
  }
}

const mapStateToProps = (state) => ({ user: state.user });

const mapDispatchToProps = (dispatch) => ({
  login: (tokenId) => dispatch(login(tokenId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
