import { UPDATE_PROFILEPIC } from './actionTypes';
const init = [];
export default (state = init, action) => {
  switch (action.type) {
    case UPDATE_PROFILEPIC:
      return action.payload || null;
    default:
      return state;
  }
};
