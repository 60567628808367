import { request } from './request.js';
import { getAccessToken } from '../util';
import { apiUrl } from './apiUrl';

export const getSignedUrls = (bucket, prefix, list) =>
  request
    .post(`${apiUrl}/signed-url/${bucket}/?prefix=${prefix}`)
    .set('Authorization', getAccessToken())
    .send({ list });

export const getSignedUrlForUpload = (bucket, prefix) =>
  request.get(`${apiUrl}/signed-url/${bucket}?key=${prefix}`).set('Authorization', getAccessToken());
