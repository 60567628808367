import { Redirect, Route } from 'react-router-dom';
import asyncComponent from './AsyncComponent';
import { store } from './reducers/store';

import LoginPage from './components/Login';
import Home from './pages/Home';
import { useRef } from 'react';
import subcategoryContentEditor from 'pages/pageBuilder/SubCategoryPages/subCategoryPageEditor/subcategoryContentEditor';

const DWVideos = asyncComponent(() => import('./components/DWVideos'));
const DWTasks = asyncComponent(() => import('./pages/dwTasks'));
const DWTaskEditor = asyncComponent(() => import('./pages/dwTasks/DWTaskEditor'));
const dwTasksDesigns = asyncComponent(() => import('./pages/dwTasksDesigns'));
const TemplateTable = asyncComponent(() => import('./components/uploads/TemplateTable'));
const ProductAudit = asyncComponent(() => import('./components/uploads/Administrator'));
// const S3Viewer = asyncComponent(() => import('./components/S3Viewer'));
const JSONUploadsMenu = asyncComponent(() => import('./components/uploads/JSONUploadsMenu'));
const AssetSearch = asyncComponent(() => import('./pages/assets/assetSearch'));
const DWTemplateSearch = asyncComponent(() => import('./pages/dwTasks/dwTemplateSearch'));
const DwAdminTemplate = asyncComponent(() => import('./pages/dwTasks/dwAdminTemplate'));
const RevenuePerAsset = asyncComponent(() => import('./pages/businessIntelligence/revenuePerAsset'));
const RevenuePerShoot = asyncComponent(() => import('./pages/businessIntelligence/revenuePerShoot'));
const RevPerEmployee = asyncComponent(() => import('./components/businessIntel/RevPerEmployee'));
const CsvImporter = asyncComponent(() => import('./pages/csvImporter'));
const ShootsList = asyncComponent(() => import('./components/ShootList'));
const ShootView = asyncComponent(() => import('./pages/shootMngmt/shootView'));
const ShootsCalendar = asyncComponent(() => import('./pages/shootMngmt/shootsCalendar'));
const AddShootVideoFolders = asyncComponent(() => import('./pages/shootMngmt/addShootVideoFolders'));
// const AddShootPhotoFolders = asyncComponent(() =>
//   import('./pages/shootMngmt/addShootPhotoFolders')
// );
const ManageShootVideoFolders = asyncComponent(() => import('./pages/shootMngmt/manageShootVideoFolders'));
const PostProductionProgress = asyncComponent(() => import('./pages/shootMngmt/postProductionProgress'));
// const AddShootVideos = asyncComponent(() => import('./pages/shootMngmt/addShootVideos'));
const ApproveShootAssets = asyncComponent(() => import('./pages/shootMngmt/approveShootAssets'));
const AddShootModels = asyncComponent(() => import('./pages/shootMngmt/addShootModels'));
const AddShootProperties = asyncComponent(() => import('./pages/shootMngmt/addShootProperties'));
const ProductUploader = asyncComponent(() => import('./pages/shootMngmt/productUploader/index'));
const Distributors = asyncComponent(() => import('./pages/distributors'));
const DistributorEditor = asyncComponent(() => import('./pages/distributors/DistributorEditor'));
const SendSubrefs = asyncComponent(() => import('./pages/distributors/SendSubrefs'));
const DownloadCSV = asyncComponent(() => import('./pages/distributors/DownloadCSV'));
const QueueErrors = asyncComponent(() => import('./pages/queueErrors'));
const AutoAudit = asyncComponent(() => import('./pages/autoAudit'));
const CustomACL = asyncComponent(() => import('./pages/customACL'));
const Roles = asyncComponent(() => import('./pages/roles'));
const Users = asyncComponent(() => import('./pages/users'));
const IStockKeywords = asyncComponent(() => import('./pages/IStockKeywords'));
const AssignModelReleases = asyncComponent(() => import('./pages/assets/assignModelReleases'));
const AssignPropertyReleases = asyncComponent(() => import('./pages/assets/assignPropertyReleases'));
const FixAssets = asyncComponent(() => import('./pages/assets/fixAssets'));
const DelayAssets = asyncComponent(() => import('./pages/assets/delayAssets'));
const AssetDetails = asyncComponent(() => import('./pages/assets/assetDetails'));
const VideoFrameExtraction = asyncComponent(() => import('./pages/assets/videoFrameExtraction'));
const FinalAssetApproval = asyncComponent(() => import('./pages/assets/finalApproval'));
const LiveAssets = asyncComponent(() => import('./pages/assets/liveAssets'));
const CreateImageAi = asyncComponent(() => import('./pages/assets/createImageAI'));
const ManualUploads = asyncComponent(() => import('./pages/assets/manualUploads'));
// const YTDFunnel = asyncComponent(() => import('./pages/reports/statusReport'));
const ProductHistory = asyncComponent(() => import('./pages/reports/productHistory'));
const ProductStatus = asyncComponent(() => import('./pages/reports/productStatus'));
const DwSegmentationReport = asyncComponent(() => import('./pages/dwSegmentationReport'));
const DwAllTemplate = asyncComponent(() => import('./pages/dwAllTemplates'));
const DwAllDesigns = asyncComponent(() => import('./pages/dwAllDesigns'));
const DwTemplatesCheck = asyncComponent(() => import('./pages/dwCheckTemplates'));
const DWFonts = asyncComponent(() => import('./pages/dwFonts'));
const Backlog = asyncComponent(() => import('./pages/reports/backlog'));
const SentToMarket = asyncComponent(() => import('./pages/reports/sentToMarket'));
const Performance = asyncComponent(() => import('./pages/reports/performance'));
const TopSellers = asyncComponent(() => import('./pages/reports/topSellers'));
const ContractorReport = asyncComponent(() => import('./pages/reports/contractorReport'));
const ClickThroughReports = asyncComponent(() => import('./pages/webReports/clickThrough'));
const WebPerformance = asyncComponent(() => import('./pages/webReports/Performance'));
const LiveAssetsReport = asyncComponent(() => import('./pages/reports/liveAssets'));
const LiveAssetsReportNew = asyncComponent(() => import('./pages/reports/liveAssetsReport'));
// const TrendAnalysis = asyncComponent(() =>
//   import('./pages/reports/trendAnalysis')
// );
const UploadVisibilityReport = asyncComponent(() => import('./pages/reports/uploadVisibility'));
const AddDescriptions = asyncComponent(() => import('./pages/assets/addDescriptionAdobe'));
// const AddDescriptions = asyncComponent(() => import('./pages/assets/addDescription'));
const AddSVGKeywords = asyncComponent(() => import('./pages/assets/addSVGKeywords'));
const Contractors = asyncComponent(() => import('./pages/contractors'));
const ContentCollections = asyncComponent(() => import('./pages/webReports/contentCollection'));
const contentCollectionDetails = asyncComponent(() =>
  import('./pages/webReports/contentCollection/contentCollectionDetails'),
);
const Collections = asyncComponent(() => import('./pages/collections'));
const Collection = asyncComponent(() => import('./pages/collections/items'));
const Spaghetti = asyncComponent(() => import('./pages/spaghetti'));
const TemplateMaker = asyncComponent(() => import('./pages/aiTemplateMaker'));
const VideoMaker = asyncComponent(() => import('./pages/videoMaker'));
const SpaghettiAssets = asyncComponent(() => import('./pages/spaghetti/assets'));
const CategoryManager = asyncComponent(() => import('./pages/pageBuilder/CategoryManager'));
const AddCategoryTemplate = asyncComponent(() => import('./pages/pageBuilder/AddCategoryTemplate'));

const DWBuilder = asyncComponent(() => import('./pages/pageBuilder/dwBuilder'));
const PWBuilder = asyncComponent(() => import('./pages/pageBuilder/pwBuilder'));
const PWLandingPageEditor = asyncComponent(() => import('./pages/pageBuilder/PWLandingPageEditor'));
// const LPEditorRedirect = asyncComponent(() => import('./pages/pageBuilder/LandingPages/LPEditorRedirect'));
const AuthorEditor = asyncComponent(() => import('./pages/pageBuilder/Authors/authorEditor/index.js'));
const BlogEditorRedirect = asyncComponent(() => import('./pages/pageBuilder/BlogEditorRedirect'));
const categoryPageEditor = asyncComponent(() => import('./pages/pageBuilder/CategoryPages/categoryPageEditor'));
// const subCategoryPageEditor = asyncComponent(() =>
//   import('./pages/pageBuilder/SubCategoryPages/subCategoryPageEditor'),
// );
const learnCategoryEditor = asyncComponent(() => import('./pages/pageBuilder/learnPages/learnCategory'));
const learnPageEditor = asyncComponent(() => import('./pages/pageBuilder/learnPages/learnPage'));
// const Trends = asyncComponent(() => import('./pages/trends/index.js'));
const CheckAICaption = asyncComponent(() => import('./pages/assets/checkAICaption'));
const RegisterUploads = asyncComponent(() => import('./pages/assets/registerUploads'));

const MrAudit = asyncComponent(() => import('./pages/management/mr-audit/index'));
const TeamManagement = asyncComponent(() => import('./pages/teamManagement'));
const AssignContentCalendarEvents = asyncComponent(() => import('./pages/assets/assignContentCalendarEvents'));
const TopPerformer = asyncComponent(() => import('./pages/webReports/topPerformer'));
const ContentCalendarEvents = asyncComponent(() => import('./pages/contentCalendarEvents'));
const ContentCalendarEventEditor = asyncComponent(() => import('./pages/contentCalendarEvents/eventEditor'));
const ContentCalendar = asyncComponent(() => import('./pages/contentCalendar'));
const WBMBuilder = asyncComponent(() => import('./pages/pageBuilder/wbmBuilder'));
const WBMLandingPageEditor = asyncComponent(() => import('./pages/pageBuilder/WBMLandingPageEditor'));
const ContractorJobs = asyncComponent(() => import('./pages/pageBuilder/Jobs'));
const DWNps = asyncComponent(() => import('./pages/webReports/dwNps'));
const CaptoionTest = asyncComponent(() => import('./pages/assets/captionTest'));
const DwRoleMapping = asyncComponent(() => import('./pages/dwRoleMapping'));
const IngestedreportImporter = asyncComponent(() => import('./pages/IngestedreportImporter'));
const RejectedAssets = asyncComponent(() => import('./pages/IngestedreportImporter/RejectedAssets'));
const PWJobs = asyncComponent(() => import('./pages/pageBuilder/PWJobs'));
const ContractorWorks = asyncComponent(() => import('./pages/pageBuilder/ContractorWorks'));
// const KeywordPage = asyncComponent(() => import('./pages/keywordSalesData/keywordPage'));
const DWTranslatedTemplates = asyncComponent(() => import('./pages/dwTranslatedTemplates'));
const DWAssetCurator = asyncComponent(() => import('./pages/dwAssetCurator'));
// const TopPerformingKeywords = asyncComponent(() => import('./pages/keywordSalesData/topPerformingKeywords'));
const DwTemplateTranslationsApproval = asyncComponent(() =>
  import('./pages/dwTranslatedTemplates/DwTemplateTranslationsApproval'),
);
const pageBuilderPublish = asyncComponent(() => import('./pages/pageBuilder/Publish'));
const DWaddDesigners = asyncComponent(() => import('./pages/dwAdddesigners'));
const MogrtCategoryReport = asyncComponent(() => import('./pages/reports/mogrtReport'));
const ReleaseDownload = asyncComponent(() => import('./pages/assets/releaseDownload'));
const DwManualCheck = asyncComponent(() => import('./pages/dwTemplatesManualCheck'));
const DwTemplatesSetEditDifficulty = asyncComponent(() => import('./pages/dwTemplatesSetEditDifficulty'));
const DwHashCheck = asyncComponent(() => import('./pages/dwTemplatesHashCheck'));
const ExternalKeywordGenerator = asyncComponent(() => import('./pages/externalContentKeywordGenerator'));
const AssignModels = asyncComponent(() => import('./pages/assets/assignModels'));
const AssignModelsNew = asyncComponent(() => import('./pages/assets/assignModelReleaseNew'));
const DWCategoriesPage = asyncComponent(() => import('./pages/dwCategories'));
/* eslint-disable react/display-name */
const routes = [
  { path: '/admin/auto-audit', component: AutoAudit },
  { path: '/admin/acl', component: CustomACL },
  { path: '/admin/roles', component: Roles },
  { path: '/admin/users', component: Users },
  { path: '/admin/audit', component: ProductAudit },
  { path: '/admin/istock-keywords', component: IStockKeywords },
  { path: '/admin/team-management', component: TeamManagement },
  { path: '/admin/content-calendar', component: ContentCalendar },
  { path: '/admin/dw-rolemapping', component: DwRoleMapping },
  { path: '/admin/dw-add-designers', component: DWaddDesigners },
  {
    path: '/upload/templates',
    component: () => <TemplateTable type="template" />,
  },
  {
    path: '/upload/animations',
    component: () => <TemplateTable type="animation" />,
  },
  { path: '/upload/json', component: JSONUploadsMenu },
  { path: '/assets/search', component: AssetSearch },
  {
    path: '/assets/assign-model-releases',
    component: AssignModelReleases,
    exact: false,
  },
  {
    path: '/assets/assign-property-releases',
    component: AssignPropertyReleases,
    exact: false,
  },
  { path: '/assets/fix-assets', component: FixAssets },
  { path: '/assets/asset-details', component: AssetDetails },
  { path: '/assets/video-frame-extraction', component: VideoFrameExtraction },
  { path: '/assets/add-descriptions', component: AddDescriptions },
  { path: '/assets/check-ai-captions', component: CheckAICaption },
  { path: '/assets/add-svg-keywords', component: AddSVGKeywords },
  { path: '/assets/approval', component: FinalAssetApproval },
  { path: '/assets/live', component: LiveAssets },
  { path: '/assets/manual-uploads', component: ManualUploads },
  // { path: '/assets/additional-keywords', component: AdditionalKeywords },
  // { path: '/assets/s3-viewer', component: S3Viewer },
  { path: '/assets/register-uploads', component: RegisterUploads },
  { path: '/assets/delay-assets', component: DelayAssets },
  { path: '/assets/create-imageai', component: CreateImageAi },
  { path: '/assets/assign-models', component: AssignModels },
  { path: '/assets/assign-model-releases-new', component: AssignModelsNew },
  {
    path: '/assets/assign-content-calendar-events',
    component: AssignContentCalendarEvents,
  },
  { path: '/assets/caption-test', component: CaptoionTest },
  {
    path: '/assets/external-keywords-generator',
    component: ExternalKeywordGenerator,
  },
  { path: '/dwvideo-upload', component: DWVideos },
  { path: '/dw-templates', component: DWTemplateSearch },
  { path: '/dw-admin-templates', component: DwAdminTemplate },
  { path: '/dw-translated-templates', component: DWTranslatedTemplates },
  { path: '/dw-asset-curator', component: DWAssetCurator },
  {
    path: '/dw-templatestranslation-approval',
    component: DwTemplateTranslationsApproval,
  },
  { path: '/dw-tasks', component: DWTasks },
  { path: '/dw-tasks/create', component: DWTaskEditor },
  { path: '/dw-tasks/edit/:id', component: DWTaskEditor },
  { path: '/dw-tasks/details/:id', component: DWTaskEditor },
  { path: '/dw-tasks/designs', component: dwTasksDesigns },
  {
    path: '/dw-user-segmentation-report/:tab',
    component: DwSegmentationReport,
  },
  { path: '/dw-all-templates', component: DwAllTemplate },
  { path: '/dw-templates-manualcheck', component: DwManualCheck },
  { path: '/dw-templates-set-edit-difficulty', component: DwTemplatesSetEditDifficulty },
  { path: '/dw-templates-hashcheck', component: DwHashCheck },
  { path: '/dw-all-designs', component: DwAllDesigns },
  { path: '/dw-check-templates', component: DwTemplatesCheck },
  { path: '/dw-categories', component: DWCategoriesPage },
  { path: '/dw-fonts', component: DWFonts },
  // { path: '/reports/ytd-funnel', component: YTDFunnel },
  { path: '/reports/backlog', component: Backlog },
  { path: '/reports/product-history', component: ProductHistory },
  { path: '/reports/sent-to-market', component: SentToMarket },
  { path: '/reports/performance', component: Performance },
  { path: '/reports/top-sellers', component: TopSellers },
  { path: '/reports/contractorReport', component: ContractorReport },
  { path: '/webreports/click-through-reports', component: ClickThroughReports },
  // { path: '/reports/trend', component: TrendAnalysis },
  { path: '/reports/upload-visibility', component: UploadVisibilityReport },
  { path: '/reports/live', component: LiveAssetsReport },
  { path: '/reports/live-asset-report', component: LiveAssetsReportNew },
  { path: '/reports/product-status', component: ProductStatus },
  { path: '/webreports/website-performance', component: WebPerformance },
  { path: '/webreports/top-performers', component: TopPerformer },
  { path: '/webreports/dw-nps', component: DWNps },
  { path: '/bi/revenue-per-shoot', component: RevenuePerShoot },
  { path: '/bi/revenue-per-employee', component: RevPerEmployee },
  { path: '/bi/revenue-per-asset', component: RevenuePerAsset },
  { path: '/bi/csv-importer', component: CsvImporter },
  { path: '/bi/ingested-report', component: IngestedreportImporter },
  { path: '/bi/rejected-assets', component: RejectedAssets },
  // { path: '/bi/keyword-sales', component: KeywordPage },
  // { path: '/bi/top-keywords', component: TopPerformingKeywords },
  { path: '/distributors', component: Distributors },
  { path: '/distributors/create', component: DistributorEditor },
  { path: '/distributors/edit/:id', component: DistributorEditor },
  { path: '/distributors/details/:id', component: DistributorEditor },
  { path: '/distributors/sendsubrefs/:id', component: SendSubrefs },
  { path: '/distributors/downloadcsv', component: DownloadCSV },
  { path: '/contractors', component: Contractors },
  { path: '/content-collections', component: ContentCollections },
  { path: '/content-collection/:id', component: contentCollectionDetails },
  { path: '/collections', component: Collections },
  { path: '/collection/:id', component: Collection },
  { path: '/spaghetti/image-video', component: Spaghetti },
  { path: '/spaghetti/templates', component: Spaghetti },
  { path: '/spaghetti/ai-template-maker', component: TemplateMaker },
  { path: '/spaghetti/video-maker', component: VideoMaker },
  { path: '/spaghetti/video-maker/:id', component: SpaghettiAssets },
  { path: '/spaghetti/ai-template-maker/:id', component: SpaghettiAssets },
  { path: '/spaghetti/:id', component: SpaghettiAssets },
  { path: '/errors', component: QueueErrors },
  { path: '/shoots', component: ShootsList },
  { path: '/shoots-view', component: ShootView },
  { path: '/download-releases', component: ReleaseDownload },
  { path: '/shoots/approve-post-production', component: ShootsList },
  {
    path: '/shoots/post-production-progress',
    component: PostProductionProgress,
  },
  { path: '/shoots/calendar', component: ShootsCalendar },
  // { path: '/shoots/video-post-production', component: AddShootVideos },
  { path: '/shoots/product-uploader', component: ProductUploader },
  {
    path: '/shoots/product-uploader-design-team',
    component: () => <ProductUploader teamFolder={'DESIGN_TEAM'} />,
  },
  {
    path: '/shoots/product-uploader-photography-team',
    component: () => <ProductUploader teamFolder={'PHOTOGRAPHY_TEAM'} />,
  },
  { path: '/shoots/add-video-folders', component: AddShootVideoFolders },
  // { path: '/shoots/add-photo-folders', component: AddShootPhotoFolders },
  { path: '/shoots/add-shoot-models', component: AddShootModels },
  { path: '/shoots/add-shoot-prs', component: AddShootProperties },
  { path: '/shoots/folders/registervideos', component: ShootsList },
  { path: '/shoots/folders/registerphotos', component: ShootsList },
  { path: '/shoots/add-models', component: ShootsList },
  { path: '/shoots/add-prs', component: ShootsList },
  { path: '/shoots/folders', component: ManageShootVideoFolders, exact: false },
  {
    path: '/shoots/:id/approve-post-production',
    component: ApproveShootAssets,
  },
  { path: '/maintenance/mr-audit', component: MrAudit },
  {
    path: '/page-builder/pikwizard/landing-page/create',
    component: PWLandingPageEditor,
    bypassSpacing: true,
  },
  {
    path: '/page-builder/pikwizard/landing-page/edit/:id',
    component: PWLandingPageEditor,
    bypassSpacing: true,
  },
  {
    path: '/page-builder/wbm/landing-page/create',
    component: WBMLandingPageEditor,
    bypassSpacing: true,
  },
  {
    path: '/page-builder/wbm/landing-page/edit/:id',
    component: WBMLandingPageEditor,
    bypassSpacing: true,
  },
  // {
  //   path: '/page-builder/:site/landing-page/create',
  //   component: LPEditorRedirect,
  //   bypassSpacing: true,
  // },
  // {
  //   path: '/page-builder/:site/landing-page/edit/:id',
  //   component: LPEditorRedirect,
  //   bypassSpacing: true,
  // },
  {
    path: '/page-builder/designwizard/:tab',
    component: DWBuilder,
  },
  {
    path: '/page-builder/pikwizard/:tab',
    component: PWBuilder,
  },
  {
    path: '/page-builder/wbm/:tab',
    component: WBMBuilder,
  },
  {
    path: '/page-builder/contractor-works',
    component: ContractorWorks,
  },
  {
    path: '/page-builder/publish',
    component: pageBuilderPublish,
  },
  {
    path: '/page-builder/:site/category-page/create',
    component: categoryPageEditor,
    bypassSpacing: true,
  },
  {
    path: '/page-builder/:site/subcategory-page/create',
    component: subcategoryContentEditor,
    bypassSpacing: true,
  },
  {
    path: '/page-builder/:site/category-page/edit/:id',
    component: categoryPageEditor,
    bypassSpacing: true,
  },
  {
    path: '/page-builder/:site/subcategory-page/edit/:id',
    component: subcategoryContentEditor,
    bypassSpacing: true,
  },
  {
    path: '/page-builder/:site/blog-post/create',
    component: BlogEditorRedirect,
    bypassSpacing: true,
  },
  {
    path: '/page-builder/:site/blog-post/edit/:id',
    component: BlogEditorRedirect,
    bypassSpacing: true,
  },
  {
    path: '/page-builder/:site/learn-category/create',
    component: learnCategoryEditor,
    bypassSpacing: true,
  },
  {
    path: '/page-builder/:site/learn-category/edit/:id',
    component: learnCategoryEditor,
    bypassSpacing: true,
  },
  {
    path: '/page-builder/:site/learn-page/create',
    component: learnPageEditor,
    bypassSpacing: true,
  },
  {
    path: '/page-builder/:site/learn-page/edit/:id',
    component: learnPageEditor,
    bypassSpacing: true,
  },
  {
    path: '/page-builder/:site/author/create',
    component: AuthorEditor,
    bypassSpacing: true,
  },
  {
    path: '/page-builder/:site/author/edit/:id',
    component: AuthorEditor,
    bypassSpacing: true,
  },
  {
    path: '/page-builder/jobs/',
    component: ContractorJobs,
    bypassSpacing: true,
  },
  { path: '/page-builder/pw-jobs', component: PWJobs },
  { path: '/category-manager', component: CategoryManager },
  {
    path: '/category-manager/:categoryId/add-template',
    component: AddCategoryTemplate,
  },
  {
    path: '/user-profile',
    component: () => <AuthorEditor isUserMode={true} />,
    bypassSpacing: true,
  },

  // { path: '/trends', component: Trends },
  { path: '/content-calendar-events', component: ContentCalendarEvents },
  {
    path: '/content-calendar-events/new',
    component: ContentCalendarEventEditor,
  },
  {
    path: '/content-calendar-events/:id',
    component: ContentCalendarEventEditor,
  },
  { path: '/', component: Home },
  { path: '/reports/mogrt-category-report', component: MogrtCategoryReport },
];
/* eslint-enable react/display-name */

const AuthenticatedRoute = ({ component: RouteComponent, bypassSpacing, ...rest }) => {
  const { user } = store.getState();
  const scrollRef = useRef(null);
  const route = (
    <Route
      {...rest}
      render={(props) =>
        user ? (
          <RouteComponent
            {...props}
            scrollRef={scrollRef}
          />
        ) : (
          <Redirect to={{ pathname: '/login' }} />
        )
      }
    />
  );

  // if page wants to start with 0 margins and padding they can set bypassSpacing = true
  return bypassSpacing ? (
    route
  ) : (
    <div
      ref={scrollRef}
      style={{
        //margin: '24px 16px',
        padding: window.location.pathname === '/' ? 0 : 16,
        paddingTop: 0,
        overflow: 'auto',
        height: '95%',
      }}>
      {route}
    </div>
  );
};

export const authenticatedRoutes = routes.map(({ path, component, bypassSpacing, exact }) => (
  <AuthenticatedRoute
    exact={exact !== false}
    key={path}
    path={path}
    component={component}
    bypassSpacing={bypassSpacing}
  />
));

export const Login = () => (
  <Route
    exact
    path="/login"
    render={(props) => <LoginPage {...props} />}
  />
);
