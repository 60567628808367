import dayjs from 'dayjs';
import { getShoots } from 'lib/api/shoot';
import { getTeams } from 'lib/api/team';
import { dateFilters } from 'lib/util';

export const getData = async ({ start, end }, cb) => {
  try {
    const filter = dateFilters(start, end);
    filter.order = 'shootdate ASC';

    const [{ body: shoots }, { body: teams }] = [await getShoots(filter), await getTeams()];

    shoots.forEach((item) => {
      item.shootdate = dayjs(item.shootdate).format('MMM D, YYYY');
      item.teamDetails = teams.find((t) => t.id === item.team);
      return item;
    });
    cb(shoots);
  } catch (err) {
    console.log('get data error', err);
    cb([]);
  }
};

export default { getData };
