import { getAccessToken } from '../util';
import { apiUrl } from './apiUrl';
import { request } from './request.js';

export const getAuthors = (filter = {}) => {
  return request.get(`${apiUrl}/api/authors?filter=${JSON.stringify(filter)}`).set('Authorization', getAccessToken());
};

export const createAuthor = (data) => {
  return request
    .post(`${apiUrl}/api/authors/create-author`)
    .send(data)
    .set('Authorization', getAccessToken())
    .then((res) => res.body.id);
};

export const getAuthorCount = (where) => {
  return request
    .get(`${apiUrl}/api/authors/count?where=${JSON.stringify(where || {})}`)
    .set('Authorization', getAccessToken())
    .then((res) => res.body.count);
};

export const editAuthor = (id, data) =>
  request.patch(`${apiUrl}/api/authors/${id}`).send(data).set('Authorization', getAccessToken());

export const updateAuthor = (id, data) =>
  request.put(`${apiUrl}/api/authors/${id}`).send(data).set('Authorization', getAccessToken());

export const deleteAuthor = (id) => {
  return request.delete(`${apiUrl}/api/authors/${id}`).set('Authorization', getAccessToken());
};

export default {
  getAuthors,
  createAuthor,
  deleteAuthor,
  getAuthorCount,
  editAuthor,
  updateAuthor,
};
