/* eslint-disable no-case-declarations */
import { cloneDeep } from 'lodash';
import { ADD_TO_DOWNLOAD, CLEAR_DOWNLOAD, REMOVE_FROM_DOWNLOAD } from './actionTypes';

export default (state = [], action) => {
  switch (action.type) {
    case ADD_TO_DOWNLOAD:
      return [...state, action.payload];
    case REMOVE_FROM_DOWNLOAD:
      const index = state.indexOf(action.payload);
      return [...state.slice(0, index), ...state.slice(index + 1)];
    case CLEAR_DOWNLOAD:
      return cloneDeep(action.payload);
    default:
      return state;
  }
};
