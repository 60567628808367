import { getAccessToken } from '../util';
import { apiUrl } from './apiUrl';
import { request } from './request.js';

export const createHistory = (data) => {
  return request.post(`${apiUrl}/api/contentHistories`).set('Authorization', getAccessToken()).send(data);
};

export default {
  createHistory,
};
