export const SET_USER = 'SET_USER';
export const SET_FILTER = 'SET_FILTER';
export const DELETE_FILTER = 'DELETE_FILTER';
export const SET_QUERY_RESULT = 'SET_QUERY_RESULT';
export const ADD_TO_DOWNLOAD = 'ADD_TO_DOWNLOAD';
export const REMOVE_FROM_DOWNLOAD = 'REMOVE_FROM_DOWNLOAD';
export const CLEAR_DOWNLOAD = 'CLEAR_DOWNLOAD';
export const UPDATE_QUERY_RESULT = 'UPDATE_QUERY_RESULT';
export const SET_CREATORS_LIST = 'SET_CREATOR_LIST';
export const SET_SUBREFS_LIST = 'SET_SUBREFS_LIST';
export const SET_CREATORS = 'SET_CREATORS';
export const SET_DISTRIBUTORS = 'SET_DISTRIBUTORS';
export const ADD_DISTRIBUTOR = 'ADD_DISTRIBUTOR';
export const EDIT_DISTRIBUTOR = 'EDIT_DISTRIBUTOR';
export const REMOVE_DISTRIBUTOR = 'REMOVE_DISTRIBUTOR';
export const SET_PRODUCTS_COLUMNS = 'SET_PRODUCTS_COLUMNS';
export const SET_WATERMARKS = 'SET_WATERMARKS';
export const ADD_WATERMARK = 'ADD_WATERMARK';
export const SET_QUEUE_ERRORS = 'SET_QUEUE_ERRORS';
export const REMOVE_QUEUE_ERRORS = 'REMOVE_QUEUE_ERRORS';
export const SET_DW_TASKS = 'SET_DW_TASKS';
export const SET_COUNT_DW_TASKS = 'SET_COUNT_DW_TASKS';
export const ADD_DW_TASK = 'ADD_DW_TASK';
export const EDIT_DW_TASK = 'EDIT_DW_TASK';
export const REMOVE_DW_TASK = 'REMOVE_DW_TASK';
export const SET_DW_TASKS_DESIGNS = 'SET_DW_TASKS_DESIGNS';
export const EDIT_DW_TASK_DESIGN = 'EDIT_DW_TASK_DESIGN';
export const SET_TRENDS = 'SET_TRENDS';
export const SET_TRENDS_DATE = 'SET_TRENDS_DATE';
export const SET_TRENDS_CATEGORY = 'SET_TRENDS_CATEGORY';
export const SET_TRENDS_BY_CATEGORY = 'SET_TRENDS_BY_CATEGORY';
export const SET_IMAGE_GALLERY = 'SET_IMAGE_GALLERY';
export const SET_MORE_IMAGES = 'SET_MORE_IMAGES';
export const SET_WBM_TYPES = 'SET_WBM_TYPES';
export const SET_CONTENT_CALENDAR_EVENTS = 'SET_CONTENT_CALENDAR_EVENTS';
export const UPDATE_PROFILEPIC = 'UPDATE_PROFILEPIC';
export const SET_SIMILAR_IMAGE_BASE64 = 'SET_SIMILAR_IMAGE_BASE64';
export const IS_DARK_THEME = 'IS_DARK_THEME';
