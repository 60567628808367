import { cloneDeep } from 'lodash';
import { SET_CONTENT_CALENDAR_EVENTS } from './actionTypes';
const init = [];
export default (state = init, action) => {
  switch (action.type) {
    case SET_CONTENT_CALENDAR_EVENTS:
      return cloneDeep(action.payload);
    default:
      return state;
  }
};
