import api from '../lib/api/index';
import { getLocalUser, removeLocalUser, setLocalUser } from '../lib/util';
import {
  ADD_TO_DOWNLOAD,
  CLEAR_DOWNLOAD,
  DELETE_FILTER,
  REMOVE_FROM_DOWNLOAD,
  SET_FILTER,
  SET_QUERY_RESULT,
  SET_USER,
  UPDATE_QUERY_RESULT,
  UPDATE_PROFILEPIC,
} from '../reducers/actionTypes';
import { populateCreators } from './creator';

export const login = (tokenId) => (dispatch) => {
  const user = getLocalUser();
  if (!user && tokenId) {
    return api.googleLogin(tokenId).then((body) => {
      if (body?.user) {
        const { user } = body;
        console.log(user);
        if (user.redirect) {
          window.location.replace(user.redirect + '/login/?access_token=' + user.accessToken.id);
        } else {
          setLocalUser(user);
          window.location.replace('/');
          console.log('relocate');
        }
      }
    });
  } else if (user) {
    const dateCreated = new Date(user.accessToken.created);
    const dateLogout = new Date(dateCreated.valueOf() + 12 * 60 * 60 * 1000);
    // auto logout after 12h
    if (new Date() > dateLogout) {
      dispatch(logout());
    } else {
      dispatch(setUserAction(user));
    }
  }
  dispatch(populateCreators());
};

export const logout = () => (dispatch) => {
  api.logout();
  removeLocalUser();
  dispatch(setUserAction(null));
};

export const setUser = (user) => (dispatch) => {
  console.log('called', user);
  dispatch(setUserAction(user));
};

export const updateFilterObject = (filterObj) => (dispatch) => {
  dispatch(setFilterObject(filterObj));
};
export const updateQueryObject = (queryObj) => (dispatch) => {
  dispatch(changeQueryObject(queryObj));
};

export const getQueryResult = (result) => (dispatch) => {
  dispatch(setQueryResult(result));
};
export const deleteFilterObject = () => (dispatch) => {
  dispatch(deleteFilter());
};
export const addAssetToDownload = (id) => (dispatch) => {
  dispatch(addToDownloads(id));
};

export const deleteAssetFromDownload = (asset) => (dispatch) => {
  dispatch(removeFromDownloads(asset));
};
export const clearDownloadsList = () => (dispatch) => {
  dispatch(clearDownloads());
};

export const changeProfilePic = (payload) => (dispatch) => {
  dispatch(changeProfilePicture(payload));
};

const setUserAction = (user) => ({
  type: SET_USER,
  payload: user,
});

const setFilterObject = (filter) => ({
  type: SET_FILTER,
  payload: filter,
});

const deleteFilter = () => ({
  type: DELETE_FILTER,
  payload: {},
});
const setQueryResult = (result) => ({
  type: SET_QUERY_RESULT,
  payload: result,
});

const addToDownloads = (id) => ({
  type: ADD_TO_DOWNLOAD,
  payload: id,
});

const removeFromDownloads = (id) => ({
  type: REMOVE_FROM_DOWNLOAD,
  payload: id,
});
const clearDownloads = () => ({
  type: CLEAR_DOWNLOAD,
  payload: [],
});
const changeQueryObject = (queryObj) => ({
  type: UPDATE_QUERY_RESULT,
  payload: queryObj,
});
const changeProfilePicture = (payload) => ({
  type: UPDATE_PROFILEPIC,
  payload: payload || null,
});
