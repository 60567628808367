import { getAccessToken } from '../util';
import { apiUrl } from './apiUrl';
import { request } from './request.js';

export const getShoots = (filter = {}, includeDeleted = false) => {
  const include = ['creators', { tempFolders: 'files' }];
  if (!includeDeleted) {
    if (filter.where) filter.where.deleted = false;
    else filter.where = { deleted: false };
    if (filter.include) {
      if (typeof filter.include === 'object') {
        if (Array.isArray(filter.include)) include.push(...filter.include);
        else include.push(filter.include);
      }
      if (typeof filter.include === 'string') include.push(filter.include);
    }
  }
  filter.include = include;
  return request.get(`${apiUrl}/api/shoots?filter=${JSON.stringify(filter)}`).set('Authorization', getAccessToken());
};

export const getShootsFQ = (filter = {}, includeDeleted = false) => {
  if (!includeDeleted) {
    if (filter.where) filter.where.deleted = false;
    else filter.where = { deleted: false };
  }

  return request
    .get(`${apiUrl}/api/shoots?filter=${JSON.stringify(Object.assign({}, filter))}`)
    .set('Authorization', getAccessToken());
};

export const getShoot = (id, filter) =>
  request
    .get(`${apiUrl}/api/shoots/${id}${filter ? '?filter=' + JSON.stringify(filter) : ''}`)
    .set('Authorization', getAccessToken());

export const getShootStatusList = () =>
  request.get(`${apiUrl}/api/shoots/status-list`).set('Authorization', getAccessToken());

export const countShoots = (where = {}) =>
  request.get(`${apiUrl}/api/shoots/count?where=${JSON.stringify(where)}`).set('Authorization', getAccessToken());

export const getLocations = () =>
  request.get(`${apiUrl}/api/shoots/unique-locations`).set('Authorization', getAccessToken());

export const createShoot = (shoot) =>
  request.post(`${apiUrl}/api/shoots`).set('Authorization', getAccessToken()).send(shoot);

export const saveWithNoMR = (shoot) =>
  request.post(`${apiUrl}/api/shoots/update-with-no-models`).set('Authorization', getAccessToken()).send(shoot);

export const saveWithNoPR = (shoot) =>
  request.post(`${apiUrl}/api/shoots/update-with-no-props`).set('Authorization', getAccessToken()).send(shoot);

export const updateShoot = (shoot) =>
  request.put(`${apiUrl}/api/shoots`).set('Authorization', getAccessToken()).send(shoot);

export const patchShoot = (shoot) =>
  request.patch(`${apiUrl}/api/shoots/${shoot.id}`).set('Authorization', getAccessToken()).send(shoot);

export const approveShoot = (id, status) =>
  request.patch(`${apiUrl}/api/shoots/${id}`).set('Authorization', getAccessToken()).send({ status });

export const hardDeleteShoot = (id) =>
  request.delete(`${apiUrl}/api/shoots/${id}`).set('Authorization', getAccessToken()).send();

export const deleteShoot = (shoot) =>
  request
    .patch(`${apiUrl}/api/shoots/${shoot.id}`)
    .set('Authorization', getAccessToken())
    .send({ ...shoot, deleted: true });

export const deleteShootCreator = (shootId, creatorId) =>
  request
    .delete(`${apiUrl}/api/shoots/${shootId}/creators/rel/${creatorId}`)
    .set('Authorization', getAccessToken())
    .send();

export const addShootCreator = (shootId, creatorId) =>
  request
    .put(`${apiUrl}/api/shoots/${shootId}/creators/rel/${creatorId}`)
    .set('Authorization', getAccessToken())
    .send();

export const getShootsByIds = (ids) =>
  request.post(`${apiUrl}/api/shoots/get-shoots`).set('Authorization', getAccessToken()).send({ ids });

export const getProductsApi = (data) => {
  return request.post(`${apiUrl}/api/products/productsautoaudit`).set('Authorization', getAccessToken()).send(data);
};

export const markShootAsCCAssigned = (shootId) => {
  return request.put(`${apiUrl}/api/shoots/${shootId}/cceventsassigned`).set('Authorization', getAccessToken()).send();
};

export const getShootList = (data) => {
  return request.post(`${apiUrl}/api/shoots/get-shoot-list`).set('Authorization', getAccessToken()).send(data);
};

export default {
  getShoots,
  getShootsFQ,
  countShoots,
  createShoot,
  updateShoot,
  approveShoot,
  deleteShoot,
  addShootCreator,
  deleteShootCreator,
  saveWithNoMR,
  saveWithNoPR,
  getShootsByIds,
  getProductsApi,
  markShootAsCCAssigned,
  getShootList,
};
