export const ACTION_TYPE = {
  MANUAL_LIST: 'MANUAL_LIST',
  RESEND_LIST: 'RESEND_LIST',
  SELECTED_MANUAL: 'SELECTED_MANUAL',
  MODELS_LIST: 'MODELS_LIST',
  RECOGNIZED_LIST: 'RECOGNIZED_LIST',
  PRODUCT_SHOOT_IDS: 'PRODUCT_SHOOT_IDS',
  SHOOT_LIST: 'SHOOT_LIST',
  SINGLE_SHOOT: 'SINGLE_SHOOT',
};

export const initialState = {
  manualIntervention: [],
  resendTomr: [],
  manual: [],
  aimr: [],
  recognizedList: [],
  modelsList: [],
  productShootIDs: [],
  shootLists: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPE.MANUAL_LIST:
      return {
        ...state,
        manualIntervention: action.payload,
        manual: action.payload.filter((el) => el.isChecked).map((itm) => itm.id),
      };
    case ACTION_TYPE.PRODUCT_SHOOT_IDS:
      return { ...state, productShootIDs: [...new Set(action.payload.map((el) => el.shootid))] };
    case ACTION_TYPE.RESEND_LIST:
      return { ...state, resendTomr: action.payload };
    case ACTION_TYPE.SELECTED_MANUAL:
      return { ...state, manual: action.payload };
    case ACTION_TYPE.RECOGNIZED_LIST:
      return { ...state, recognizedList: action.payload };
    case ACTION_TYPE.MODELS_LIST:
      return {
        ...state,
        modelsList: action.payload.sort((a, b) => {
          if (a.refnum > b.refnum) return -1;
          if (a.refnum < b.refnum) return 1;
          return 0;
        }),
      };
    case ACTION_TYPE.SHOOT_LIST:
      return { ...state, shootLists: action.payload };
    case ACTION_TYPE.SINGLE_SHOOT: {
      action.payload;
      const clonedShoot = [...state.shootLists];
      const ind = clonedShoot.findIndex((el) => el.id === action.payload.id);
      clonedShoot[ind] = action.payload;
      return { ...state, shootLists: clonedShoot };
    }

    default:
      return state;
  }
};
