import { SET_PRODUCTS_COLUMNS } from './actionTypes';
const init = [];
export default (state = init, action) => {
  switch (action.type) {
    case SET_PRODUCTS_COLUMNS:
      return [...action.payload];
    default:
      return state;
  }
};
