/* eslint-disable no-case-declarations */
import { DELETE_FILTER, SET_FILTER } from './actionTypes';

export default (state = {}, action) => {
  switch (action.type) {
    case SET_FILTER:
      const { key, value } = action.payload;
      return {
        ...state,
        [key]: value,
      };
    case DELETE_FILTER:
      return {};
    default:
      return state;
  }
};
