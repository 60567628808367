import { request } from './request.js';
import { getAccessToken } from '../util';
import { apiUrl } from './apiUrl';
import { message } from 'antd';

function compareKeys(objectA, objectB) {
  //compare the keys of objectA and objectB and return true if same, false otherwise test
  const aKeys = Object.keys(objectA).sort();
  const bKeys = Object.keys(objectB).sort();
  return JSON.stringify(aKeys) === JSON.stringify(bKeys);
}

export const compile = async (data) => {
  console.log('compile is ', JSON.stringify(data));
  try {
    let res = await request.post(`${apiUrl}/api/contents/build`).set('Authorization', getAccessToken()).send(data);
    if (res.statusCode === 200) {
      return `Successfully compiled!`;
    } else {
      return 'There was an error building the pages';
    }
  } catch (e) {
    return e;
  }
};

export const publishAll = async (data) => {
  try {
    let res = await request.post(`${apiUrl}/api/contents/buildAll`).set('Authorization', getAccessToken()).send(data);
    if (res.statusCode === 200) {
      return `Successfully compiled!`;
    } else {
      return 'There was an error building the pages';
    }
  } catch (e) {
    return e;
  }
};

export const publishPage = async (data) => {
  try {
    let res = await request
      .post(`${apiUrl}/api/contents/publish-page`)
      .set('Authorization', getAccessToken())
      .send(data);
    if (res.statusCode === 200) {
      return `Successfully compiled!`;
    } else {
      return 'There was an error building the pages';
    }
  } catch (e) {
    return e;
  }
};

export const unpublish = async (data) =>
  request.post(`${apiUrl}/api/contents/unpublish`).set('Authorization', getAccessToken()).send(data);

// export const createPost = async (contentTypeID, value) => {
//   //get schema for contentType first
//   let schema = {};

//   try {
//     await request
//       .get(`${apiUrl}/api/contentTypes/${contentTypeID}`)
//       .set('Authorization', getAccessToken())
//       .then((resp) => (schema = resp.body.schema));
//   } catch (e) {
//     return e;
//   }

//   let id;

//   try {
//     let resp = await request
//       .post(`${apiUrl}/api/contents`)
//       .set('Authorization', getAccessToken())
//       .send({ contentTypeID, value, slug: value.slug });
//     if (resp.statusCode !== 200) {
//       return 'There was an error creating the blog post';
//     }

//     id = resp.body.id;
//   } catch (e) {
//     return e;
//   }

//   try {
//     let resp = await request
//       .post(`${apiUrl}/api/contents/build`)
//       .set('Authorization', getAccessToken());
//     if (resp.statusCode === 200) {
//       return `Successfully created new blog post with id ${id}`;
//     } else {
//       return 'There was an error building the blog post';
//     }
//   } catch (e) {
//     return e;
//   }
// };
/**
 * Insert any type db
 * @returns {Promise<string|*>}
 */
export const saveNewPage = async (data) =>
  request.post(`${apiUrl}/api/contents`).set('Authorization', getAccessToken()).send(data);

/**
 * Update any post
 * @returns {Promise<string|*>}
 */
export const updatePage = async (id, data) =>
  request.patch(`${apiUrl}/api/contents/${id}`).set('Authorization', getAccessToken()).send(data);

/**
 * Insert new post of any type db
 * @returns {Promise<string|*>}
 */
export const newBlogPost = async (data) =>
  request.post(`${apiUrl}/api/contents`).set('Authorization', getAccessToken()).send(data);

/**
 * Update blog post
 * @returns {Promise<string|*>}
 */
export const updateBlogPost = async (id, data) =>
  request.patch(`${apiUrl}/api/contents/${id}`).set('Authorization', getAccessToken()).send(data);

export const upsertBlogPost = async (data) =>
  request
    .post(`${apiUrl}/api/contents/upsert-blog-post`)
    .set('Authorization', getAccessToken())
    .send(data)
    .then((res) => res.body);

export const getPost = async (id) => {
  try {
    return await request
      .get(`${apiUrl}/api/contents/${id}`)
      .set('Authorization', getAccessToken())
      .then((res) => res.body);
  } catch (e) {
    return e;
  }
};

export const getContentTypes = async () => {
  try {
    return await request
      .get(`${apiUrl}/api/contentTypes/`)
      .set('Authorization', getAccessToken())
      .then((res) => res.body);
  } catch (e) {
    return e;
  }
};

export const getContents = async (filter) => {
  try {
    return await request
      .get(`${apiUrl}/api/contents?filter=${JSON.stringify(filter)}`)
      .set('Authorization', getAccessToken())
      .then((res) => res.body);
  } catch (e) {
    return e;
  }
};

export const getContentsWithFilter = async (filter) => {
  try {
    return await request
      .post(`${apiUrl}/api/contents/getWithFilter`)
      .set('Authorization', getAccessToken())
      .send(filter)
      .then((res) => res.body);
  } catch (e) {
    return e;
  }
};

export const editPost = async (id, contentType, contentValue) => {
  //get schema for contentType first
  let schema = {};

  try {
    await request
      .get(`${apiUrl}/api/contentTypes/${contentType}`)
      .set('Authorization', getAccessToken())
      .then((resp) => (schema = resp.body.schema));
  } catch (e) {
    return e;
  }

  //compare placeholderSchema to contentValue & if same schema send
  if (compareKeys(contentValue, schema)) {
    try {
      let resp = await request
        .put(`${apiUrl}/api/contents/${id}`)
        .set('Authorization', getAccessToken())
        .send({ contentType, contentValue });
      if (resp.statusCode === 200) {
        return `Successfully edited blog post with id ${resp.body.id}`;
      } else {
        return 'There was an error editing the blog post';
      }
    } catch (e) {
      return e;
    }
  } else {
    return {
      Error: 'content date does not match content schema',
    };
  }
};

export const deletePost = async (id) => {
  try {
    return await request.delete(`${apiUrl}/api/contents/${id}`).set('Authorization', getAccessToken());
  } catch (e) {
    return e;
  }
};

export const generateText = async (subject, att_cnt) => {
  try {
    return await request
      .post(`${apiUrl}/api/contents/generateText`)
      .set('Authorization', getAccessToken())
      .query({ subject, att_cnt })
      .then((res) => {
        if (res.body.result.errno) {
          throw new Error(JSON.stringify(res.body.result));
        }
        return res.body.result;
      });
  } catch (e) {
    console.log('Error generating data:', e);
    message.error(`Error generating data: ${e}`, 10);
  }
};

export const updateOrder = async (data) => {
  try {
    return await request
      .post(`${apiUrl}/api/contents/update-order`)
      .set('Authorization', getAccessToken())
      .send(data)
      .then((res) => res.body);
  } catch (e) {
    return e;
  }
};

export const getAnalyticsReport = async (data) => {
  try {
    return await request
      .post(`${apiUrl}/api/contentanalytics/getreportbytargetsite`)
      .set('Authorization', getAccessToken())
      .send(data)
      .then((res) => res.body);
  } catch (e) {
    console.log('Error contentanalytics/getreportbytargetsite data:', e);
    message.error(`Error contentanalytics/getreportbytargetsite data: ${e}`, 10);
  }
};

export const getReportsContentType = async (contentType, targetsite) => {
  try {
    return await request
      .get(`${apiUrl}/api/contents/creators?contentType=${contentType}&targetsite=${targetsite}`)
      .set('Authorization', getAccessToken())
      .then((res) => res.body);
  } catch (e) {
    console.log('Error contentanalytics/getReportsContentType data:', e);
    message.error(`Error contentanalytics/getReportsContentType data: ${e}`, 10);
  }
};

export const getTopPages = async (data) => {
  try {
    return await request
      .post(`${apiUrl}/api/contentanalytics/gettoppages`)
      .set('Authorization', getAccessToken())
      .send(data)
      .then((res) => res.body);
  } catch (e) {
    console.log('Error contentanalytics/getReportsContentType data:', e);
    message.error(`Error contentanalytics/getReportsContentType data: ${e}`, 10);
  }
};

export const getTrendsApi = async (data) => {
  try {
    return await request
      .post(`${apiUrl}/api/contentanalytics/gettrends`)
      .set('Authorization', getAccessToken())
      .send(data)
      .then((res) => res.body);
  } catch (e) {
    console.log('Error contentanalytics/getReportsContentType data:', e);
    message.error(`Error contentanalytics/getReportsContentType data: ${e}`, 10);
  }
};

export const getContentOrder = async (filter) => {
  try {
    return await request
      .get(`${apiUrl}/api/contentsorts?filter=${JSON.stringify(filter)}`)
      .set('Authorization', getAccessToken())
      .then((res) => res.body[0]);
  } catch (e) {
    return e;
  }
};

export const updateContentOrder = async (data) => {
  try {
    return await request
      .post(`${apiUrl}/api/contentsorts/update-order`)
      .set('Authorization', getAccessToken())
      .send(data)
      .then((res) => res.body);
  } catch (e) {
    return e;
  }
};

export const deleteBlogPostApi = async (data) =>
  request.post(`${apiUrl}/api/contents/deleteDWBlogPost`).set('Authorization', getAccessToken()).send(data);

export const getclicksndownloads = async (data) => {
  try {
    return await request
      .post(`${apiUrl}/api/clickthroughAnalytics/clicksndownloads-by-asset`)
      .set('Authorization', getAccessToken())
      .send(data)
      .then((res) => res.body);
  } catch (e) {
    return e;
  }
};

export const getPerformanceReport = async (data) => {
  try {
    return await request
      .post(`${apiUrl}/api/clickthroughAnalytics/website-performance-report`)
      .set('Authorization', getAccessToken())
      .send(data)
      .then((res) => res.body);
  } catch (e) {
    return e;
  }
};

export const getdwNpsReport = async (data) => {
  try {
    return await request
      .post(`${apiUrl}/api/dwNps/getdwnpsreport`)
      .set('Authorization', getAccessToken())
      .send(data)
      .then((res) => res.body);
  } catch (e) {
    return e;
  }
};

export const getNpsRecords = async (filter = {}) => {
  try {
    return await request
      .get(`${apiUrl}/api/dwNps?filter=${JSON.stringify(filter)}`)
      .set('Authorization', getAccessToken())
      .then((res) => res.body);
  } catch (e) {
    return e;
  }
};

export const upsertNpsRecords = async (where, data) => {
  try {
    return await request
      .post(`${apiUrl}/api/dwNps/upsertWithWhere?where=${JSON.stringify(where)}`)
      .set('Authorization', getAccessToken())
      .send(data)
      .then((res) => res.body);
  } catch (e) {
    return e;
  }
};

export const getDwRegistrations = async (data) => {
  try {
    return await request
      .post(`${apiUrl}/api/contentanalytics/dw-registrations`)
      .set('Authorization', getAccessToken())
      .send(data)
      .then((res) => res.body);
  } catch (e) {
    console.log('Error contentanalytics/dw-registrations data:', e);
    message.error(`Error contentanalytics/dw-registrations data: ${e}`, 10);
  }
};

export const createCategoryConverterRequest = async (slug) => {
  //after user has upload category image, send request to converter to add sizes + convert file type
  //AWS_THUMB_KEY = dw-images/category-image/templates/
  //generatedSlug = w/e
  slug = `${slug.split('/').filter(Boolean).join('/')}/`;
  const qnapPreviewPath = '/media/assets/test/dw-category-images';
  const fixedUrl = slug.replaceAll('/', '--');
  const data = {
    filePath: `s3:///${process.env.REACT_APP_LANDINGPAGE_BUCKET}/${slug}image1.jpg`,
    downloadPath: `${qnapPreviewPath}/${fixedUrl}image1.jpg`,
    type: 'image',
    isTransparent: false,
    category: 'images',
    priority: 0,
    overwrite: false,
    otherSizes: [],
  };
  data.otherSizes.push(
    {
      filePath: `${qnapPreviewPath}/${fixedUrl}image1_small.jpg`,
      deleteAfterUpload: false,
      format: 'jpg',
      sizeType: 'larger-side',
      size: 250,
      s3Bucket: process.env.REACT_APP_LANDINGPAGE_BUCKET,
      s3Key: `${slug}image1_small.jpg`,
      s3StorageClass: 'STANDARD',
    },
    {
      filePath: `${qnapPreviewPath}/${fixedUrl}image1_small.avif`,
      deleteAfterUpload: false,
      format: 'avif',
      sizeType: 'larger-side',
      size: 250,
      s3Bucket: process.env.REACT_APP_LANDINGPAGE_BUCKET,
      s3Key: `${slug}image1_small.avif`,
      s3StorageClass: 'STANDARD',
    },
    {
      filePath: `${qnapPreviewPath}/${fixedUrl}image1.avif`,
      deleteAfterUpload: false,
      format: 'avif',
      sizeType: 'larger-side',
      size: 768,
      s3Bucket: process.env.REACT_APP_LANDINGPAGE_BUCKET,
      s3Key: `${slug}image1.avif`,
      s3StorageClass: 'STANDARD',
    },
    {
      filePath: `${qnapPreviewPath}/${fixedUrl}image1.webp`,
      deleteAfterUpload: false,
      format: 'webp',
      sizeType: 'larger-side',
      size: 768,
      s3Bucket: process.env.REACT_APP_LANDINGPAGE_BUCKET,
      s3Key: `${slug}image1.webp`,
      s3StorageClass: 'STANDARD',
    },
    {
      filePath: `${qnapPreviewPath}/${fixedUrl}image1_small.webp`,
      deleteAfterUpload: false,
      format: 'webp',
      sizeType: 'larger-side',
      size: 250,
      s3Bucket: process.env.REACT_APP_LANDINGPAGE_BUCKET,
      s3Key: `${slug}image1_small.webp`,
      s3StorageClass: 'STANDARD',
    },
  );
  return await request
    .post(`${apiUrl}/api/contents/createCategoryConverterRequest`)
    .set('Authorization', getAccessToken())
    .send(data)
    .then((res) => res.body);
};
