import { getAccessToken } from '../util';
import { apiUrl } from './apiUrl';
import { request } from './request.js';

export const getContentCollections = (filter = {}) => {
  return request
    .get(`${apiUrl}/api/contentCollections?filter=${JSON.stringify(filter)}`)
    .set('Authorization', getAccessToken());
};

export const getOwnedContentCollections = (type) => {
  return request
    .get(`${apiUrl}/api/contentCollections/get-collections?${type ? 'type=' + type : ''}`)
    .set('Authorization', getAccessToken());
};

export const createContentCollection = (data) =>
  request.post(`${apiUrl}/api/contentCollections/create`).set('Authorization', getAccessToken()).send(data);

export const getContentCollection = (id) => {
  return request.get(`${apiUrl}/api/contentCollections/${id}/details`).set('Authorization', getAccessToken());
};

export const getContentCollectionSummary = (data) =>
  request.post(`${apiUrl}/api/contentCollections/get-summary`).set('Authorization', getAccessToken()).send(data);

export const patchContentCollection = (id, data) =>
  request
    .patch(`${apiUrl}/api/contentCollections/${id}`)
    .set('Authorization', getAccessToken())
    .send(data)
    .then((res) => res.body);

export const patchContentCollectionLinks = (id, data) =>
  request.patch(`${apiUrl}/api/contentCollections/${id}/links`).set('Authorization', getAccessToken()).send(data);

export const patchContentCollectionShareedData = (id, sharedWith) =>
  request.patch(`${apiUrl}/api/contentCollections/${id}`).set('Authorization', getAccessToken()).send(sharedWith);

export const deleteLinks = (id, link) =>
  request.delete(`${apiUrl}/api/contentCollections/${id}/links`).set('Authorization', getAccessToken()).send(link);

export const deleteRow = (id) =>
  request.delete(`${apiUrl}/api/contentCollections/${id}`).set('Authorization', getAccessToken());

export default {
  getContentCollections,
  getOwnedContentCollections,
  createContentCollection,
  getContentCollection,
  patchContentCollectionLinks,
  deleteLinks,
  patchContentCollectionShareedData,
};
