import { SET_WBM_TYPES } from './actionTypes';
const init = [];
export default (state = init, action) => {
  switch (action.type) {
    case SET_WBM_TYPES:
      return action.payload;
    default:
      return state;
  }
};
