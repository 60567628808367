import { startCase } from 'lodash';
import { canI } from './lib/util';

/*
  !!!
  WARNING: icon field now uses Ant Design icons, not the old icon names
  Please refer to waveflow/src/components/Layout/Menu.js for what icons are available or to add new ones
  !!!
*/

export const allMenuItems = [
  {
    icon: 'dashboard',
    text: 'Dashboard',
    key: 'dashboard',
    path: '/',
  },
  {
    icon: 'appstore',
    text: 'Collections',
    key: 'collections',
    path: '/collections',
  },
  {
    icon: 'experiment',
    text: 'Spaghetti',
    key: 'spaghetti',
    subs: [
      {
        text: 'Image/Video',
        key: 'spaghetti.imageVideo',
        path: '/spaghetti/image-video',
      },
      {
        text: 'Templates',
        key: 'spaghetti.templates',
        path: '/spaghetti/templates',
      },
      {
        text: 'Template Maker',
        key: 'spaghetti.aiTemplateMaker',
        path: '/spaghetti/ai-template-maker',
      },
      {
        text: 'Video Maker',
        key: 'spaghetti.videoMaker',
        path: '/spaghetti/video-maker',
      },
    ],
  },
  {
    icon: 'picture',
    text: 'Assets',
    key: 'assets',
    subs: [
      { text: 'Asset Search', key: 'assets.search', path: '/assets/search' },
      {
        text: 'Assign Model Releases',
        key: 'assets.assignModelReleases',
        path: '/assets/assign-model-releases',
      },
      {
        text: 'Assign Model Releases (New)',
        key: 'assets.assignModelReleasesNew',
        path: '/assets/assign-model-releases-new',
      },
      {
        text: 'Assign Property Releases',
        key: 'assets.assignPropertyReleases',
        path: '/assets/assign-property-releases',
      },
      { text: 'Download Releases', key: 'assets.downloadReleases', path: '/download-releases' },
      {
        text: 'Final Approval',
        key: 'assets.approval',
        path: '/assets/approval',
      },
      { text: 'Live Assets', key: 'assets.live', path: '/assets/live' },
      {
        text: 'Fix Assets',
        key: 'assets.fixAssets',
        path: '/assets/fix-assets',
      },
      {
        text: 'Asset Details',
        key: 'assets.assetDetails',
        path: '/assets/asset-details',
      },
      {
        text: 'Add Descriptions',
        key: 'assets.addDescription',
        path: '/assets/add-descriptions',
      },
      // {
      //   text: 'Check AI Descriptions',
      //   key: 'assets.checkAICaption',
      //   path: '/assets/check-ai-captions',
      // },
      {
        text: 'Add SVG Keywords',
        key: 'assets.addSVGKeywords',
        path: '/assets/add-svg-keywords',
      },
      {
        text: 'Manual Uploads',
        key: 'assets.manualUpload',
        path: '/assets/manual-uploads',
      },
      {
        text: 'Video Frame Extraction',
        key: 'assets.videoFrameExtraction',
        path: '/assets/video-frame-extraction',
      },
      {
        text: 'Assign CC Events',
        key: 'assets.assign-content-calendar-events',
        path: '/assets/assign-content-calendar-events',
      },
      {
        text: 'Register Uploads',
        key: 'assets.registerUploads',
        path: '/assets/register-uploads',
      },
      {
        text: 'Delay Assets',
        key: 'assets.delayAssets',
        path: '/assets/delay-assets',
      },
      {
        text: 'Create AI Images',
        key: 'assets.createImageAi',
        path: '/assets/create-imageai',
      },
      {
        text: 'External KW Generator',
        key: 'assets.externalKeywordsGenerator',
        path: '/assets/external-keywords-generator',
      },
      {
        text: 'Caption Test',
        key: 'assets.captionTest',
        path: '/assets/caption-test',
      },
      {
        text: 'ImageNonSelect Models',
        key: 'assets.assign-models',
        path: '/assets/assign-models',
      },
    ],
  },
  //   {
  //     icon: 'cloud-upload',
  //     text: 'Uploads',
  //     key: 'uploads',
  //     subs: [
  //       { text: 'Templates', key: 'uploads.templates', path: '/upload/templates' },
  //       { text: 'Animations', key: 'uploads.animations', path: '/upload/animations' },
  //       { text: 'Videos', key: 'uploads.videos', path: '/upload/videos' },
  //     ],
  //   },
  {
    icon: 'camera',
    text: 'Shoot management',
    key: 'shoots',
    subs: [
      { text: 'Shoot list', key: 'shoots.list', path: '/shoots' },
      { text: 'Shoot View', key: 'shoots.view', path: '/shoots-view' },
      {
        text: 'Shoots calendar',
        key: 'shoots.calendar',
        path: '/shoots/calendar',
      },
      {
        text: 'Add Shoot Models',
        key: 'shoots.models.add',
        path: '/shoots/add-models',
      },
      { text: 'Add Shoot PRs', key: 'shoots.prs.add', path: '/shoots/add-prs' },
      //       {
      //         text: 'Register photos',
      //         key: 'shoots.registerPhotos',
      //         path: '/shoots/folders/registerphotos',
      //       },
      //       {
      //         text: 'Register videos',
      //         key: 'shoots.registerRawFiles',
      //         path: '/shoots/folders/registervideos',
      //       },
      //       {
      //         text: 'Pre-select videos',
      //         key: 'shoots.preSelectVideos',
      //         path: '/shoots/folders/pre-select',
      //       },
      //       { text: 'Assign editors', key: 'shoots.assignEditor', path: '/shoots/folders/assign' },
      //       {
      //         text: 'Video post production',
      //         key: 'shoots.videoPostProduction',
      //         path: '/shoots/folders/edited-files',
      //       },
      {
        text: 'Post production approval',
        key: 'shoots.approvePostProduction',
        path: '/shoots/approve-post-production',
      },
      {
        text: 'Post production progress',
        key: 'shoots.postProductionProgress',
        path: '/shoots/post-production-progress',
      },
      //       {
      //         text: 'Product Uploader' + (hasRole(getLocalUser(), 'admin') ? ' [Anim]' : ''),
      //         key: 'shoots.productUploader',
      //         path: '/shoots/product-uploader',
      //       },
      //       {
      //         text: 'Product Uploader' + (hasRole(getLocalUser(), 'admin') ? ' [Design]' : ''),
      //         key: 'shoots.productUploaderDesignTeam',
      //         path: '/shoots/product-uploader-design-team',
      //       },
      //       {
      //         text: 'Product Uploader' + (hasRole(getLocalUser(), 'admin') ? ' [Photo]' : ''),
      //         key: 'shoots.productUploaderPhotographyTeam',
      //         path: '/shoots/product-uploader-photography-team',
      //       },
    ],
  },
  //   {
  //     icon: 'team',
  //     text: 'Maintenance',
  //     key: 'maintenance',
  //     subs: [{ text: 'MR Audit', key: 'maintenance.mrAudit', path: '/maintenance/mr-audit' }],
  //   },
  {
    icon: 'team',
    text: 'Partners',
    key: 'distributors',
    subs: [
      { text: 'Distributors', key: 'distributors.list', path: '/distributors' },
      { text: 'Contractors', key: 'contractors.list', path: '/contractors' },
      //       { text: 'Create', key: 'distributors.create', path: '/distributors/create' },
    ],
  },

  {
    icon: 'fund',
    text: 'Business Intelligence',
    key: 'bi',
    subs: [
      {
        text: 'Revenue per shoot',
        key: 'bi.revenuePerShoot',
        path: '/bi/revenue-per-shoot',
      },
      {
        text: 'Revenue per employee',
        key: 'bi.revenuePerEmployee',
        path: '/bi/revenue-per-employee',
      },
      {
        text: 'Revenue per asset',
        key: 'bi.revenuePerAsset',
        path: '/bi/revenue-per-asset',
      },

      {
        text: 'CSV importer',
        key: 'bi.csvImporter',
        path: '/bi/csv-importer',
      },
      {
        text: 'Ingested Report importer',
        key: 'bi.ingestedReport',
        path: '/bi/ingested-report',
      },
      {
        text: 'Top Performing Keywords',
        key: 'bi.top-keywords',
        path: '/bi/top-keywords',
      },
      {
        text: 'Keyword Sales Data',
        key: 'bi.keyword-sales',
        path: '/bi/keyword-sales',
      },
      /*    {
        text: 'Rejected Assets',
        key: 'bi.rejectedAssets',
        path: '/bi/rejected-assets',
      }, */
    ],
  },
  {
    icon: 'reconciliation',
    text: 'Core Reports',
    key: 'reports',
    subs: [
      // {
      //   text: 'YTD Funnel',
      //   key: 'reports.status',
      //   path: '/reports/ytd-funnel',
      // },
      {
        text: 'Product History',
        key: 'reports.productHistory',
        path: '/reports/product-history',
      },
      {
        text: 'Product Status',
        key: 'reports.productStatus',
        path: '/reports/product-status',
      },
      { text: 'Backlog', key: 'reports.backlog', path: '/reports/backlog' },
      {
        text: 'Sent to market',
        key: 'reports.sentToMarket',
        path: '/reports/sent-to-market',
      },
      {
        text: 'Performance',
        key: 'reports.performance',
        path: '/reports/performance',
      },
      {
        text: 'Top sellers',
        key: 'reports.topSellers',
        path: '/reports/top-sellers',
      },
      // { text: 'Trends', key: 'reports.trend', path: '/trends' },
      { text: 'Live assets', key: 'reports.liveAssets', path: '/reports/live' },
      {
        text: 'Live assets report',
        key: 'reports.liveAssetReport',
        path: '/reports/live-asset-report',
      },
      {
        text: 'Upload Visibility',
        key: 'reports.uploadVisibility',
        path: '/reports/upload-visibility',
      },
      {
        text: 'Contractor Report',
        key: 'reports.contractorReport',
        path: '/reports/contractorReport',
      },
      {
        text: 'Mogrt Category Report',
        key: 'reports.mogrtCategoryReport',
        path: '/reports/mogrt-category-report',
      },
    ],
  },
  {
    icon: 'dashboard',
    text: 'Website Reports',
    key: 'webreports',
    subs: [
      {
        text: 'Website Performance',
        key: 'webreports.performance',
        path: '/webreports/website-performance',
      },
      {
        text: 'Click Through Reports',
        key: 'webreports.clickThrough',
        path: '/webreports/click-through-reports',
      },
      {
        text: 'Collections',
        key: 'contentCollections',
        path: '/content-collections',
      },
      {
        text: 'Top Performers',
        key: 'webreports.topPerformers',
        path: '/webreports/top-performers',
      },
      {
        text: 'NPS',
        key: 'webreports.dwnps',
        path: '/webreports/dw-nps',
      },
    ],
  },
  {
    icon: 'star',
    text: 'Design Wizard',
    key: 'dw',
    subs: [
      // {
      //   text: 'Upload videos',
      //   key: 'dw.uploadVideos',
      //   path: '/dwvideo-upload',
      // },
      { text: 'Template Search', key: 'dw.template', path: '/dw-templates' },
      { text: 'Tasks', key: 'dw.tasks', path: '/dw-tasks' },
      {
        text: 'Tasks Designs',
        key: 'dw.tasks.designs',
        path: '/dw-tasks/designs',
      },
      {
        text: 'Customer Data Report',
        key: 'dw.userSegmentationReport',
        path: '/dw-user-segmentation-report/sales-report',
      },
      {
        text: 'Translated Templates',
        key: 'dw.translated.template',
        path: '/dw-translated-templates',
      },
      {
        text: 'Asset Curator',
        key: 'dw.asset.curator',
        path: '/dw-asset-curator',
      },
      {
        text: 'Translation Approval',
        key: 'dw.templatetranslation.approval',
        path: '/dw-templatestranslation-approval',
      },
      {
        text: 'Templates Status',
        key: 'dw.all.templates',
        path: '/dw-all-templates',
      },
      {
        text: 'All DW Designs',
        key: 'dw.all.designs',
        path: '/dw-all-designs',
      },
      {
        text: 'DW Fonts',
        key: 'dw.fonts',
        path: '/dw-fonts',
      },
      {
        text: 'DW All Categories',
        key: 'dw.categories',
        path: '/dw-categories',
      },
      {
        text: 'Templates Check Thumbnails',
        key: 'dw.check.templates',
        path: '/dw-check-templates',
      },
      { text: 'Admin - Template Approval', key: 'dw.admin-template', path: '/dw-admin-templates' },
      {
        text: 'Manual Templates Check',
        key: 'dw.templates.manualcheck',
        path: '/dw-templates-manualcheck',
      },
      {
        text: 'Templates Hash Check',
        key: 'dw.templates.hashcheck',
        path: '/dw-templates-hashcheck',
      },
      {
        text: 'Templates Set Edit Difficulty',
        key: 'dw.templates.setEditDifficulty',
        path: '/dw-templates-set-edit-difficulty',
      },
    ],
  },
  {
    icon: 'coffee',
    text: 'Page Builder',
    key: 'page-builder',
    subs: [
      {
        text: 'Design Wizard',
        key: 'page-builder.designwizard',
        path: '/page-builder/designwizard/blog-post',
        extraPaths: [
          '/page-builder/designwizard/lead-page',
          '/page-builder/designwizard/category-page',
          '/page-builder/designwizard/subcategory-page',
          '/page-builder/designwizard/blog-post-category',
          '/page-builder/designwizard/blog-tag',
          '/page-builder/designwizard/author',
        ],
      },
      {
        text: 'PikWizard',
        key: 'page-builder.pikwizard',
        path: '/page-builder/pikwizard/landing-page',
        extraPaths: [
          '/page-builder/pikwizard/blog-post',
          '/page-builder/pikwizard/home-page',
          '/page-builder/pikwizard/aipages',
        ],
      },
      {
        text: 'WBM',
        key: 'page-builder.wbm',
        path: '/page-builder/wbm/landing-page',
      },
      {
        text: 'Category Manager',
        key: 'page-builder.category-manager',
        path: '/category-manager',
      },
      {
        text: 'Jobs',
        key: 'page-builder.jobs',
        path: '/page-builder/jobs',
      },
      {
        text: 'PW Jobs',
        key: 'page-builder.pw-jobs',
        path: '/page-builder/pw-jobs',
      },
      {
        text: 'Contractor Works',
        key: 'page-builder.contractor-works',
        path: '/page-builder/contractor-works',
      },
      {
        text: 'Pagebuilder Publish',
        key: 'page-builder.publish',
        path: '/page-builder/publish',
      },
      //       {
      //         text: 'Reports',
      //         key: 'page-builder.reports',
      //         path: '/page-builder/reports',
      //       },
    ],
  },
  {
    icon: 'calendar',
    text: 'Content Calendar',
    key: 'content-calendar',
    subs: [
      //       {
      //         text: 'New Event',
      //         key: 'content-calendar.create-event',
      //         path: '/content-calendar-events/new',
      //       },
      {
        text: 'Event Manager',
        key: 'content-calendar.event-manager',
        path: '/content-calendar-events',
      },
    ],
  },
  {
    icon: 'warning',
    text: 'Errors',
    key: 'errors',
    subs: [{ text: 'List', key: 'errors.list', path: '/errors' }],
  },
  {
    icon: 'setting',
    text: 'Admin',
    key: 'admin',
    subs: [
      { text: 'Auto Audit', key: 'admin.autoAudit', path: '/admin/auto-audit' },
      {
        text: 'MR Audit',
        key: 'maintenance.mrAudit',
        path: '/maintenance/mr-audit',
      },
      { text: 'Access Control List', key: 'admin.ACL', path: '/admin/acl' },
      { text: 'Roles', key: 'admin.roles', path: '/admin/roles' },
      { text: 'Users', key: 'admin.users', path: '/admin/users' },
      { text: 'Product audit', key: 'admin.audit', path: '/admin/audit' },
      {
        text: 'Download Dist CSV',
        key: 'distributors.downloadcsv',
        path: '/distributors/downloadcsv',
      },
      {
        text: 'iStock Keywords',
        key: 'admin.iStockKeywords',
        path: '/admin/istock-keywords',
      },
      {
        text: 'Team Management',
        key: 'admin.team-management',
        path: '/admin/team-management',
      },
      { text: 'JSON Upload', key: 'uploads.adobe', path: '/upload/json' },
      {
        text: 'Content Calendar',
        key: 'admin.content-calendar',
        path: '/admin/content-calendar',
      },
      {
        text: 'DW Role Mapping',
        key: 'admin.dw-rolemapping',
        path: '/admin/dw-rolemapping',
      },
      {
        text: 'DW Add Designers',
        key: 'admin.dw-add-designers',
        path: '/admin/dw-add-designers',
      },
    ],
  },
];

export const pageTitle = (path) => {
  const { origin } = window.location;

  let title = '';
  if (path.includes('login')) {
    title = '';
  } else if (/\/shoots\/[0-9]*\/approve-post-production/.test(path)) {
    title = 'Post Production Approval';
  } else if (path.includes('add-shoot-models')) {
    title = 'Add Shoot Models';
  } else if (path.includes('add-shoot-prs') || path.includes('add-prs')) {
    title = 'Add Shoot PRs';
  } else if (path.includes('collection')) {
    title = 'Collections';
  } else {
    title = allMenuItems.reduce((memo, item) => {
      if (item.subs && Array.isArray(item.subs) && item.subs.length > 0) {
        return item.subs.reduce((subMemo, sub) => {
          if (sub.path && path.includes(sub.path)) {
            return startCase(sub.text);
          }
          return subMemo;
        }, memo);
      }

      if (item.path && path.includes(item.path)) {
        return startCase(item.text);
      }

      return memo;
    }, '');
  }

  if (origin.startsWith('https://wf-staging.wbml.io')) {
    title = `${title} - STAGING`;
  }

  return title;
};

export const menuStatus = (path) => {
  let currentOpen = [];
  let currentSelected = [];

  const menuItems = allMenuItems.filter((item) => {
    if (item.path && path.includes(item.path)) {
      currentSelected = [item.key];
    }
    if (item.subs && Array.isArray(item.subs) && item.subs.length > 0) {
      item.subs = item.subs.filter((sub) => {
        let found = false;

        if (sub.path && path.includes(sub.path)) {
          found = true;
        }

        // if extra paths are defined, then any of those being hit will highlight the menuitem
        if (sub.extraPaths && Array.isArray(sub.extraPaths) && sub.extraPaths.length > 0) {
          for (const p of sub.extraPaths) {
            if (path.includes(p)) {
              found = true;
            }
          }
        }

        if (found) {
          currentSelected = [sub.key];
          currentOpen = [item.key];
        }

        return canI('read', 'menu.' + sub.key);
      });
    }
    if (!canI('read', 'menu.' + item.key)) return false;
    return true;
  });

  return { menuItems, currentOpen, currentSelected };
};
