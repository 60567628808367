import { SET_DW_TASKS_DESIGNS, EDIT_DW_TASK_DESIGN } from './actionTypes';
const init = [];
export default (state = init, action) => {
  switch (action.type) {
    case SET_DW_TASKS_DESIGNS:
      return [...action.payload];
    case EDIT_DW_TASK_DESIGN:
      state.forEach((design, index) => {
        if (action.payload.ids.includes(design.id)) {
          const { status, backgroundColor } = action.payload;
          if (status) {
            state[index].status = status;
          }
          if (backgroundColor) {
            state[index].backgroundColor = backgroundColor;
          }
        }
      });
      return [...state];
    default:
      return state;
  }
};
