import styled from 'styled-components';

export const PageCardWrapper = styled.div`
  width: 40%;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  max-height: 600px;
  overflow: hidden;
  margin: 50px;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  img {
    width: 100%;
    margin-bottom: 5px;
  }
  h2 {
    text-align: center;
  }
`;

export const SearchBar = styled.div`
  display: flex;
  justify-content: center;
  #searchTerm {
    margin: 4px;
  }
  select {
    margin: 4px;
    text-align: center;
  }
  #searchButton {
    margin: 4px;
  }
  #createButton {
    margin: 4px 4px 4px 24px;
  }
  #searchField {
    margin: 4px;
  }
  form {
    display: flex;
    .form-buttons {
      display: flex;
      flex-direction: row;
      .buttons-group {
        margin: 0 10px;
        display: flex;
        flex-direction: column;
      }
    }
  }
`;

export const CardsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const validation = styled.div`
  display: flex;
  flex-direction: column;
  ${(props) => props.flag && '*{border-color: #f5222d;}'}
  ::after {
    content: '${(props) => props.flag}';
    display: inline-block;
    color: #f5222d;
    ${(props) => props.absolute && `margin-bottom: -30px;`}
  }
`;

export const richTextStyles = styled.div`
  :root {
    --bs-blue: #0d6efd;
    --bs-indigo: #6610f2;
    --bs-purple: #6f42c1;
    --bs-pink: #d63384;
    --bs-red: #dc3545;
    --bs-orange: #fd7e14;
    --bs-yellow: #ffc107;
    --bs-green: #198754;
    --bs-teal: #20c997;
    --bs-cyan: #0dcaf0;
    --bs-black: #000;
    --bs-white: #fff;
    --bs-gray: #6c757d;
    --bs-gray-dark: #343a40;
    --bs-gray-100: #f8f9fa;
    --bs-gray-200: #e9ecef;
    --bs-gray-300: #dee2e6;
    --bs-gray-400: #ced4da;
    --bs-gray-500: #adb5bd;
    --bs-gray-600: #6c757d;
    --bs-gray-700: #495057;
    --bs-gray-800: #343a40;
    --bs-gray-900: #212529;
    --bs-primary: #0d6efd;
    --bs-secondary: #6c757d;
    --bs-success: #198754;
    --bs-info: #0dcaf0;
    --bs-warning: #ffc107;
    --bs-danger: #dc3545;
    --bs-light: #f8f9fa;
    --bs-dark: #212529;
    --bs-primary-rgb: 13, 110, 253;
    --bs-secondary-rgb: 108, 117, 125;
    --bs-success-rgb: 25, 135, 84;
    --bs-info-rgb: 13, 202, 240;
    --bs-warning-rgb: 255, 193, 7;
    --bs-danger-rgb: 220, 53, 69;
    --bs-light-rgb: 248, 249, 250;
    --bs-dark-rgb: 33, 37, 41;
    --bs-white-rgb: 255, 255, 255;
    --bs-black-rgb: 0, 0, 0;
    --bs-body-color-rgb: 33, 37, 41;
    --bs-body-bg-rgb: 255, 255, 255;
    --bs-font-sans-serif: system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', 'Noto Sans', 'Liberation Sans',
      Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
    --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
    --bs-body-font-family: var(--bs-font-sans-serif);
    --bs-body-font-size: 1rem;
    --bs-body-font-weight: 400;
    --bs-body-line-height: 1.5;
    --bs-body-color: #212529;
    --bs-body-bg: #fff;
    --bs-border-width: 1px;
    --bs-border-style: solid;
    --bs-border-color: #dee2e6;
    --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
    --bs-border-radius: 0.375rem;
    --bs-border-radius-sm: 0.25rem;
    --bs-border-radius-lg: 0.5rem;
    --bs-border-radius-xl: 1rem;
    --bs-border-radius-2xl: 2rem;
    --bs-border-radius-pill: 50rem;
    --bs-link-color: #0d6efd;
    --bs-link-hover-color: #0a58ca;
    --bs-code-color: #d63384;
    --bs-highlight-bg: #fff3cd;
  }
`;
