import { Alert, Layout } from 'antd';
import { apiUrl } from 'lib/api/apiUrl';
import { useSelector } from 'react-redux';
import Header from './Header';
import Menu from './Menu';

const { Sider, Content } = Layout;

const MainLayout = ({ children, isDarkTheme, ...rest }) => {
  const user = JSON.parse(useSelector(({ user }) => user));

  return (
    <Layout
      style={{
        background: '#EEF0F3',
        position: 'fixed',
        top: 0,
        right: 0,
        left: 0,
        // bottom: 0,
      }}
    >
      {user && user.warnProdDB && (
        <Alert message="Warning! Connected to production database!" banner />
      )}
      {process.env.REACT_APP_ENV !== 'production' &&
        (apiUrl.includes('192.168.24.41') || apiUrl.includes('wf.wbml.io')) && (
          <Alert message="Warning! Connected to production API!" banner />
        )}
      <div style={{ background: '#ffffff' }} />
      <Header isDarkTheme={isDarkTheme} style={{ position: 'fixed', top: 0, right: 0, width: '100%', zIndex: 1 }} {...rest} />
      <Layout>
        <Sider
          trigger={null}
          collapsible
          collapsed={false}
          width={256}
          style={{ position: 'sticky', top: 0, backgroundColor: isDarkTheme ? '#141414' : '#FFFFFF'}}
        >
          <Menu collapsed={false} {...rest} />
        </Sider>
        <Layout style={{ height: '96vh' }}>
          <Content>{children}</Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default MainLayout;
