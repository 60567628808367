import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';

import 'antd/dist/reset.css';
import 'ant-design-pro/dist/ant-design-pro.css';
import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import './index.css';
import App from './App';
/* eslint-disable no-extend-native */
// polyfill for startsWith to fix IE bug
if (!String.prototype.startsWith) {
  String.prototype.startsWith = (searchString, position = 0) => this.indexOf(searchString, position) === position;
}
/* eslint-enable no-extend-native */

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App tab="home" />);

// ReactDOM.render(<App />, document.getElementById('root'));
