import {
  Tooltip,
  Calendar,
  message,
  Modal,
  notification,
  Typography,
  Popconfirm,
  Button,
  Row,
  Col,
  Select,
} from 'antd';
import styled from 'styled-components';
import { CloseOutlined } from '@ant-design/icons';
import React, { memo, useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { groupBy } from 'lodash';
import ShootsThisWeek from './widgets/shootsThisWeek';
// import DescriptionProgress from './widgets/descriptionProgress';
// import ContentCalendar2Weeks from './widgets/contentCalendar2Weeks';
import { createEvent, deleteEvent, editEvent, getEventsDropDate } from 'lib/api/contentCalendarEvent';
import { canI } from 'lib/util';
import { WrappedAddEentForm } from './addEventForm';
// import { getDropDates } from 'lib/api/contentCalendarDropDate';
import { connect } from 'react-redux';
import { getUserToDepartments } from 'lib/api/userToDepartment';

const { Text } = Typography;

const allWidgets = [
  { acl: 'widget.shootsThisWeek', El: ShootsThisWeek },
  // { acl: 'widget.DescriptionProgress', El: DescriptionProgress },
];

const Home = ({ user }) => {
  const widgets = allWidgets.filter((w) => canI('read', w.acl)).map((w) => w.El);
  const [showModal, setShowModal] = useState(false);
  const [eventDate, setEventDate] = useState(null);
  const [events, setEvents] = useState([]);
  const [eventModal, setEventModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [groupByDate, setGroupByDate] = useState(null);
  const [eventsByDate, setEventsByDate] = useState([]);
  const [contentTypes, setContentTypes] = useState([]);
  const [chosenContentType, setChosenContentType] = useState(null);
  const [currentMonth, setCurrentMonth] = useState(null);
  const [currentYear, setCurrentYear] = useState(null);
  const [userToDepartments, setUsersToDepartments] = useState([]);
  // console.log(JSON.parse(user));
  const fetchEvents = async (startDate, endDate) => {
    try {
      const daysBeforeEvent = chosenContentType ? chosenContentType.daysBeforeEventToDrop : 0;
      const events = await getEventsDropDate(startDate, endDate, daysBeforeEvent);
      setEvents(events);
    } catch (error) {
      message.error('Something went wrong fetching events, please try again later.', 10);
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      onPanelChange(dayjs(), 'month');
      const parsedUser = JSON.parse(user);
      const userToDepartments = await getUserToDepartments({ where: { userId: parsedUser.id } });
      setUsersToDepartments(userToDepartments);
    };

    fetchData();
  }, []);

  useEffect(() => {
    const groupOfDates = groupBy(events, 'date');
    setGroupByDate(groupOfDates);
    if (eventDate) {
      const hasEvent = groupOfDates[eventDate];
      setEventsByDate(hasEvent);
    }
  }, [events]);

  useEffect(() => {
    const fetchData = () => {
      if (chosenContentType) {
        const start = dayjs(`${currentYear}-${currentMonth + 1}-01`);
        const end = dayjs(`${currentYear}-${currentMonth + 1}-01`).endOf('month');
        fetchEvents(start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD')).catch((error) => {
          message.error('Something went wrong fetching events, please try again later.', 10);
          console.log(error);
        });
      }
    };

    fetchData();
  }, [chosenContentType]);

  const getListData = (value) => {
    let listData = [];

    for (const ev of events) {
      if (ev.date === value.format('YYYY-MM-DD')) {
        listData.push(ev);
      }
    }
    return listData;
  };

  const getMonthData = (value) => {
    let eventCount = 0;

    for (const ev of events) {
      // if the event is in this month increment the count
      if (ev.date.slice(0, -2) === value.format('YYYY-MM-DD').slice(0, -2)) {
        eventCount++;
      }
    }

    return eventCount;
  };

  const monthCellRender = (value) => {
    const eventsCount = getMonthData(value);
    return eventsCount ? (
      <div className="notes-month">
        <section>{eventsCount} events</section>
      </div>
    ) : null;
  };

  const dateCellRender = (value) => {
    const listData = getListData(value);
    return (
      <ul className="events">
        {listData.map((ev) => (
          <li key={ev.id}>
            <Tooltip title={`${ev.name} ${ev.actualDate.slice(0, 10)}`}>
              <s.eventBlock
                style={{ width: '100%' }}
                ellipsis={true}>
                {ev.name} {ev.actualDate.slice(0, 10)}
              </s.eventBlock>
            </Tooltip>
          </li>
        ))}
      </ul>
    );
  };

  const onSelectDate = async (val) => {
    const selectedDate = val.format('YYYY-MM-DD');
    setEventDate(selectedDate);
    setShowModal(true);
    const hasEvent = groupByDate[selectedDate];
    setEventsByDate(hasEvent);
  };

  const removeEvent = async (id) => {
    try {
      await deleteEvent(id);
      setEvents(events.filter((ev) => ev.id !== id));
      notification.open({
        message: 'Deleted',
        description: `Event has been deleted`,
      });
    } catch (error) {
      message.error('Something went wrong when deleting the event, please try again later.', 10);
      console.log(error);
    }
  };

  const onPanelChange = async (value, mode) => {
    const startData = value.startOf(mode).format('YYYY-MM-DD');
    const endDate = value.endOf(mode).format('YYYY-MM-DD');
    setCurrentMonth(value.month());
    setCurrentYear(value.year());
    await fetchEvents(startData, endDate);
  };

  const onSubmitCallback = useCallback(async (data) => {
    try {
      const res = await createEvent(data);
      const updatedEvents = events.concat({
        ...res,
        date: dayjs(res.actualDate).format('YYYY-MM-DD'),
      });
      setEvents(updatedEvents);
      notification.open({
        message: 'Created',
        description: `New event has been created`,
      });
    } catch (error) {
      message.error('Something went wrong, please try again later.', 10);
    } finally {
      setEventModal(false);
    }
  });

  const onUpdateCallback = useCallback(async (data) => {
    try {
      const res = await editEvent(selectedEvent.id, {
        ...selectedEvent,
        ...data,
      });
      setEvents(events.map((el) => (el.id === selectedEvent.id ? { ...el, ...res } : el)));
      notification.open({
        message: 'Updated',
        description: `Event has been updated`,
      });
    } catch (error) {
      console.log('error', error);
      message.error(`Something went wrong, please try again later. ${JSON.stringify(error)} `, 10);
    } finally {
      setEventModal(false);
    }
  });

  return (
    <s.container>
      <div style={{ display: 'flex', width: '100%' }}>
        {widgets.map((Widget, index) => (
          <div
            style={{
              flex: '1',
            }}
            key={index}>
            <Widget
              style={{
                borderColor: 'lightgray',
                borderWidth: 1,
                borderStyle: 'solid',
                borderRadius: 5,
                margin: 10,
              }}
            />
          </div>
        ))}
      </div>
      {/* <div style={{ width: '100%' }}>
        <ContentCalendar2Weeks
          contentTypes={[
            ...contentTypes,
            {
              id: 0,
              contentToBeDelivered: 'All Content Types',
              skip: true,
            },
          ]}
        />
      </div> */}
      <div style={{ padding: '12px'}}>
        <Calendar
          dateCellRender={dateCellRender}
          monthCellRender={monthCellRender}
          onSelect={onSelectDate}
          onPanelChange={onPanelChange}
        />
      </div>
      <Modal
        title={`Events for ${eventDate}`}
        styles={{ body: { height: 378, overflowY: 'auto' }}}
        open={showModal}
        onCancel={() => setShowModal(false)}
        footer={
          <s.flexRow>
            <Button onClick={() => setShowModal(false)}>Cancel</Button>
            <Button
              type="primary"
              onClick={() => {
                setSelectedEvent(null);
                setEventModal(true);
              }}>
              Add Event
            </Button>
          </s.flexRow>
        }>
        {eventsByDate?.map((ev) => (
          <Row
            type="flex"
            justify="space-between"
            align="middle"
            style={{ marginBottom: '5px' }}
            key={ev.name}>
            <Col
              span={23}
              style={{
                cursor: 'pointer',
                background: '#b4e6ff',
                color: '#488ba4',
                borderRadius: '4px',
                fontSize: '12px',
                padding: '2px 5px',
              }}
              onClick={() => {
                setSelectedEvent(ev);
                setEventModal(true);
              }}>
              {ev.name}
            </Col>
            <Col>
              <Popconfirm
                title={`Are you sure?`}
                onConfirm={() => removeEvent(ev.id)}
                okText="Delete"
                cancelText="Cancel">
                <s.CloseOutlined />
              </Popconfirm>
            </Col>
          </Row>
        ))}
      </Modal>

      <Modal
        title={`${selectedEvent ? 'Edit' : 'Add'} events for ${eventDate}`}
        open={eventModal}
        onCancel={() => {
          setSelectedEvent(null);
          setEventModal(false);
        }}
        footer={null}
        zIndex={1001}>
        <s.flexRow>
          <WrappedAddEentForm
            {...selectedEvent}
            isEditable={selectedEvent}
            onSubmitCallback={selectedEvent ? onUpdateCallback : onSubmitCallback}
            onFormReset={() => {
              setEventModal(false);
              setSelectedEvent(null);
            }}
          />
        </s.flexRow>
      </Modal>
    </s.container>
  );
};

const s = {
  eventBlock: styled(Text)`
    background: #b4e6ff;
    color: #488ba4;
    border-radius: 4px;
    font-size: 11px;
    text-overflow: ellipsis;
    width: 100%;
  `,
  CloseOutlined: styled(CloseOutlined)`
    font-size: 11px;
  `,
  container: styled.div`
    width: 100%;
    //background: #eef0f3 url('/img/WBM_LOGO_symbol.png');
    background-repeat: no-repeat;
    background-position: center center;
    display: flex;

    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 20px;
    .events {
      margin: 0;
      padding: 0;
      list-style: none;
    }
    .events .ant-badge-status {
      width: 100%;
      overflow: hidden;
      font-size: 12px;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .notes-month {
      font-size: 28px;
      text-align: center;
    }
    .notes-month section {
      font-size: 28px;
    }
    h3,
    h4 {
      font-size: 1.17em;
    }
  `,
  flexRow: styled.div``,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(Home);
