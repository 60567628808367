import { CaretDownFilled, CaretUpFilled, CloseCircleTwoTone, DeleteTwoTone, LoadingOutlined } from '@ant-design/icons';
import {
  Button,
  Divider,
  Form,
  Input,
  message,
  Modal,
  notification,
  Popconfirm,
  Radio,
  Select,
  Spin,
  Tooltip,
  Typography,
} from 'antd';
import _ from 'lodash';
import copy from 'copy-text-to-clipboard';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Gallery from 'react-photo-gallery';
import { Link, useHistory, useLocation } from 'react-router-dom';
import slugify from 'slugify';
import HeadingInput from 'components/PageBuilder/HeadingInput';
import RichTextInput from 'components/PageBuilder/RichTextInput';
import { createContentPage, editContentPage, getContent, translateContent } from 'lib/api/contentPage';
import { canI } from 'lib/util';
import { store } from 'reducers/store';
import styled from 'styled-components';
import { validation } from 'styles/pageBuilder/pageBuilder';
import HoveredImage from './HoveredImage';
import SelectedImage from './SelectedImage';
import * as designwizard from 'lib/api/designwizard';
import { createHistory } from 'lib/api/contentHistory';
import { getOwnedContentCollections, patchContentCollection } from 'lib/api/contentCollection';
import { publishPage } from '../../../../lib/api/pageBuilder';
import RemovedModal from './RemovedModal';
const { Text } = Typography;

const DEFAULT_HEADING_SIZE = 3;

const { user } = store.getState();
const me = typeof user === 'object' ? user : JSON.parse(user);
const ES_FIELDS = ['displayTitle^3', 'categorizationKeywords^2', 'title', 'keywords', 'tags'];

const SubCategoryPageEditor = (props) => {
  const urlParams = new URLSearchParams(props.location.search);
  const jobId = urlParams.get('jobId');
  const [form] = Form.useForm();
  const contractorId = urlParams.get('contractorId');
  const subCategoryId = props.match?.params?.id;
  const targetSite = props.match?.params?.site;
  const [data, setData] = useState({
    categoryType: 'photo',
    assets: [],
    sections: [],
    intro: {},
  });
  const [dataFlags, setDataFlags] = useState({});
  const [showAssetModal, setShowAssetModal] = useState(false);
  const [showIDPasteModal, setShowIDPasteModal] = useState(false);
  const [pasteModalAssets, setPasteModalAssets] = useState([]);
  const [modalAssets, setModalAssets] = useState([]);
  const [selectedModalAssets, setSelectedModalAssets] = useState([]);
  const [topAssets, setTopAssets] = useState([]);
  const [currentSearchQuery, setCurrentSearchQuery] = useState('');
  const [isSlugEditable, setIsSlugEditable] = useState(true);
  const [newSlug, setNewSlug] = useState('');
  const [assetModalLoading, setAssetModalLoading] = useState(false);
  const [topAssetsLoading, setTopAssetsLoading] = useState(false);
  const [insertAssetLoading, setInsertAssetLoading] = useState(false);
  const [IDPasteModalLoading, setIDPasteModalLoading] = useState(false);
  const [publishLoading, setPublishLoading] = useState(false);
  const [fetchedData, setFetchedData] = useState({ assetType: 'photo' });
  const [categoryList, setCategoryList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState();
  const [collections, setCollections] = useState([]);
  const [selectedCollections, setSelectedCollections] = useState([]);
  const [loadedStatus, setLoadedStatus] = useState(false);
  const [translatedLps, setTranslatedLps] = useState([]);
  const [translatedvalue, setTranslatedvalue] = useState([]);
  const history = useHistory();
  const location = useLocation();
  const [removedAssests, setRemovedAssests] = useState([]);
  const [removedAssetModel, setRemovedAssetModel] = useState(false);
  const [autoTitle, setAutoTitle] = useState(true);
  const [autoDesc, setAutoDesc] = useState(true);
  const [searchType, setSearchType] = useState('searchterm');

  const historyMsgRef = useRef(null);
  const { Option } = Select;

  useEffect(() => {
    fetchData();
  }, [location]);

  const fetchData = async () => {
    await fetchCollections(2);
    if (subCategoryId) {
      const subCatDt = await fetchSubCategoryPage(subCategoryId);
      await fetchTranslatedLps(subCatDt);
      await fetchCategories(subCatDt);
    } else {
      await fetchCategories();
    }
  };

  const fetchSubCategoryPage = async (id) => {
    setTopAssetsLoading(true);
    const subCatData = (await getContent({ where: { id } }))?.body[0];
    if (subCatData?.value?.assets) {
      for (let asset of subCatData.value.assets) {
        const a = await getAssetFromIDNew(asset, subCatData.assetType);
        if (a) {
          setTopAssets((prev) => [...prev, a]);
        }
      }
    }
    setFetchedData(subCatData);
    setData(subCatData.value);
    setNewSlug(subCatData.slug);
    setSelectedCollections(subCatData.collections);
    setTopAssetsLoading(false);
    return subCatData;
  };

  const fetchCategories = async (subCatDt = {}) => {
    try {
      const categories = await getContent({
        where: {
          contentType: 'category-page',
          targetSite: subCatDt.targetSite || targetSite,
          language: subCatDt.language || 'en',
        },
        fields: { id: true, name: true, slug: true, errors: true },
      });
      setCategoryList(categories.body);
      setSelectedCategory(categories.body.find((cat) => cat.id === subCatDt.categoryID));
    } catch (error) {
      console.error('Error fetching categories: ', error);
    }
  };

  const fetchCollections = async (type) => {
    try {
      const collectionsRes = await getOwnedContentCollections(type);
      setCollections(collectionsRes.body);
    } catch (error) {
      console.error('Error fetching categories: ', error);
    }
  };

  const fetchTranslatedLps = async (fetchedSubCatData) => {
    try {
      const translatedLps = await getContent({
        where: {
          contentType: 'subcategory-page',
          targetSite,
          or: [
            {
              subcategoryID:
                fetchedSubCatData.language === 'en' ? Number(subCategoryId) : Number(fetchedSubCatData.subcategoryID),
            },
            {
              id: fetchedSubCatData.language === 'en' ? Number(subCategoryId) : Number(fetchedSubCatData.subcategoryID),
            },
          ],
        },
        fields: { id: true, language: true },
      });
      const translateResp = translatedLps.body;
      setTranslatedLps(translateResp);
      setTranslatedvalue(translateResp.find((val) => val.language === fetchedSubCatData.language)?.id);
    } catch (error) {
      console.error('Error fetching categories: ', error);
    }
  };

  const onTranslatedPageChange = (value) => {
    const confirmBox = confirm('All the unsaved changes will be lost. Do you want to continue?');
    if (confirmBox) {
      history.push(`/page-builder/${targetSite}/subcategory-page/edit/${value}`);
    } else {
      return false;
    }
  };

  const handleCategoryChange = (value) => {
    setSelectedCategory(categoryList.find((cat) => cat.id === value));
  };

  const setDataField = (key, newVal) => {
    const newData = { ...data };
    _.set(newData, key, newVal);
    setData(newData);
  };

  const changeCategoryType = async (categoryType) => {
    if (data.assets && data.assets.length !== 0) {
      Modal.confirm({
        content: 'Selected top assets will be cleared before switching asset type. Are you sure?',
        onOk: async () => {
          setDataField('categoryType', categoryType);
          setFetchedData({ ...fetchedData, assetType: categoryType });
          setDataField('assets', []);
          setTopAssets([]);
        },
      });
    } else {
      setDataField('categoryType', categoryType);
      setFetchedData({ ...fetchedData, assetType: categoryType });
    }
    setModalAssets([]);
  };

  const handleAssetSearch = async () => {
    await searchAssets(currentSearchQuery, fetchedData.assetType, searchType);
  };

  // setting default of 500 x 400, will be replaced with actual dimensions once image loads
  const getAssetFromIDNew = async (id, categoryType) => {
    return {
      key: id,
      src: `https://templates.designwizard.com/${id}.jpg`,
      width: 500,
      height: 400,
    };
  };

  // the data maps differently depending if its a photo or template
  const mapFetchObjToGalleryObj = (obj, categoryType) => {
    return {
      src: `https://templates.designwizard.com/${obj.templateId}.${categoryType === 'photo' ? 'jpg' : 'mp4'}`,
      key: obj.templateId,
      width: obj.width ? obj.width : obj.composer_object.split('width":')[1].split(',')[0],
      height: obj.height ? obj.height : obj.composer_object.split('height":')[1].split(',')[0],
    };
  };

  const searchSiteAssets = async (searchText, categoryType, searchType = 'searchterm') => {
    try {
      let res;
      if (searchType === 'briefCode') {
        res = await designwizard.searchBriefCodes({
          size: 200,
          from: 0,
          searchterm: searchText,
          assetType: categoryType,
        });
      } else {
        if (categoryType === 'photo') {
          res = await designwizard.search({
            body: {
              query: {
                bool: {
                  must_not: [
                    {
                      terms: {
                        categoryId: [10, 11, 12, 13],
                      },
                    },
                  ],
                  must: [
                    {
                      term: {
                        _type: 'template',
                      },
                    },
                    {
                      multi_match: {
                        query: searchText,
                        fields: ES_FIELDS,
                      },
                    },
                  ],
                },
              },
            },
            size: 200,
          });
        } else {
          res = await designwizard.search({
            body: {
              query: {
                bool: {
                  must: [
                    {
                      term: {
                        categoryId: 10,
                      },
                    },
                    {
                      multi_match: {
                        query: searchText,
                        fields: ES_FIELDS,
                      },
                    },
                  ],
                },
              },
              sort: [
                {
                  price: {
                    order: 'asc',
                  },
                },
              ],
            },
            size: 200,
          });
        }
      }

      const items = res.body.templates;

      const temp = [];

      for (let i = 0; i < items.length; i++) {
        temp.push(mapFetchObjToGalleryObj(items[i], categoryType));
      }

      return temp;
    } catch (e) {
      console.log('something went wrong with dw asset search');
      console.log(e);
    }
  };

  const searchAssets = async (searchText, categoryType, searchType = 'searchterm') => {
    if (searchText.length < 3) {
      return;
    }

    setAssetModalLoading(true);

    const temp = await searchSiteAssets(searchText, categoryType, searchType);

    if (temp) {
      setModalAssets(temp);
    }

    setAssetModalLoading(false);
  };

  const handleInsertSelection = async () => {
    setInsertAssetLoading(true);
    const tmp = _.cloneDeep(topAssets);

    // only add the selected image if not already added
    for (let i = 0; i < selectedModalAssets.length; i++) {
      const sel = selectedModalAssets[i];
      let alreadyInserted = false;

      for (let j = 0; j < tmp.length; j++) {
        if (sel.key === tmp[j].key) {
          alreadyInserted = true;
          break;
        }
      }

      if (!alreadyInserted) {
        tmp.push(sel);
      }
    }

    setTopAssets(tmp);

    setDataField(
      'assets',
      tmp.map((x) => x.key),
    );

    setShowAssetModal(false);
    setSelectedModalAssets([]);
  };

  const handleInsertPastedIDs = async () => {
    setIDPasteModalLoading(true);

    const ta = _.cloneDeep(topAssets);

    // only add the image if not already added
    for (let i = 0; i < pasteModalAssets.length; i++) {
      const pastedID = pasteModalAssets[i];
      let alreadyInserted = false;

      for (let j = 0; j < ta.length; j++) {
        if (pastedID === ta[j].key) {
          alreadyInserted = true;
          break;
        }
      }

      if (!alreadyInserted) {
        let a = await getAssetFromIDNew(pastedID);

        if (a) {
          ta.push(a);
          setTopAssets(ta);
        }
      }
    }

    setDataField(
      'assets',
      ta.map((x) => x.key),
    );

    setShowIDPasteModal(false);
    setPasteModalAssets([]);
  };

  // moveBy -1 for up or +1 for down
  // const moveArrayItemPosition = (newArray, index, moveBy) => {
  //   const toMove = newArray[index];
  //   newArray[index] = newArray[index + moveBy];
  //   newArray[index + moveBy] = toMove;
  //   return newArray;
  // };

  // const handleMoveSection = (index, moveBy) => {
  //   reordering = true;
  //   const newData = { ...data };
  //   const newDataFlags = { ...dataFlags };
  //   newData.sections = moveArrayItemPosition(newData.sections, index, moveBy);
  //   if (newDataFlags.sections?.length > 0) {
  //     newDataFlags.sections = moveArrayItemPosition(newDataFlags.sections, index, moveBy);
  //   }

  //   setData(newData);
  //   setDataFlags(newDataFlags);
  // };

  const imageRenderer = useCallback(({ index, left, top, key, photo }) => (
    <SelectedImage
      key={'sel_' + key}
      margin={'2px'}
      index={index}
      photo={photo}
      left={left}
      top={top}
      onSelectionToggle={(isSelected) => {
        let tmp = _.cloneDeep(selectedModalAssets);

        if (isSelected) {
          tmp.push(photo);
        } else {
          tmp = tmp.filter((x) => x.key !== photo.key);
        }

        setSelectedModalAssets(tmp);
      }}
    />
  ));

  const removableImageRenderer = useCallback(({ index, key, photo }) => (
    <HoveredImage
      key={'rem_' + key}
      index={index}
      photo={photo}
      onClick={() => {
        let tmp = _.cloneDeep(topAssets);
        tmp = tmp.filter((x) => x.key !== photo.key);
        setTopAssets(tmp);
        setDataField(
          'assets',
          tmp.map((x) => x.key),
        );
      }}
      onLoad={({ target: img }) => {
        const { offsetHeight, offsetWidth } = img;

        let ta = [...topAssets];

        ta[index].width = offsetWidth;
        ta[index].height = offsetHeight;

        setTopAssets(ta);
      }}
    />
  ));

  const handleSubmit = async (isDraft = false) => {
    setPublishLoading(true);
    let status, slug, cleanedTitle;

    if (isDraft) {
      if (subCategoryId) {
        status = 'publishedDraft';
      } else {
        status = 'newDraft';
      }
    }

    try {
      if (subCategoryId) {
        status = 'publishedDraft';
        slug = newSlug || fetchedData.slug;
      } else {
        status = 'newDraft';
        slug = slugify(data.intro?.heading?.text, {
          replace: '-',
          remove: /["*+~.()/'!:@]/g,
          strict: true, // strip special characters except replacement
          trim: true,
        });
        const catSlug = selectedCategory?.slug;
        cleanedTitle = slug.replaceAll('-', ' ');
        slug = `${catSlug.endsWith('/') ? catSlug.slice(0, -1) : catSlug}/${slug}`.toLowerCase();
      }

      const patchContentCollectionPromise = [];
      if (subCategoryId && !_.isEqual(fetchedData.collections, selectedCollections)) {
        for (let collectionId of fetchedData.collections) {
          const x = collections.find((c) => c.id === Number(collectionId));
          if (x && x.links.includes(slug)) {
            patchContentCollectionPromise.push(
              patchContentCollection(collectionId, {
                links: x.links.filter((l) => l !== slug),
              }),
            );
          }
        }
      }
      if (selectedCollections) {
        for (let collectionId of selectedCollections) {
          const collection = collections.find((c) => c.id === Number(collectionId));
          if (collection && !Array.isArray(collection.links)) {
            collection.links = [];
          }
          collection.links.push(slug);
          patchContentCollectionPromise.push(
            patchContentCollection(collectionId, {
              links: collection.links,
            }),
          );
        }
      }

      await Promise.all(patchContentCollectionPromise);
      if (subCategoryId) {
        const editObject = {
          ...fetchedData,
          categoryID: selectedCategory.id,
          value: data,
          contentType: 'subcategory-page',
          new_status: status,
          collections: selectedCollections,
        };
        if (newSlug !== fetchedData.slug) {
          const slugList = fetchedData.slugList?.split(',') || [];
          slugList.push(fetchedData.slug);
          editObject.slug = newSlug;
          editObject.slugList = slugList.join(',');
        }
        await editContentPage(subCategoryId, editObject);

        notification.open({
          message: 'Updated',
          description: `Sub-category page #${subCategoryId} updated!`,
        });
      } else {
        const res2 = await createContentPage({
          assetType: fetchedData.assetType,
          targetSite,
          slug,
          value: data,
          name: cleanedTitle,
          title: cleanedTitle,
          contentType: 'subcategory-page',
          categoryID: selectedCategory.id,
          createdBy: me.id,
          active: true,
          new_status: status,
          collections: selectedCollections,
          language: 'en',
          jobId, //jobId will be set null if no url parameters
        });

        await createHistory({
          message: `${targetSite} has created`,
          contentId: res2.body.id,
        });

        notification.open({
          message: 'Created',
          description: `New sub-category page created!`,
        });
      }
    } catch (e) {
      console.log('Error while making changes:', e);
      notification.error({
        message: 'Error!',
        description: e.message || e,
        duration: 10,
      });
    }

    if (!isDraft) {
      try {
        await publishPage({
          site: targetSite,
          buildType: 'subcategory-page',
          action: 'publish',
          contentId: parseInt(fetchedData.id, 10),
        });
      } catch (e) {
        console.log('Error while publishing:', e);
        notification.error({
          message: 'Error!',
          description: e.message || e,
          duration: 10,
        });
      }
    }
    setPublishLoading(false);
  };

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  const onClickOpenLink = (key) => openInNewTab(`https://www.designwizard.com/${key}`);

  const handleAddSection = () => {
    const newData = { ...data };
    if (!newData.sections || !Array.isArray(newData.sections)) {
      newData.sections = [];
    }
    // adds an empty tag to be filled..
    newData.sections.push({
      heading: { text: null, size: DEFAULT_HEADING_SIZE },
      content: null,
    });

    setData(newData);
  };

  const handleUpdateSection = (index, fieldName, newVal) => {
    const newData = { ...data };

    _.set(newData.sections[index], fieldName, newVal);

    setData(newData);
  };

  // check if array is deep empty
  const isArrayEmpty = (array) => {
    for (let i = 0; i < array.length; i++) {
      if (!_.isEmpty(array[i])) {
        return false;
      }
    }
    return true;
  };

  const cleanEmptyFlagArrays = (newData, key) => {
    // if flag is part of an array of flags, check if array is now empty and can be deleted..
    if (key.includes('[')) {
      const baseArrayName = key.split('[')[0];

      if (newData[baseArrayName] && isArrayEmpty(newData[baseArrayName])) {
        delete newData[baseArrayName];
      }
    }

    return newData;
  };

  const handleDeleteArrayItem = (arrayName, index) => {
    const newData = { ...data };
    let newDataFlags = { ...dataFlags };

    newData[arrayName].splice(index, 1);
    newDataFlags[arrayName]?.splice(index, 1);

    newDataFlags = cleanEmptyFlagArrays(newDataFlags, `${arrayName}[${index}]`);

    setData(newData);
    setDataFlags(newDataFlags);
  };

  // recursive func to make sure there are no flags
  const isEmptyDeep = (obj) => {
    if (_.isObject(obj)) {
      if (Object.keys(obj).length === 0) return true;
      return _.every(_.map(obj, (v) => isEmptyDeep(v)));
    } else if (_.isString(obj)) {
      return !obj.length;
    }
    return false;
  };

  const handleTranslatePage = async (payload) => {
    try {
      const { englishContentId, language } = payload;
      const res = await translateContent({
        contentId: englishContentId,
        fromLanguage: 'en',
        toLanguage: language,
      });
      notification.open({
        message: 'Translated',
        description: `Subcategory Page Translation is processing`,
      });
    } catch (err) {
      console.log('err is ', err);
    }
  };

  if (!canI('write', 'pageBuilder.content')) {
    return (
      <div
        style={{
          display: 'flex',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <h4>You do not have permission to edit landing pages! (Please contact IT team)</h4>
      </div>
    );
  }

  console.log(':selectedCategory is ', selectedCategory);

  return (
    <>
      {topAssetsLoading ? (
        <div style={{ display: 'flex', marginTop: 250, justifyContent: 'center' }}>
          <Spin indicator={<LoadingOutlined style={{ fontSize: '100px', color: 'lightgray' }} />} />
        </div>
      ) : (
        <div style={{ display: 'flex', overflow: 'auto' }}>
          <s.Form
            layout="vertical"
            style={{
              flex: 1,
              padding: '30px',
              maxHeight: '800px',
              overflowY: 'auto',
              paddingBottom: '100px',
              paddingLeft: '15%',
              paddingRight: '15%',
            }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}>
              <s.Button
                onClick={() => history.push(`/page-builder/${targetSite}/subcategory-page`)}
                style={{ fontSize: '12px', color: 'grey' }}>
                {`<< Sub-Category Pages`}
              </s.Button>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              {translatedLps.length > 0 ? (
                <Select
                  style={{ width: 150 }}
                  onChange={onTranslatedPageChange}
                  value={translatedvalue}>
                  {translatedLps.map((val, index) => (
                    <Option
                      value={val.id}
                      disabled={val.language === fetchedData.language}
                      key={index}>
                      {val.language ? val.language : 'eng'}
                    </Option>
                  ))}
                </Select>
              ) : (
                ''
              )}
            </div>
            <s.h4
              style={{
                display: 'block',
                color: 'darkgray',
                padding: 0,
                margin: 0,
                fontWeight: 300,
              }}>
              {targetSite}
            </s.h4>
            <s.h3>Sub-Category Page Editor</s.h3>
            <Text style={{ marginBottom: '4px', display: 'block' }}>Category*:</Text>
            <Select
              showSearch
              allowClear
              style={{ marginBottom: '8px' }}
              placeholder="Select a category"
              onChange={handleCategoryChange}
              value={selectedCategory?.id}
              optionFilterProp="children"
              filterOption={(input, option) => option.props?.children?.toLowerCase().includes(input.toLowerCase())}>
              {categoryList &&
                categoryList.map((cat) => (
                  <Select.Option
                    value={cat.id}
                    key={cat.id}>
                    {cat.name}
                  </Select.Option>
                ))}
            </Select>
            {selectedCategory?.errors ? (
              <div style={{ marginBottom: '8px', color: '#d07e21' }}>
                {`This category contains warnings! `}
                <Link
                  style={{ color: '#d07e21', textDecoration: 'underline' }}
                  to={`/page-builder/${targetSite}/category-page/edit/${selectedCategory.id}`}
                  target="_blank"
                  rel="noopener noreferrer">
                  click here to fix (new tab)
                </Link>
              </div>
            ) : null}
            <Text style={{ marginBottom: '4px', display: 'block' }}>SubCategory Name*:</Text>
            <HeadingInput
              style={{ marginBottom: '8px' }}
              fieldName={'subcategory_name'}
              placeholder={'sub-category name'}
              text={data?.intro?.heading?.text}
              onTextChange={(text) => {
                setDataField('intro.heading', { ...data.intro?.heading, text });
              }}
            />
            <>
              <Text style={{ marginBottom: '4px', display: 'block' }}>Slug:</Text>
              <Input
                style={{ marginBottom: '16px' }}
                key={'newSlug'}
                id={'newSlug'}
                placeholder={'Slug'}
                value={newSlug}
                disabled={!(canI('write', 'pageBuilder.updateSlug') && subCategoryId !== undefined)}
                onChange={(e) => setNewSlug(e.target.value)}
              />
            </>
            <Text style={{ marginBottom: '4px', display: 'block' }}>Intro Content:</Text>
            {/* {loadedStatus ? ( */}
            <RichTextInput
              minHeight={'120px'}
              style={{ marginBottom: '8px' }}
              value={data.intro?.content || ''}
              onChange={(content) => {
                setDataField('intro.content', content);
              }}
              placeholder={'intro content'}
            />
            {/* ) : null} */}
            <Text style={{ marginBottom: '4px', display: 'block' }}>Asset Type:</Text>
            <Radio.Group
              style={{ marginBottom: '8px' }}
              value={fetchedData.assetType}
              buttonStyle="solid">
              <>
                <Radio.Button
                  checked
                  value="photo"
                  onClick={() => {
                    if (fetchedData.assetType !== 'photo') {
                      changeCategoryType('photo');
                    }
                  }}>
                  photo
                </Radio.Button>
                <Radio.Button
                  value="video"
                  onClick={() => {
                    if (fetchedData.assetType !== 'video') {
                      changeCategoryType('video');
                    }
                  }}>
                  video
                </Radio.Button>
              </>
            </Radio.Group>
            <s.flexRow style={{ justifyContent: 'space-between', marginBottom: '4px' }}>
              <Text style={{ display: 'block' }}>Assets*:</Text>
              <s.flexRow>
                {fetchedData.errors && fetchedData.errors.removedAssests && (
                  <s.Button
                    style={{ marginRight: '25px' }}
                    type="default"
                    size="small"
                    onClick={() => setRemovedAssetModel(true)}>
                    Removed Assets
                  </s.Button>
                )}
                <s.Button
                  style={{ marginRight: '8px' }}
                  type="default"
                  size="small"
                  onClick={() => {
                    copy(data.assets.join(', '));
                    message.success('IDs copied to clipboard');
                  }}>
                  Copy selected Asset IDs
                </s.Button>
                <s.Button
                  style={{ marginRight: '8px' }}
                  type="default"
                  size="small"
                  onClick={() => setShowIDPasteModal(true)}>
                  Paste Asset IDs
                </s.Button>
                <s.Button
                  type="default"
                  size="small"
                  onClick={() => setShowAssetModal(true)}>
                  Insert via Search
                </s.Button>
              </s.flexRow>
            </s.flexRow>
            <Modal
              closable={true}
              footer={null}
              open={showAssetModal}
              onOk={() => setShowAssetModal(false)}
              onCancel={() => setShowAssetModal(false)}
              centered
              closeIcon={
                <CloseCircleTwoTone
                  style={{ fontSize: '24px' }}
                  twoToneColor="rgb(255, 0, 0)"
                />
              }
              width={1000}
              body={{ display: 'relative', height: 848 }}>
              <s.flexRow style={{ height: 50, justifyContent: 'center' }}>
                <Select
                  onChange={(e) => setSearchType(e)}
                  placeholder="Please Select Search Type"
                  value={searchType}
                  style={{ width: 200, marginRight: '8px' }}>
                  <Select.Option
                    value={'searchterm'}
                    key={'searchterm'}>
                    Search Term
                  </Select.Option>
                  <Select.Option
                    value={'briefCode'}
                    key={'briefCode'}>
                    Brief Code
                  </Select.Option>
                </Select>
                <Input
                  placeholder={`Search ${targetSite}..`}
                  onChange={(e) => setCurrentSearchQuery(e.target.value)}
                  style={{ width: 200, display: 'block', marginRight: '8px' }}
                  value={currentSearchQuery}
                  onPressEnter={handleAssetSearch}
                />
                <s.Button
                  type="primary"
                  htmlType="submit"
                  disabled={currentSearchQuery.length < 3 || assetModalLoading}
                  onClick={handleAssetSearch}>
                  Search {fetchedData.assetType}s
                </s.Button>
              </s.flexRow>
              <div style={{ overflowY: 'auto', height: 700 }}>
                {assetModalLoading ? (
                  <Spin indicator={<LoadingOutlined style={{ fontSize: '100px', color: 'lightgray' }} />} />
                ) : (
                  <Gallery
                    photos={modalAssets}
                    targetRowHeight={150}
                    renderImage={imageRenderer}
                    style={{ height: '100%' }}
                  />
                )}
              </div>
              <s.flexRow style={{ height: 50, justifyContent: 'right' }}>
                <s.Button
                  type="primary"
                  htmlType="button"
                  disabled={selectedModalAssets.length === 0}
                  loading={insertAssetLoading}
                  onClick={handleInsertSelection}>
                  Insert Selected {fetchedData.assetType}s
                </s.Button>
              </s.flexRow>
            </Modal>
            <Modal
              closable={true}
              footer={null}
              open={showIDPasteModal}
              onOk={() => setShowIDPasteModal(false)}
              onCancel={() => setShowIDPasteModal(false)}
              centered
              closeIcon={
                <CloseCircleTwoTone
                  style={{ fontSize: '24px' }}
                  twoToneColor="rgb(255, 0, 0)"
                />
              }
              width={600}
              styles={{ display: 'relative', height: 524 }}>
              <Input.TextArea
                style={{
                  height: 400,
                  marginTop: '24px',
                  marginBottom: '8px',
                  resize: 'none',
                }}
                key={'assets'}
                id={'assets'}
                autoSize={false}
                placeholder={'Top Asset IDs (one per line)'}
                value={pasteModalAssets.join('\n')}
                onChange={(e) => setPasteModalAssets(e.target.value.split('\n'))}
              />
              <s.flexRow style={{ height: 50, justifyContent: 'right' }}>
                <s.Button
                  type="primary"
                  htmlType="submit"
                  disabled={pasteModalAssets.length === 0}
                  loading={IDPasteModalLoading}
                  onClick={handleInsertPastedIDs}>
                  Insert Asset IDs
                </s.Button>
              </s.flexRow>
            </Modal>
            <div
              style={{
                overflowY: 'auto',
                height: 500,
                border: '1px solid lightgrey',
                marginBottom: '8px',
                padding: '2px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              {topAssetsLoading ? (
                <Spin indicator={<LoadingOutlined style={{ fontSize: '100px', color: 'lightgray' }} />} />
              ) : (
                <div
                  style={{
                    width: 500,
                    height: 500,
                  }}>
                  <Gallery
                    photos={topAssets}
                    targetRowHeight={120}
                    renderImage={removableImageRenderer}
                    style={{ height: '100%' }}
                  />
                </div>
              )}
            </div>
            <Text style={{ marginBottom: '4px', display: 'block' }}>[Meta] Title:</Text>
            <Input
              style={{ marginBottom: '8px' }}
              key={'title'}
              id={'title'}
              placeholder={'Title'}
              value={data.title || (autoTitle ? data.intro?.heading?.text : null) || null}
              onChange={(e) => {
                if (autoTitle) setAutoTitle(false);
                return setDataField('title', e.target.value);
              }}
            />
            <Text style={{ marginBottom: '4px', display: 'block' }}>[Meta] Description:</Text>
            <Input.TextArea
              style={{ marginBottom: '8px' }}
              key={'metaDescription'}
              id={'metaDescription'}
              placeholder={'Description'}
              value={data.metaDescription}
              onChange={(e) => {
                return setDataField('metaDescription', e.target.value);
              }}
              rows={4}
            />
            <Text style={{ marginBottom: '4px', display: 'block' }}>[Meta] Open Graph Description:</Text>
            <Input.TextArea
              style={{ marginBottom: '8px' }}
              key={'description'}
              id={'description'}
              placeholder={'Open Graph Description'}
              value={data.description || (autoDesc ? data.metaDescription : null) || null}
              onChange={(e) => {
                if (autoDesc) setAutoDesc(false);
                return setDataField('description', e.target.value);
              }}
              rows={4}
            />
            {data.sections &&
              data.sections.map((section, i) => {
                const prefix = `section${i + 1}_`;

                return (
                  <div key={prefix + 'section'}>
                    <Divider
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '12px',
                      }}>
                      <s.flexRow>
                        <s.flexCentered>
                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                            {/* <s.CaretUpFilled
                              style={{ display: i === 0 ? 'none' : 'block' }}
                              onClick={() => handleMoveSection(i, -1)}
                            />
                            <s.CaretDownFilled
                              style={{
                                display: i === data.sections.length - 1 ? 'none' : 'block',
                              }}
                              onClick={() => handleMoveSection(i, 1)}
                            /> */}
                          </div>
                        </s.flexCentered>
                        <s.flexCentered style={{ marginLeft: '8px' }}>Section {i + 1}</s.flexCentered>
                        <s.flexCentered>
                          <DeleteTwoTone
                            style={{ marginLeft: '8px' }}
                            twoToneColor={'#ff0000'}
                            onClick={() => handleDeleteArrayItem('sections', i)}
                          />
                        </s.flexCentered>
                      </s.flexRow>
                    </Divider>
                    <HeadingInput
                      style={{ marginBottom: '8px' }}
                      fieldName={prefix + 'heading'}
                      text={section.heading?.text}
                      onTextChange={(text) =>
                        handleUpdateSection(i, 'heading', {
                          ...section.heading,
                          text,
                        })
                      }
                    />
                    <RichTextInput
                      minHeight={'120px'}
                      style={{ marginBottom: '8px' }}
                      value={section.content || ''}
                      onChange={(content) => {
                        handleUpdateSection(i, 'content', content);
                      }}
                      key={prefix + 'content'}
                      id={prefix + 'content'}
                      placeholder={'content'}
                    />
                  </div>
                );
              })}
            <Divider style={{ display: 'flex', alignItems: 'center', padding: '12px' }}>
              <Button
                style={{ fontWeight: 'bold' }}
                onClick={handleAddSection}>
                Add Section +
              </Button>
            </Divider>
            <Select
              mode="multiple"
              allowClear
              style={{ width: '100%' }}
              placeholder="Add to collection(s)"
              value={selectedCollections}
              onChange={(val) => setSelectedCollections(val)}>
              {collections.map((c) => (
                <Option key={c.id}>{c.name}</Option>
              ))}
            </Select>
            <s.flexRow style={{ justifyContent: 'space-between', marginTop: '32px' }}>
              {subCategoryId ? (
                <Popconfirm
                  icon={''}
                  title={
                    <div>
                      <textarea
                        placeholder="Reason for why are you making this changes?"
                        rows={4}
                        cols="70"
                        ref={historyMsgRef}></textarea>
                    </div>
                  }
                  onConfirm={() => handleSubmit(true)}
                  okText="Submit"
                  cancelText="Cancel">
                  <s.Button
                    type="primary"
                    htmlType="button"
                    disabled={!isEmptyDeep(dataFlags)}
                    loading={publishLoading}>
                    Update Draft
                  </s.Button>
                </Popconfirm>
              ) : (
                <s.Button
                  type="primary"
                  htmlType="button"
                  disabled={!isEmptyDeep(dataFlags)}
                  loading={publishLoading}
                  onClick={() => handleSubmit(true)}>
                  Save Draft
                </s.Button>
              )}

              {subCategoryId &&
              (fetchedData.new_status === 'published' || fetchedData.new_status === 'publishedDraft') ? (
                <s.Button onClick={() => onClickOpenLink(fetchedData.slug)}>open in {targetSite}</s.Button>
              ) : (
                <Tooltip title={'available once published'}>
                  <s.Button disabled>open in {targetSite}</s.Button>
                </Tooltip>
              )}

              <Popconfirm
                title={`Are you sure?`}
                onConfirm={() => handleSubmit(false)}
                okText="Publish"
                cancelText="Cancel">
                <s.Button
                  danger
                  htmlType="button"
                  disabled={!isEmptyDeep(dataFlags)}
                  loading={publishLoading}>
                  {`Publish`}
                </s.Button>
              </Popconfirm>
              {fetchedData.id && fetchedData.language && fetchedData.language !== 'en' ? (
                <s.Button onClick={() => handleTranslatePage(fetchedData)}>Translate page</s.Button>
              ) : (
                ''
              )}
            </s.flexRow>
          </s.Form>
        </div>
      )}
      {removedAssetModel && (
        <RemovedModal
          open={removedAssetModel}
          closeModal={setRemovedAssetModel}
          imageRenderer={imageRenderer}
          assets={removedAssests}
        />
      )}
    </>
  );
};

const s = {
  flexRow: styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
  `,
  flexCentered: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  h3: styled.h3`
    margin: 20px;
    text-align: center;
  `,
  h4: styled.h4`
    margin: 20px;
    text-align: center;
  `,
  Button: styled(Button)`
    display: block;
  `,
  Form: styled(Form)`
    border-radius: 5px;
    padding: 20px;
  `,
  CaretUpFilled: styled(CaretUpFilled)`
    padding: 0;
    margin: 0;
    font-size: 16px;
  `,
  CaretDownFilled: styled(CaretDownFilled)`
    padding: 0;
    margin: 0;
    font-size: 16px;
  `,
  validation,
};

export default SubCategoryPageEditor;
