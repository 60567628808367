import {
  SET_DW_TASKS,
  SET_COUNT_DW_TASKS,
  ADD_DW_TASK,
  EDIT_DW_TASK,
  REMOVE_DW_TASK,
} from './actionTypes';
const init = {
  tasks: [],
  total: 0,
};
export default (state = init, action) => {
  switch (action.type) {
    case SET_DW_TASKS:
      return { ...state, tasks: [...action.payload] };
    case SET_COUNT_DW_TASKS:
      return { ...state, total: action.payload };
    case ADD_DW_TASK:
      return { ...state, tasks: [...state.tasks, action.payload] };
    case EDIT_DW_TASK:
      state.tasks.some((task, index) => {
        if (task.id === action.payload.id) {
          state.tasks[index] = action.payload;
          return true;
        }
        return false;
      });
      return { ...state, tasks: [...state.tasks] };
    case REMOVE_DW_TASK:
      state.tasks.some((task, index) => {
        if (task.id === action.payload.id) {
          state.tasks.splice(index, 1);
          return true;
        }
        return false;
      });
      return { ...state, tasks: [...state.tasks] };
    default:
      return state;
  }
};
